import React, { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  changePaymentPointer,
  defaultPaymentChannel,
  defaultPaymentPointer,
  setPaymentChannels,
  setPaymentPointers,
  setSwərl,
} from "../SonarMuseWebMonetization";
import useArtist from "../../hooks/useArtist";
import useMusic from "../../hooks/useMusic";
import { ArtistDetailsContainer } from "../AudioPlayer/AudioPlayerElements";
import { AudioContext } from "../AudioPlayer/context/AudioContext";
import { DEFAULT_IMAGE} from "../GravatarAPI";
import LoadingScreen from "../LoadingScreen";
import { ArtistContent } from "./ArtistContent";
import ArtistHeader from "./ArtistHeader";
import { ArtistProfileDiv } from "./ArtistProfileElements";
import { shuffleSongs, updateWebPageIcon } from "../../utils/MusicUtils";
import { getUniqueListBy } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";

export const ArtistProfile = ({ artist }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [artistId, setArtist] = useState(artist);
  const [isUserSubscribed, setUserSubscribed] = useState(false)
  const [artistProfile, setArtistProfile] = useState();
  const { setPlaylist, setCurrentSong, play } = useContext(AudioContext);
  const { currentUser} = useAuth();
  const { validateUserArtistSubscription, getArtistProfile, getArtistDiscography, searchArtistAlbums, searchArtistSingles, searchArtistProducedSongs, searchArtistComposedSongs, searchArtistFeatSongs, searchArtistWrittenSongs } = useArtist({ id: artist });
  const { getSongs } = useMusic();
  const [allMusic, setAllMusic] = useState([])
  const [isFollowing, setIsFollowing] = useState(); // if currentUser follows artist
  const navigate  = useNavigate();
  const [discography, setDiscography] = useState({});
  const [isDiscographyLoaded, setDiscographyLoaded] = useState(false)
  const [isFullDiscographyLoaded, setFullDiscographyLoaded] = useState(false)


  useEffect(() => {
    let aProfile = {};
    setLoaded(false);
    setDiscography({})
    setDiscographyLoaded(false)
    if (artist === null || !artist) {
      navigate( "/home");
    } else {
      if(currentUser?.uid){
        validateUserArtistSubscription(currentUser?.uid, artist).then(sub => {
        setUserSubscribed(sub)
      })}else{
        setUserSubscribed(false)
      }
      getArtistProfile()
        .then((data) => {
          setArtistProfile(data);
          updateWebPageIcon(data?.artistSettings?.profilePictureType === "custom" &&
          data?.profilePicture
            ? data?.profilePicture
            : data?.photoUrl
            ? data?.photoUrl + "?s=600&d=mp"
            : 'https://sonarmuse.org/favicon.ico')
          document
            .querySelector('meta[name="monetization"]')
            .setAttribute(
              "content",
              data?.profile?.paymentPointer
                ? data.profile.paymentPointer
                : "$ilp.gatehub.net/239263989"
            );
          if (data?.profile?.paymentPointer) {
            setPaymentPointers({ [data?.profile?.paymentPointer]: 100 });
          } else {
            defaultPaymentPointer();
          }
          if(data?.profile?.paymentChannel){
            /* setSwərl(PAYOUT_TYPE.ARTIST, artist)
            setPaymentChannels(PAYOUT_TYPE.ARTIST,{[data?.profile?.paymentChannel]: 100}) */
          }else{
            defaultPaymentChannel()
          }
          document
          .querySelector('meta[name="description"]')
          .setAttribute("content", data?.gravatarProfile?.description);
        document
          .querySelector('meta[name="twitter:url"]')
          .setAttribute(
            "content",
            `https://dev.sonarmuse.org/artist/${artist}`
          );
        document
          .querySelector('meta[name="twitter:title"]')
          .setAttribute("content", data?.profile?.name);
        document
          .querySelector('meta[name="twitter:description"]')
          .setAttribute(
            "content",
            data?.profile?.paymentPointer
              ? data.profile.paymentPointer
              : "$ilp.gatehub.net/239263989"
          );
        document
          .querySelector('meta[name="twitter:image"]')
          .setAttribute(
            "content",
            `${data?.gravatarProfile?.avatar_url}?s=600&d=mp`
          );
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute(
            "content",
            `https://dev.sonarmuse.org/artist/${artist}`
          );
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", data?.profile?.name);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", data?.gravatarProfile?.description);
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute(
            "content",
            `${data?.gravatarProfile?.avatar_url}?s=600&d=mp`
          );
          setLoaded(true);
          getArtistDiscography(artist, 0)
            .then((data2) => {
              setDiscography(data2);
             setDiscographyLoaded(true);
/*                setFullDiscographyLoaded(true);
 */            })
            .catch((err) => {
              console.error(err);
              setDiscographyLoaded(true);
/*               setFullDiscographyLoaded(true);
 */            });
          /* getArtistDiscography(artist)
            .then((data2) => {
              setDiscography(data2);
              setDiscographyLoaded(true);
            })
            .catch((err) => {
              console.error(err);
              setDiscographyLoaded(true);
            }); */
        })
        .catch((err) => {
          //console.log(err);
          navigate( "/home");
        });
    }
    return () => {}
  }, [artist]);

  const getProfile = async () => {
    return await getArtistProfile()
      .then((data) => {
        if (data !== false || data.error) {
          //console.log(data)
          document
            .querySelector('meta[name="monetization"]')
            .setAttribute(
              "content",
              data?.profile?.paymentPointer
                ? data.profile.paymentPointer
                : "$ilp.gatehub.net/239263989"
            );
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", artistProfile?.gravatarProfile?.description);
          document
            .querySelector('meta[name="twitter:url"]')
            .setAttribute(
              "content",
              `https://dev.sonarmuse.org/artist/${artist}`
            );
          document
            .querySelector('meta[name="twitter:title"]')
            .setAttribute("content", data?.artistName);
          document
            .querySelector('meta[name="twitter:description"]')
            .setAttribute(
              "content",
              data?.profile?.paymentPointer
                ? data.profile.paymentPointer
                : "$ilp.gatehub.net/239263989"
            );
          document
            .querySelector('meta[name="twitter:image"]')
            .setAttribute(
              "content",
              `${data?.gravatarProfile?.avatar_url}?s=600&d=mp`
            );
          document
            .querySelector('meta[property="og:url"]')
            .setAttribute(
              "content",
              `https://dev.sonarmuse.org/artist/${artist}`
            );
          document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", data?.artistName);
          document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", artistProfile?.gravatarProfile?.description);
          document
            .querySelector('meta[property="og:image"]')
            .setAttribute(
              "content",
              `${data?.gravatarProfile?.avatar_url}?s=600&d=mp`
            );
          setArtistProfile(data);
          
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
 const filterSongs = async (discog, isShuffle) => {
    let allSongs = []
    let composed = discog?.composed?.length > 0  ? discog?.composed?.songs?.filter(c => !c.artists.some(a => a?.artistId === artist)) : []
    let written = discog?.written?.length > 0  ? discog?.written?.songs?.filter(w => !w.artists.some(a => a?.artistId === artist)) : []
    let produced = discog?.produced?.length > 0  ? discog?.produced?.songs?.filter(p => !p.artists.some(a => a?.artistId === artist)) : []
    try{
    if (discog?.songs && discog?.songs?.length > 0) {
      allSongs.push(...discog?.songs?.songs);
    }
    if (discog?.features && discog?.features?.length > 0) {
      allSongs.push(...discog?.features?.songs);
    }
    if(composed?.songs?.length > 0){
      allSongs.push(...composed);
    }
    if(written?.songs?.length > 0){
      allSongs.push(...written);
    }
    if(produced?.songs?.length > 0){
      allSongs.push(...produced);
    }
    if (allSongs?.length > 0) {
      allSongs = isShuffle ? shuffleSongs(getUniqueListBy(allSongs, '')) : allSongs
      setPlaylist(allSongs);
      setCurrentSong(0);
      play();
    }
    if (discog?.albums?.albums && discog?.albums?.albums?.length > 0) {
      let albumSongs = [];
      let albums = discog?.albums?.albums
       for (let i = 0; i < discog?.albums?.albums?.length; i++) {
        if (discog?.albums.albums[i]?.songs[0]?.songId) {
          albumSongs.push(...discog?.albums[i]?.songs);
        } else {
          await getSongs(discog?.albums?.albums[i]?.songs).then((songs) => {
            if(songs?.length > 0){
            albumSongs.push(...songs.filter(s => s?.songId));
            albums[i] = {
              ...albums[i],
              songs: songs.filter(s => s?.songId)
            }}
          }).catch(err => {console.error(err)});
        }
      }
      setDiscography(prev => {
        return {
          ...prev,
          albums:{
            ...prev.albums,
            albums: albums
          }
        }
      })
      albumSongs = isShuffle ? shuffleSongs(albumSongs) : albumSongs
      allSongs.push(...albumSongs);
    }
    setPlaylist(allSongs)
    return allSongs
  }catch(err){
      console.error(err)
      return []
    }
  }

  const filterDiscog = async (discog) => {
    let music = []
    let composed = discog?.composed?.length > 0  ? discog?.composed?.songs.filter(c => !c.artists.some(a => a?.artistId === artist)) : []
    let written = discog?.written?.length > 0  ? discog?.written?.songs.filter(w => !w.artists.some(a => a?.artistId === artist)) : []
    let produced = discog?.produced?.length > 0  ? discog?.produced?.songs.filter(p => !p.artists.some(a => a?.artistId === artist)) : []
      try{

      
    if (discog?.songs && discog?.songs?.length > 0) {
      if(!isUserSubscribed){
      music.push(...discog?.songs?.songs.filter(s => !s?.isSubscription));
    }else{
      music.push(...discog?.songs?.songs);
    }
    }
    if (discog?.features && discog?.features?.length > 0) {
      if(!isUserSubscribed){
        music.push(...discog.features?.songs.filter(s => !s?.isSubscription));
      }else{
        music.push(...discog.features?.songs);
      }
     
    }
    if(composed?.songs?.length > 0){
      if(!isUserSubscribed){
        music.push(...composed.filter(s => !s?.isSubscription));
      }else{
        music.push(...composed);
      }
    }
    if(written?.songs?.length > 0){
      if(!isUserSubscribed){
        music.push(...written.filter(s => !s?.isSubscription));
      }else{
        music.push(...written);
      }
    }
    if(produced?.songs?.length > 0){
      if(!isUserSubscribed){
        music.push(...produced.filter(s => !s?.isSubscription));
      }else{
        music.push(...produced);
      }
    }    
    if (discog?.albums?.albums && discog?.albums?.albums?.length > 0) {
      let albumSongs = [];
      let albums =  isUserSubscribed ? discog?.albums?.albums : discog?.albums?.albums.filter(s => !s?.isSubscription)
       for (let i = 0; i < albums?.length; i++) {
        if (discog?.albums?.albums[i]?.songs[0]?.songId) {
          albumSongs.push(...discog?.albums?.albums[i]?.songs);
        } else {
          await getSongs(discog?.albums.albums[i]?.songs).then((songs) => {
            albumSongs.push(...songs.filter(s => s?.songId));
            albums[i] = {
              ...albums[i],
              songs: isUserSubscribed ? songs.filter(s => s?.songId) : songs.filter(s => s?.songId && !s?.isSubscription)
            }
          });
        }
       
      }
      //console.log(albumSongs)
      //console.log(music)
      if(!isUserSubscribed){
        music.push(...albumSongs.filter(s => !s?.isSubscription));
      }else{
        music.push(...albumSongs);
      }
      setDiscography(prev => {
        return {
          ...prev,
          albums:{
            ...prev.albums,
            albums: albums
          }
        }
      })
    }
    return music
  }catch(err){
    console.error(err)
    return []
  }
  }

  const playAll = async () => {
    let allSongs = [];
    if(allMusic?.length > 0){
      allSongs = allMusic
      setPlaylist(getUniqueListBy(allSongs, ''));
      setCurrentSong(0);
      play();
    }else{
      if( !isFullDiscographyLoaded){
   allSongs = await filterSongs(discography, false)
    return await getArtistDiscography(artist, "all").then(async(discog) => {
      let albums = discog?.albums?.length > 0 ? discog?.albums : discography?.albums
          let songs = discog?.songs?.length > 0 ? discog?.songs.songs : discography?.songs?.length > 0 ? discography?.songs : [] 
          let features = discog?.features?.length > 0 ? discog?.features : discography?.features?.length > 0 ? discography?.features : [] 
          let composed = discog?.composed?.length > 0 ? discog?.composed : discography?.composed?.length > 0 ? discography?.composed : [] 
          let written = discog?.written?.length > 0 ? discog?.written : discography?.written?.length > 0 ? discography?.written : [] 
          let produced = discog?.produced?.length > 0 ? discog?.produced : discography?.produced?.length > 0 ? discography?.produced : [] 

/*       setDiscography({albums,songs, composed, features, written, produced})
      setFullDiscographyLoaded(true) */
      let filteredDs = await filterDiscog(discog)
      //console.log(filteredDs)
     allSongs.push(...filteredDs) 
      setAllMusic(allSongs)
      //console.log(allSongs)
      allSongs = getUniqueListBy(allSongs, '')
      setPlaylist(getUniqueListBy(allSongs, ''));
    })
}else{
  allSongs = await filterSongs(discography, false)
  setAllMusic(allSongs)
  //console.log(allSongs)
  setPlaylist(getUniqueListBy(allSongs, ''));
}
}
  };
  const shuffleAll = async () => {
    let allSongs = [];
    if(allMusic?.length > 0)
    {
      allSongs = allMusic
      setPlaylist(shuffleSongs(getUniqueListBy(allSongs, '')));
      setCurrentSong(0);
      play();
    }else{
      if(!isFullDiscographyLoaded){
      let composed = discography?.composed?.length > 0  ? discography?.composed?.songs.filter(c => !c.artists.some(a => a?.artistId === artist)) : []
      let written = discography?.written?.length > 0  ? discography?.written?.songs.filter(w => !w.artists.some(a => a?.artistId === artist)) : []
      let produced = discography?.produced?.length > 0  ? discography?.produced?.songs.filter(p => !p.artists.some(a => a?.artistId === artist)) : []
      if (discography?.songs && discography?.songs?.length > 0) {
      allSongs.push(...discography.songs.songs);
    }
    if (discography?.features && discography?.features?.songs?.length > 0) {
      allSongs.push(...discography.features.songs);
    }
    if(composed?.length > 0){
      allSongs.push(...composed);
    }
    if(written?.length > 0){
      allSongs.push(...written);
    }
    if(produced?.length > 0){
      allSongs.push(...produced);
    }
    if (allSongs?.length > 0) {
      setPlaylist(shuffleSongs(getUniqueListBy(allSongs, '')));
      setCurrentSong(0);
      play();
    }

    if(discography?.songs?.length > 10 && (discography?.songs.songs.length !== discography?.songs?.length)){
      for (let i = 1; i < (discography?.songs?.length/10); i++) {
        await searchArtistComposedSongs(artist, i).then((songs) => {
          allSongs.push(...shuffleSongs(songs.songs));
        });
      
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }
    if (discography?.albums?.albums && discography?.albums?.albums?.length > 0) {
      let albumSongs = [];
       for (let i = 0; i < discography?.albums?.albums?.length; i++) {
        if (discography?.albums.albums[i]?.songs[0]?.songId) {
          albumSongs.push(...discography?.albums[i]?.songs);
        } else {
          await getSongs(discography?.albums.albums[i]?.songs).then((songs) => {
            albumSongs.push(...songs);
          });
        }
      }
        if(discography?.albums?.length > 10 && (discography?.albums.albums.length !== discography?.albums?.length)){
          for (let i = 1; i < (discography?.albums?.length/10); i++) {
            let album = await searchArtistAlbums(artist, i)
            await getSongs(album.songs).then((songs) => {
              albumSongs.push(...songs);
            });
          }
    }

      allSongs.push(...shuffleSongs(albumSongs));
      setPlaylist(getUniqueListBy(allSongs, ''));

    }
    if (discography?.features?.length > 10 && (discography?.features.songs.length !== discography?.features?.length)) {
      for (let i = 1; i < (discography?.features?.length/10); i++) {
       await searchArtistFeatSongs(artist, i).then((songs) => {
          allSongs.push(...shuffleSongs(songs.songs));
        });
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }
    if(discography?.composed?.length > 10 && (discography?.composed.songs.length !== discography?.composed?.length)){
      for (let i = 1; i < (discography?.composed?.length/10); i++) {
        await searchArtistComposedSongs(artist, i).then((songs) => {
          allSongs.push(...shuffleSongs(songs.songs));
        });
      
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }
    if(discography?.written?.length > 10 && (discography?.written.songs.length !== discography?.written?.length)){
      for (let i = 1; i < (discography?.written?.length/10); i++) {
        await searchArtistWrittenSongs(artist, i).then((songs) => {
          allSongs.push(...shuffleSongs(songs.songs));
        });
      
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }
    if(discography?.produced?.length > 10 && (discography?.written.songs.length !== discography?.written?.length)){
      for (let i = 1; i < (discography?.produced?.length/10); i++) {
        await searchArtistProducedSongs(artist, i).then((songs) => {
          allSongs.push(...shuffleSongs(songs.songs));
        });
      
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }}else{
    let composed = discography?.composed?.length > 0  ? discography?.composed?.songs.filter(c => !c.artists.some(a => a?.artistId === artist)) : []
      let written = discography?.written?.length > 0  ? discography?.written?.songs.filter(w => !w.artists.some(a => a?.artistId === artist)) : []
      let produced = discography?.produced?.length > 0  ? discography?.produced?.songs.filter(p => !p.artists.some(a => a?.artistId === artist)) : []
      if (discography?.songs && discography?.songs?.length > 0) {
      allSongs.push(...discography.songs.songs);
    }
    if (discography?.features && discography?.features?.songs?.length > 0) {
      allSongs.push(...discography.features.songs);
    }
    if(composed?.length > 0){
      allSongs.push(...composed);
    }
    if(written?.length > 0){
      allSongs.push(...written);
    }
    if(produced?.length > 0){
      allSongs.push(...produced);
    }
    if (allSongs?.length > 0) {
      setPlaylist(shuffleSongs(getUniqueListBy(allSongs, '')));
      setCurrentSong(0);
      play();
    }

    if (discography?.albums?.albums && discography?.albums?.albums?.length > 0) {
      let albumSongs = [];
       for (let i = 0; i < discography?.albums?.albums?.length; i++) {
        if (discography?.albums.albums[i]?.songs[0]?.songId) {
          albumSongs.push(...discography?.albums[i]?.songs);
        } else {
          await getSongs(discography?.albums.albums[i]?.songs).then((songs) => {
            albumSongs.push(...songs);
          });
        }
      }
      allSongs.push(...shuffleSongs(albumSongs));
      setPlaylist(getUniqueListBy(allSongs, ''));
    }
    setPlaylist(getUniqueListBy(allSongs, ''));
  }}
  };

  return (
    <>
      {!isLoaded && <LoadingScreen isLoaded={isLoaded} />}
      {isLoaded && (
        <ArtistProfileDiv>
          <ArtistDetailsContainer>
            <ArtistHeader
              playDiscography={isDiscographyLoaded? playAll : {}}
              shuffleDiscography={isDiscographyLoaded ? shuffleAll : {}}
              artistSettings={artistProfile?.profile?.artistSettings}
              background={artistProfile?.gravatarProfile?.profileBackground}
              wallets={artistProfile?.gravatarProfile?.currency ? Object.values(artistProfile?.gravatarProfile?.currency): undefined}
              isVerified={artistProfile?.profile?.isVerified}
              artistName={artistProfile?.profile?.name}
              artistUid={artistProfile?.profile?.uid}
              discography={discography}
              isSubscribed={isUserSubscribed}
              artistId={artist}
              artist={artistProfile?.profile}
              isUserFollowing={artistProfile?.isUserFollowing}
              profilePicture={
                artistProfile?.profile?.artistSettings?.profilePictureType
                  ? artistProfile?.profile?.artistSettings?.profilePictureType ===
                    "gravatar"
                    ? artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                    : artistProfile?.profile?.artistSettings?.profilePictureType === "custom"
                    ? artistProfile?.profile?.profilePicture?.url
                    : artistProfile?.gravatarProfile?.avatar_url+ "?s=600"
                  : artistProfile?.gravatarProfile?.avatar_url ? artistProfile?.gravatarProfile?.avatar_url+ "?s=600" : DEFAULT_IMAGE(600)
              }
            />
            <ArtistContent
              artistId={artist}
              artist={artistProfile?.profile}
              isDiscographyLoaded={isDiscographyLoaded}
              discography={discography}
              searchArtistSingles={searchArtistSingles}
              searchArtistAlbums={searchArtistAlbums}
              searchArtistFeatSongs={searchArtistFeatSongs}
              searchArtistComposedSongs={searchArtistComposedSongs}
              searchArtistWrittenSongs={searchArtistWrittenSongs}
              searchArtistProducedSongs={searchArtistProducedSongs}
              isUserSubscribed={isUserSubscribed}
              setDiscography={setDiscography}
              getProfile={getProfile}
              background={
                artistProfile?.gravatarProfile?.profileBackground?.color
              }
              gravatarProfile={artistProfile?.gravatarProfile}
              about={{
                photos: artistProfile?.gravatarProfile?.gallery,
                accounts: artistProfile?.gravatarProfile?.verified_accounts,
                bio: artistProfile?.profile?.artistSettings?.bioType === "custom" ? artistProfile?.profile?.bio : artistProfile?.gravatarProfile?.description,
              }}
            />
          </ArtistDetailsContainer>
        </ArtistProfileDiv>
      )}
    </>
  );
};
