import { useAlert } from "../components/Alert/AlertContext";
import axiosInstance from "../components/Authorization/client";
import { getUserProfile, hashEmail} from "../components/GravatarAPI";
import { useAuth } from "../contexts/AuthContext";
import { ALERT_TYPES, ARTIST_SEARCH_TYPES } from "../utils/Enum";
import useMusic from "./useMusic";
const axios = axiosInstance

export default function useArtist({id}){
    const {getSong, getAlbum} = useMusic()
    const {addAlert} = useAlert()
    const {currentUser} = useAuth()

    async function getArtistProfile(aId){
        const controller = new AbortController();
        let artistProfile = await axios.get(`/api/${aId ? aId:id}/getArtist`,{signal: controller.signal})
        .then((data) => {
            return data.data
        }).catch((err) => {
            console.error(err)
            return false
        })
       let artistGProfile = await getUserProfile(hashEmail(artistProfile.profile?.email))
       .then((data) => {
        return data
    }).catch((err) => {
        console.error(err)
        return false
    })

        let isUserFollowing = await isFollowingArtist(aId ? aId : id)
        .then((data) => {
            return data
        }).catch((err) => {
            console.error(err)
            return false
        })

        return {...artistProfile, gravatarProfile: artistGProfile, isUserFollowing: isUserFollowing}
    }

    async function getArtistName(uid){
        const controller = new AbortController();
        return await axios.get(`/api/${uid}/getArtistName`,{signal: controller.signal})
        .then((artist) => {
            if(artist?.data && !artist.data.error){
            return artist.data?.name
        }
            return false
        })
        .catch(err => {
            //console.error(err)
            return false
        })
    }

    const artistProfileChecker = async(uid) => {
        const controller = new AbortController();
        return await axios.get(`/api/artists/${uid}/artistProfileChecker`, {signal: controller.signal})
        .then((res) => {
            //console.log(res.data)
            if(!res.data || res?.data?.error){
                return false
            }
            return res?.data
        }).catch((err) => {
            console.error(err)
            return false
        })
    }


    const followArtist = async(aid) => {
        const controller = new AbortController();
        addAlert({type: ALERT_TYPES.INFO, title: 'Artist Follow', secs: 10})

        return axios.post(`/api/${aid ? aid:id}/followArtist`, {uid: currentUser?.uid, signal: controller.signal})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Artist has been followed'})

            return data.data
        })
        .catch(err => {
            console.error(err)
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const unfollowArtist = async(aId) => {
        const controller = new AbortController();
    addAlert({type: ALERT_TYPES.INFO, title: 'Artist Unfollow', secs: 10})

        return axios.post(`/api/${aId ? aId:id}/unfollowArtist`, {uid: currentUser?.uid, signal: controller.signal})
        .then((data) => {
            addAlert({type: ALERT_TYPES.SUCCESS, message: 'Artist has been unfollowed'})

            return data.data
        })
        .catch(err => {
            addAlert({type: ALERT_TYPES.DANGER, title: 'Follow Error', message: 'Oops, Something went wrong. Try again.'})

            return false
        })
    }

    const isFollowingArtist = async(aId) => {

        return axios.get(`/api/${aId ? aId:id}/${currentUser?.uid}/isFollowing`)
        .then((data) => {
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const getArtistFollowers = async(aId) => {
        const controller = new AbortController();
        return axios.post(`/api/${aId ? aId:id}/getArtistFollowers,`,{signal: controller.signal})
        .then((data) => {
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    
    const uploadImage = (filePath) => {
        
    }

    const getArtistDiscography = async(id, page) => {
        return await Promise.all([searchArtistAlbums(id, page), searchArtistSingles(id,page), searchArtistFeatSongs(id,page),searchArtistComposedSongs(id,page),searchArtistWrittenSongs(id,page), searchArtistProducedSongs(id,page), searchArtistPopularSongs(id, page)])
        .then(data => {
            let discog = {}
            if(data[0] && !data[0]?.error){
                discog = {
                    ...discog,
                    albums: data[0]
                }
            }else if(!data[0] || data[0].error)
            {
                discog = {
                    ...discog,
                    albums: false
                }  
            }

            if(data[1] && !data[1].error){
                discog = { 
                    ...discog,
                songs: data[1]
                }
            }else if(!data[1] || data[1].error)
            {
                discog = { 
                    ...discog,
                songs: false
                }    
            }

            if(data[2] && !data[2].error){
                discog = {
                    ...discog,
                    features: data[2]
                }
            }else if(!data[2] || data[2]?.error)
            {
                discog = {
                    ...discog,
                    features: false
                }    
            }
            if(data[3] && !data[3].error){
                discog = {
                    ...discog,
                    composed: data[3]
                }
            }else if(!data[3] || data[3]?.error)
            {
                discog = {
                    ...discog,
                    composed: false
                }    
            }
            if(data[4] && !data[4].error){
                discog = {
                    ...discog,
                    written: data[4]
                }
            }else if(!data[4] || data[4]?.error)
            {
                discog = {
                    ...discog,
                    written: false
                }    
            }
            if(data[5] && !data[5].error){
                discog = {
                    ...discog,
                    produced: data[5]
                }
            }else if(!data[5] || data[5]?.error)
            {
                discog = {
                    ...discog,
                    produced: false
                }    
            }
            if(data[6] && !data[6].error){
              
                discog = {
                    ...discog,
                    popular: data[6]
                }
            }else if(!data[6] || data[6]?.error)
            {
                discog = {
                    ...discog,
                    popular: false
                }    
            }
            return discog
        }).catch(err => {
            console.error(err)
        })
    }

    const bulkArtistsSearch = async(name) =>{
        let artists = []
        try{
        await searchArtists(ARTIST_SEARCH_TYPES.NAME,name)
        .then(data => {
            if(data !== false){
            artists.push(...data)
        }
        })
        await searchArtists(ARTIST_SEARCH_TYPES.FULL_NAME,name)
        .then(data => {
            if(data !== false){
                for(let i = 0; i <= data.length - 1; i++){
                    let artist = data[i]
                    if(artists.length > 0){
                        artists = artists.filter(a => a.artistId !== artist.artistId)
                    }
                    artists.push(artist)
                }
            }
            
        })
        await searchArtistsBySong(name)
        .then(data => {
            if(data !== false){
                for(let i = 0; i <= data.length - 1; i++){
                    let artist = data[i]
                    if(artists.length > 0){
                        artists = artists.filter(a => a.artistId !== artist.artistId)
                    }
                    artists.push(artist)
                }
            }
            
        })
        await searchArtistsByAlbum(name)
        .then(data => {
            if(data !== false){
                for(let i = 0; i <= data.length - 1; i++){
                    let artist = data[i]
                    if(artists.length > 0){
                        artists = artists.filter(a => a.artistId !== artist.artistId)
                    }
                    artists.push(artist)
                }
            }
            
        })
        return artists
        }catch (err){
            console.error(err)
            return false
        }
    }

    const searchArtists = async(type, artist) => {
        const controller = new AbortController();

        switch (type) {
            case ARTIST_SEARCH_TYPES.ID:
                return await axios.post(`/api/artist/search`, {artistId: artist, type: ARTIST_SEARCH_TYPES.ID, signal: controller.signal})
                .then((data) => 
                {
            return data.data
            }).catch(err => {
            return false
            })
                
            case ARTIST_SEARCH_TYPES.ADDRESS:
                return await axios.post(`/api/artist/search`, {address: artist, type: ARTIST_SEARCH_TYPES.ADDRESS, signal: controller.signal})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })
                
            case ARTIST_SEARCH_TYPES.NAME:
                return await axios.post(`/api/artist/search`, {artistName: artist, type: ARTIST_SEARCH_TYPES.NAME, signal: controller.signal})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })
                      
            case ARTIST_SEARCH_TYPES.FULL_NAME:
                return await axios.post(`/api/artist/search`, {artistName: artist, type: ARTIST_SEARCH_TYPES.FULL_NAME, signal: controller.signal})
                .then((data) => {
                    return data.data
                })
                .catch(err => {
                    return false
                })    
            default:
                break;
        }
    }

    const searchArtistsBySong = async(name) => {
        const controller = new AbortController();
        return await axios.post('/api/artists/searchBySong',{songName: name, signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
        })
        .catch(err => {
        return false
        })
    }

    const searchArtistsByAlbum = async(name) => {
        const controller = new AbortController();
        return await axios.post('/api/artists/searchByAlbum',{albumName: name, signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        controller.abort()
        return songs.data
    })
    .catch(err => {
        return false
    })
    }
    
    const searchArtistPopularAlbums = async(id, page) => {
        const controller = new AbortController();
        return await axios.get(`/api/${id}/getPopularAlbums/${page}`,{signal: controller.signal})
        .then((popular) => {
        if(popular?.data.error || popular?.data === false){
            controller.abort()
            return false
        }
        controller.abort()
        return popular.data
    })
    .catch(err => { 
        controller.abort()
        return false
       
    })
    }

    const searchArtistPopularSongs = async(id, page) => {
        const controller = new AbortController();
        return await axios.get(`/api/${id}/getPopularSongs/${page}`,{signal: controller.signal})
        .then((popular) => {
        if(popular?.data.error || popular?.data === false){
            controller.abort()
            return false
        }
        controller.abort()
        return popular.data
    })
    .catch(err => { 
        controller.abort()
        return false
       
    })
    }

    const searchArtistPopularFeatures = async(id, page) => {
        const controller = new AbortController();
        return await axios.get(`/api/${id}/getPopularSongs/${page}`,{signal: controller.signal})
        .then((popular) => {
        if(popular?.data.error || popular?.data === false){
            controller.abort()
            return false
        }
        controller.abort()
        return popular.data
    })
    .catch(err => { 
        controller.abort()
        return false
       
    })
    }


    const searchArtistAlbums = async(id, page) => {
        const controller = new AbortController();
       return await axios.get(`/api/${id}/getArtistAlbums/${page}`,{signal: controller.signal})
        .then((albums) => {
        if(albums?.data.error || albums?.data === false){
            controller.abort()
            return false
        }
        controller.abort()
        return albums.data
    })
    .catch(err => { 
        controller.abort()
        return false
       
    })
    
    }

    const searchArtistSongs = async(id, page) => {
        const controller = new AbortController();
       return await axios.get(`/api/${id}/getArtistSongs/${page}`,{signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        controller.abort()
        return songs.data
    })
    .catch(err => {
        controller.abort()
        return false
    })

    }

    const searchArtistSingles = async(id, page) => {
        const controller = new AbortController();
      return await axios.get(`/api/${id}/getArtistSingles/${page}`,{signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
    })
    .catch(err => {
        return false
    })


    }

    const searchArtistFeatSongs = async(id, page) => {
        const controller = new AbortController();
      return await axios.get(`/api/${id}/getFeaturedSongs/${page}`,{signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
    })
    .catch(err => {
        return false
    })

    }

    const searchArtistComposedSongs = async(id, page) => {
        const controller = new AbortController();
      return await axios.get(`/api/${id}/getComposedSongs/${page}`, {signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
    })
    .catch(err => {
        return false
    })

    }
    const searchArtistWrittenSongs = async(id, page) => {
        const controller = new AbortController();
      return await axios.get(`/api/${id}/getWrittenSongs/${page}`,{signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
    })
    .catch(err => {
        return false
    })

    }
    const searchArtistProducedSongs = async(id, page) => {
        const controller = new AbortController();
      return await axios.get(`/api/${id}/getProducedSongs/${page}`, {signal: controller.signal})
        .then((songs) => {
        if(songs.data.error || songs.data === false){
            return false
        }
        return songs.data
    })
    .catch(err => {
        return false
    })

    }

    const getPaymentPointer = async(aId) => {
        return axios.get(`/api/${aId}/getPaymentPointer`)
                .then((data) => {
                    if(data.data.message || data.data.error || data.data === false  )
                    {
                        return false
                    }
                    return data.data
                })
                .catch(err => {
                    return false
                }) 

    }

    const getArtists = async(page) => {
        return await axios.get(`/api/artists/getArtists/${page}`)
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }
    
    const getTopArtists = async(page) => {
        const controller = new AbortController();
        return await axios.get(`/api/artists/getTopArtists/${page}`,{signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const requestArtistRegistration = async(uid, artistInfo, payload) => {
        const controller = new AbortController();
        return axios.post(`/api/${uid}/requestArtistRegistration`, {profile: artistInfo, payload: payload, signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const updateProfile = async(aId, profile, txInfo) => {
        const controller = new AbortController();
        return axios.post(`/api/${aId}/updateArtistProfile`, {profile: profile, txInfo, signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }
    
    const updateStorageToken = async(aId,token, txInfo) => {
        const controller = new AbortController();
        return axios.post(`/api/${aId}/updateStorageToken`, {token: token, txInfo, signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const validateArtistNFTokenMint = async(payload, uid) => {
        const controller = new AbortController();
        return await axios.get(`/api/artists/${payload}/${uid}/validateArtistNFTokenMint`,{signal: controller.signal})
        .then((data) => {
            if(data?.data?.error || data?.data === false)
            {
                return false
            }
            return data?.data
        })
        .catch(err => {
            return false
        })
    }

    const validateNFTStorageKey = async(artistId) => {
        const controller = new AbortController();
        return axios.get(`/api/artists/${artistId}/validateNFTStorageKey`,{signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }
    
    const getNFTStorageKey = async(artistId) => {
        const controller = new AbortController();
        return axios.get(`/api/artists/${artistId}/getNFTStorageKey`, {signal: controller.signal})
        .then((data) => {
            if(data.data.error || data.data === false)
            {
                return false
            }
            return data.data
        })
        .catch(err => {
            return false
        })
    }

    const enableArtistWebMonetization = async(artistId, payload, txId) => {
        const controller = new AbortController();
        return await axios.post(`/api/${artistId}/enableArtistWebMonetization`, {
            payload: payload,
            txId: txId,
           signal: controller.signal
        }).then(result => {
            if(result.data && !result.data.error){
                return result.data
            }
            return false
        }).catch(err => {
            console.error(err)
            return false
        })
    }

    const getArtistFunds = async(artistId, paymentChannel) => {
        const controller = new AbortController();
        return await axios.get(`/api/${artistId}/${paymentChannel}/getArtistCurrentBalance`, {signal: controller.signal})
        .then((balance) => {
            if(balance.data && !balance.data.error){
                return balance.data
            }
        }).catch(err => {
            return false
        })
    }

    const getArtistMusicRoyaltiesByUserId = async (uid, next) => {
        return await axios
          .get(`/api/royalties/${uid}/getArtistMusicRoyalties/${next}`)
          .then((royalties) => {
            if (royalties.data && !royalties?.data?.error) {
              return royalties.data;
            }
            return false;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      };

    const validateUserArtistSubscription = async(uid, artistId) => {
        return await axios.get(`/api/${uid}/${artistId}/isUserSubscribedToArtist`).then(subscription => {
            if(subscription.data && ! subscription.data.error){
                return subscription?.data?.subscribed
            }
            return false
        }).catch(err => {
            
            return false
        })
    }


    return { 
        getArtistProfile,
        artistProfileChecker,
        enableArtistWebMonetization,
        requestArtistRegistration,
        getArtistFunds,
        searchArtistPopularSongs,
        searchArtistPopularAlbums,
        searchArtistPopularFeatures,
        getArtistDiscography,
        bulkArtistsSearch,
        searchArtists,
        followArtist,
        validateUserArtistSubscription,
        unfollowArtist,
        searchArtistAlbums,
        searchArtistSongs,
        searchArtistSingles,
        searchArtistFeatSongs,
        searchArtistComposedSongs,
        searchArtistWrittenSongs,
        searchArtistProducedSongs,
        getArtistFollowers,
        isFollowingArtist,
        getPaymentPointer,
        getArtistMusicRoyaltiesByUserId,
        getTopArtists,
        getArtists,
        getArtistName,
        updateProfile,
        updateStorageToken,
        validateArtistNFTokenMint,
        validateNFTStorageKey,
        getNFTStorageKey
    }
        
       
    



}
