import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
} from "react";
import AudioReducer from "../components/AudioPlayer/context/AudioReducer";
import {
  SonarMuseWebMonetization,
  changePaymentPointer,
  defaultPaymentPointer,
  isUserPayingOut,
} from "../components/SonarMuseWebMonetization";
import { Theme } from "../components/WebsiteThemeElements";
import {
  convertDuration,
  getArtistsNames2,
  getSongTitle,
  getUniqueListBy,
} from "../utils";
import {
  ARTIST_SEARCH_TYPES,
  MUSIC_PLAYER_ACTIONS,
  MUSIC_PLAYING_FROM,
} from "../utils/Enum";
import useMusic from "./useMusic";
import { useAlert } from "../components/Alert/AlertContext";
import { updateQueue } from "../utils/dbUtils/userUtils";
import { useAuth } from "../contexts/AuthContext";
import { updateWebPageIcon } from "../utils/MusicUtils";

//console.log(Songs().map(s => s.songId))
const initialState = {
  currentSong: 0,
  playlist: [],
  isPlaying: false,
  isShuffle: false,
  isRepeat: false,
  isMute: false,
  volume: 0.5,
  playId: 0,
  playType: "skip"
};
export const useSonarMusePlayer = () => {
  /* states */
  const { addAlert } = useAlert();
  const { currentUser } = useAuth();
  const {
    changeArtistPaymentChannel,
    defaultPaymentChannel,
    changePodcasterPaymentChannel,
    userRoyaltyPayout,
  } = SonarMuseWebMonetization();
  const [state, dispatch] = useReducer(AudioReducer, initialState);
  const [duration, setDuration] = useState(0);
  const { updateSongDuration, updateMusicPlay } = useMusic();
  const [currentTime, setcurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [id, setId] = useState(state.currentSong);
  const [isUpdating, setUpdating] = useState(false)
  const [currentPointer, setCurrentPointer] = useState();
  // const [timeJump, setTimeJump] = useState(0)
  /* Refs */
  const audioPlayer = useRef(); //Used to reference audioplayer
  const progressBar = useRef(); // Reference to the progressBar
  const animationRef = useRef(); // Reference the animation of song play

  useEffect(() => {
    if (state?.isPlaying && audioPlayer && audioPlayer?.current) {
      audioPlayer.current.autoplay = true;
    }
    return () => {};
  }, [id, state?.isPlaying]);

  useEffect(() => {
    const onWaiting = () => {
      if (isPlaying) {
       
        setIsPlaying(false);
      } 
      setIsWaiting(true);
    };
  
    const onPlay = () => {
      if (isWaiting) setIsWaiting(false);
      dispatch({type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: true})
      play()
      if (state?.isPlaying) {
      
        document.title = state?.playlist[state.currentSong]?.songName
          ? `${getSongTitle(
              state?.playlist[state.currentSong]
            )} - ${getArtistsNames2(
              state?.playlist[state.currentSong].artists,
              ARTIST_SEARCH_TYPES.NAME
            )}`
          : state?.playlist[state.currentSong]?.episodeName
          ? state?.playlist[state.currentSong]?.episodeName
          : "Sonar Muse";
        updateWebPageIcon(
          state.playlist[state.currentSong]?.albumArt
            ? state.playlist[state.currentSong]?.albumArt
            : state.playlist[state.currentSong]?.thumbnail
        );
      } else {
        document.title = "Sonar Muse";
        updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
      }
     
        
      
      setIsPlaying(true);
    };
    if (audioPlayer && audioPlayer?.current) {
      audioPlayer.current.preload = "auto";
      audioPlayer.current?.addEventListener("waiting", onWaiting);
      audioPlayer.current?.addEventListener("play", onPlay);
      audioPlayer.current?.addEventListener("playing", onPlay);
      /* audioPlayer.current?.addEventListener('onEnded', () => {
      if((state?.isRepeat === 1 || state?.isRepeat === 2) || state?.currentSong <= state?.playlist?.length -1){
        if(state?.isRepeat === 2){
          audioPlayer.current.loop = true
        }else{
          audioPlayer.current.loop = false
        }
        
        play()
        return
      }
      if((state?.isRepeat === 1 || state?.isRepeat === 2) && state?.playlist?.length === 1){
        audioPlayer.current.loop = true
        play()
        return
      }
      dispatch({type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false})
      audioPlayer.current.pause();
    }); */
    

      return () => {
        //audioPlayer.current?.removeEventListener('onEnded', () => {})
        audioPlayer?.current?.removeEventListener("waiting", onWaiting);
        audioPlayer?.current?.removeEventListener("play", onPlay);
        audioPlayer?.current?.removeEventListener("playing", onPlay);
      };
    }
  }, [state, audioPlayer, isPlaying]);

  useEffect(() => {
    if (state?.isPlaying) {
    
      processMusicPlay(Math.round(audioPlayer?.current?.currentTime))
      processPayouts(Math.round(audioPlayer?.current?.currentTime))
    
  }
    return () => {

    };
  })

  

  useEffect(() => {
    if (
      state?.playlist?.length > 0 &&
      (!currentTime || currentTime === 0) &&
      !state?.isPlaying
    ) {
      timeTravel(0);
    }
    return () => {};
  }, [currentTime, state?.isPlaying, state?.playlist?.length]);

  /* useEffect(() => {
    if(state.playlist.length === 0){
      dispatch({type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data:false})
      //audioPlayer?.current?.pause()
      setDuration();
    }
    return () => {} 
  }, [state]) */

  //extract track information



  const setCurrentSong = (val) => {
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0});
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
    setId(val);
    dispatch({
      type: MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG,
      data: val,
    });
    if (
      state?.playlist?.length > 0 &&
      state?.playlist[val]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state?.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      state?.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, {
          playlist: state?.playlist,
          currentSong: val,
          isRepeat: state?.isRepeat,
          isShuffle: state?.isShuffle,
          isMute: state?.isMute,
          volume: state?.volume,
        });
      }
    }
  };

  const setPlaylist = (pl) => {
    dispatch({ type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST, data: pl });
    if (
      pl?.length > 0 &&
      pl[state?.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      pl[state?.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      pl[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, {
          playlist: pl,
          currentSong: state?.currentSong,
          isRepeat: state?.isRepeat,
          isShuffle: state?.isShuffle,
          isMute: state?.isMute,
          volume: state?.volume,
        });
      }
    }
  };

  const prevSong = () => {
    
    if (state.playlist.length > 0) {
      timeTravel(0);
      let prev;
      if (state.currentSong === 0) {
        prev = state.playlist.length - 1;
      } else {
        prev = state.currentSong - 1;
      }
      if (
        state.playlist?.length > 0 &&
        state.playlist[prev]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[prev]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[prev]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        updateWebPageIcon(
          state.playlist[prev]?.albumArt
            ? state.playlist[prev]?.albumArt
            : state.playlist[prev]?.thumbnail
        );
      }
      if (state?.isPlaying) {
        document.title = state?.playlist[prev]?.songName
          ? `${getSongTitle(state?.playlist[prev])} - ${getArtistsNames2(
              state?.playlist[prev].artists,
              ARTIST_SEARCH_TYPES.NAME
            )}`
          : state?.playlist[prev]?.episodeName
          ? state?.playlist[prev]?.episodeName
          : "Sonar Muse";
      } else {
        document.title = "Sonar Muse";
        updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
      }
      setDuration(state?.playlist[prev]?.duration);
      setCurrentSong(prev);
    }
  };

  const nextSong = () => {
   
    if (state.playlist.length > 0) {
      let next;
      if (state.isShuffle) {
        let random = Math.floor(Math.random() * state.playlist.length);
        next =
          random <= state.playlist.length - 1 && random !== state.currentSong
            ? random
            : state.playlist.length - 1;
        setCurrentSong(
          random <= state.playlist.length - 1 && random !== state.currentSong
            ? random
            : state.playlist.length - 1
        );
      } else {
        if (state.currentSong === state.playlist.length - 1) {
          setCurrentSong(0);
          next = 0;
        } else {
          setCurrentSong(state.currentSong + 1);
          next = state.currentSong + 1;
        }
      }
      if (
        state.playlist?.length > 0 &&
        state.playlist[next]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[next]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[next]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        updateWebPageIcon(
          state.playlist[next]?.albumArt
            ? state.playlist[next]?.albumArt
            : state.playlist[next]?.thumbnail
        );
      }
      if (state?.isPlaying) {
        document.title = state?.playlist[next]?.songName
          ? `${getSongTitle(state?.playlist[next])} - ${getArtistsNames2(
              state?.playlist[next].artists,
              ARTIST_SEARCH_TYPES.NAME
            )}`
          : state?.playlist[next]?.episodeName
          ? state?.playlist[next]?.episodeName
          : "Sonar Muse";
      } else {
        updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
        document.title = "Sonar Muse";
      }

      timeTravel(0);
      setDuration();
    }
  };

  const toggleRepeat = () => {
    let action;
    if (!state.isRepeat) {
      if (state.playlist?.length > 1) {
        action = 1;
        dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_REPEAT, data: 1 });
      } else {
        action = 2;
        dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_REPEAT, data: 2 });
      }
      if (
        state.playlist?.length > 0 &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (currentUser?.uid) {
          updateQueue(currentUser?.uid, {
            ...state,
            isShuffle: action === 2 ? false : state.isShuffle,
            isRepeat: action,
          });
        }
      }

      return;
    }
    if (state.isRepeat === 1) {
      dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_REPEAT, data: 2 });
      if (
        state.playlist?.length > 0 &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (currentUser?.uid) {
          updateQueue(currentUser?.uid, {
            ...state,
            isShuffle: false,
            isRepeat: 2,
          });
        }
      }
    }
    if (state.isRepeat === 2) {
      dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_REPEAT, data: false });
      if (
        state.playlist?.length > 0 &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (currentUser?.uid) {
          updateQueue(currentUser?.uid, {
            ...state,
            isShuffle: state.isShuffle,
            isRepeat: false,
          });
        }
      }
      return;
    }
  };

  const toggleShuffle = () => {
    let action = !state.isShuffle;
    if (
      state.playlist?.length > 0 &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, {
          ...state,
          isShuffle: action,
          isRepeat: action && state.isRepeat === 2 ? 1 : state.isRepeat,
        });
      }
    }
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_SHUFFLE, data: action });
  };

  const handleEnd = () => {
     dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0});
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
    timeTravel(0);
    if (
      state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION
    ) {
      if (state.isShuffle) {
        let random = Math.floor(Math.random() * state.playlist.length);
        setCurrentSong(
          random <= state.playlist.length - 1
            ? random
            : state.playlist.length - 1
        );
        return;
      }
      if (
        !state?.isRepeat &&
        !state.isShuffle &&
        state?.currentSong === state?.playlist?.length - 1
      ) {
        stop();
        
        return;
      }
      if (
        state?.isRepeat === 1 &&
        !state.isShuffle &&
        state?.currentSong === state?.playlist?.length - 1
      ) {
        setCurrentSong(0);
        return;
      }
      if (
        state?.isRepeat !== 2 &&
        !state.isShuffle &&
        state?.currentSong < state?.playlist?.length - 1
      ) {
        setCurrentSong(state?.currentSong + 1);
        return;
      }
      if (state.isRepeat === 2) {
        dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0});
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
        return;
      }
    } else {
      if (state?.currentSong < state?.playlist?.length - 1) {
        setCurrentSong(state?.currentSong + 1);
        return;
      }
      if (state?.currentSong === state?.playlist?.length - 1) {
        stop();
        return;
      }
    }
  };

  useEffect(() => {
    if (
      audioPlayer &&
      audioPlayer.current &&
      audioPlayer?.current?.readyState &&
      audioPlayer.current?.duration
    ) {
      const seconds = Math.floor(audioPlayer.current?.duration + 2);
      if (state?.playlist[state?.currentSong]?.songId && !state?.playlist[state?.currentSong]?.duration ) {
        updateSongDuration(state?.playlist[state?.currentSong]?.songId , audioPlayer.current?.duration)
      }
      if (progressBar?.current) {
        progressBar.current.value = 0;
        setcurrentTime(audioPlayer?.current?.currentTime);
        setDuration(seconds);
        progressBar.current.max = seconds;
      }
    }
    return () => {};
  }, [
    audioPlayer?.current?.loadeddata,
    audioPlayer?.current?.loadedmetadata,
    audioPlayer?.current?.readyState,
    dispatch,
    id,
    state?.currentSong,
    state?.playlist,
  ]);

  useEffect(() => {
    if (
      !isNaN(currentTime) &&
      Number(duration) > 0 &&
      Number(currentTime) === Number(duration)
    ) {
      progressBar.current.value = 0;
      defaultPaymentPointer();
      defaultPaymentChannel();
      handleEnd();
    }
    return () => {};
  }, [currentTime, duration, state?.currentSong, state?.playlist,]);

  const handleToggle = (type, val) => {
    switch (type) {
      case "repeat":
        toggleRepeat();
        break;
      case "shuffle":
        toggleShuffle();

        break;
      case "prev":
        if (currentTime > 5) {
          timeTravel(0);
        } else {
          prevSong();
        }

        break;
      case "play-pause":
        togglePlayPause();

        break;
      case "play":
        if (!state?.isPlaying) {
          play();
          localStorage.setItem(
            "isPlayingFrom",
            JSON.stringify(
              state.playlist[state?.currentSong]?.playingFrom
                ? state.playlist[state?.currentSong]?.playingFrom
                : {
                    playingFrom: state.playlist[state?.currentSong]?.songId
                      ? MUSIC_PLAYING_FROM.SONG
                      : MUSIC_PLAYING_FROM.EPISODE,
                    id: state.playlist[state?.currentSong]?.songId
                      ? state.playlist[state?.currentSong]?.songId
                      : state.playlist[state?.currentSong]?.episodeId,
                  }
            )
          );
          if (
            state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.NFT &&
            state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE
          ) {
            if (currentUser?.uid) {
              updateQueue(currentUser?.uid, {
                playlist: state.playlist,
                currentSong: state?.currentSong,
                isRepeat: state?.isRepeat,
                isShuffle: state?.isShuffle,
                isMute: state?.isMute,
                volume: state?.volume,
              });
            }
            if (
              state.playlist[state?.currentSong]?.isUserRoyalties &&
              state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
            ) {
              let random = Math.floor(Math.random() * 101);
              // console.log(random)
              if (
                random <=
                state.playlist[state?.currentSong]?.userRoyalties?.percentage
              ) {
                return userRoyaltyPayout(
                  state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
                );
              } else {
                if (
                  state.playlist[state?.currentSong]?.royalties &&
                  state.playlist[state?.currentSong]?.songId
                ) {
                  changeArtistPaymentChannel(
                    state.playlist[state.currentSong].royalties,
                    `song ${getSongTitle(
                      state.playlist[state?.currentSong]
                    )} (id: ${state.playlist[state?.currentSong]?.songId})`
                  );
                }
                if (
                  state.playlist[state?.currentSong]?.royalties &&
                  state.playlist[state?.currentSong]?.episodeId
                ) {
                  changePodcasterPaymentChannel(
                    state.playlist[state?.currentSong]?.royalties,
                    state.playlist[state?.currentSong]?.artistRoyalties,
                    `episode ${
                      state.playlist[state.currentSong]?.episodeName
                    } (id: ${state.playlist[state?.currentSong]?.episodeId})`
                  );
                }
              }
              return;
            }
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.songId
            ) {
              return changeArtistPaymentChannel(
                state.playlist[state.currentSong].royalties,
                `song ${getSongTitle(
                  state.playlist[state?.currentSong]
                )} (id: ${state.playlist[state?.currentSong]?.songId})`
              );
            }
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.episodeId
            ) {
              return changePodcasterPaymentChannel(
                state.playlist[state?.currentSong]?.royalties,
                state.playlist[state?.currentSong]?.artistRoyalties,
                `episode ${
                  state.playlist[state.currentSong]?.episodeName
                } (id: ${state.playlist[state?.currentSong]?.episodeId})`
              );
            }
          }
        }
        break;
      case "pause":
        document.title = "Sonar Muse";
        audioPlayer.current.pause();
        defaultPaymentPointer();
        defaultPaymentChannel();
        cancelAnimationFrame(whilePlaying);
        break;
      case "next":
        nextSong();

        break;
      case "volume":
        //audioPlayer.current.volume = (val / 100).toFixed(1)
        dispatch({
          type: MUSIC_PLAYER_ACTIONS.VOLUME_CHANGE,
          data: val.toFixed(1),
        });

        break;
      case "mute":
        toggleMute();
        break;
      default:
        break;
    }
  };

  const toggleMute = () => {
    let action = !state.isMute;
    //console.log("Mute: ", !state.isMute)
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_MUTE, data: action });
    if (
      state.playlist?.length > 0 &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, { ...state, isMute: action });
      }
    }
  };

  const toggleVolume = (e) => {
    //console.log((e.target.value / 100).toFixed(1))
    audioPlayer.current.volume = (e.target.value / 100).toFixed(1);
    dispatch({
      type: MUSIC_PLAYER_ACTIONS.VOLUME_CHANGE,
      data: (e.target.value / 100).toFixed(1),
    });
    if (
      state.playlist?.length > 0 &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, {
          ...state,
          volume: (e.target.value / 100).toFixed(1),
        });
      }
    }
  };

  /*  useEffect(() => {

        if (timeJump && timeJump > 0) {
            timeTravel(timeJump)
            setPlaying(true)
            play()  
   
        }
    }, [timeJump]) */

  const calcTime = (secs) => {
   
    return convertDuration(secs);
  };

  const processMusicPlay = (secs) => {
    try{
      if (parseInt(secs) === 5) {
       return updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong].episodeId ? "episode" : "song",
          state.playlist[state.currentSong].episodeId
            ? state.playlist[state.currentSong].episodeId
            : state.playlist[state.currentSong].songId,
          state.playId,
          "skip",
          state.playlist[state.currentSong].playingFrom
        ).then((data) => { 
          if(data){
            dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: data});
            return
          }
          
          return dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
        }).catch(err => {
          console.error(err)
        });
      }
      if (parseInt((parseInt(secs) / duration) * 100) >= 45 && state.playType === "skip") {
        dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "partial"})
       return updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong].episodeId ? "episode" : "song",
          state.playlist[state.currentSong].episodeId
            ? state.playlist[state.currentSong].episodeId
            : state.playlist[state.currentSong].songId,
          state.playId,
          "partial",
          state.playlist[state.currentSong].playingFrom
        ).then((data) => { 
          if(data){
           return dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: data});
            
          }
          return dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
        }).catch(err => {
          console.error(err)
          return dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
        });
      }
      if (parseInt((parseInt(secs) / duration) * 100) >= 75 && state.playType === "partial") {
        dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "full"})
       return updateMusicPlay(
          currentUser?.uid,
          state.playlist[state.currentSong].episodeId ? "episode" : "song",
          state.playlist[state.currentSong].episodeId
            ? state.playlist[state.currentSong].episodeId
            : state.playlist[state.currentSong].songId,
          state.playId,
          "full",
          state.playlist[state.currentSong].playingFrom
        ).then((data) => { 
          if(data){
          return dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: data});
        }
         dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "partial"})
        }).catch(err => {
          console.error(err)
          dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "partial"})
        });   
  }
  if(parseInt(secs) >= parseInt(duration) && state.playType === "full"){
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0});
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
  }
}catch(err){
  console.error(err)
}
  }

  const processPayouts = (secs) => {
    if (secs % 10 === 0) {
      if (
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (
          state.playlist[state?.currentSong]?.isUserRoyalties &&
          state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
        ) {
          let random = Math.floor(Math.random() * 101);
          // console.log(random)
          if (
            random <=
            state.playlist[state?.currentSong]?.userRoyalties?.percentage
          ) {
            return userRoyaltyPayout(
              state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
            );
          } else {
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.songId
            ) {
              return changeArtistPaymentChannel(
                state.playlist[state.currentSong].royalties,
                `song ${getSongTitle(
                  state.playlist[state?.currentSong]
                )} (id: ${state.playlist[state?.currentSong]?.songId})`
              );
            }
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.episodeId
            ) {
              return changePodcasterPaymentChannel(
                state.playlist[state?.currentSong]?.royalties,
                state.playlist[state?.currentSong]?.artistRoyalties,
                `episode ${
                  state.playlist[state.currentSong]?.episodeName
                } (id: ${state.playlist[state?.currentSong]?.episodeId})`
              );
            }
            
          }
       
        }
        if (!state.playlist[state?.currentSong]?.isUserRoyalties && state.playlist[state?.currentSong]?.royalties) {
          if (state.playlist[state?.currentSong]?.songId) {
            return changeArtistPaymentChannel(
              state.playlist[state.currentSong].royalties,
              `song ${getSongTitle(state.playlist[state.currentSong])} (id: ${
                state.playlist[state?.currentSong]?.songId
              })`
            );
          }
          if (state.playlist[state?.currentSong]?.episodeId) {
            return changePodcasterPaymentChannel(
              state.playlist[state?.currentSong]?.royalties,
              state.playlist[state?.currentSong]?.artistRoyalties,
              `episode ${state.playlist[state.currentSong]?.episodeName} (id: ${
                state.playlist[state?.currentSong]?.episodeId
              })`
            );
          }
          
        } 
      }else {
        defaultPaymentPointer();
        defaultPaymentChannel();
      }
    }
  }


  const togglePlayPause = () => {
    let prevVal = state.isPlaying;
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: !prevVal });
    if (!prevVal) {
      play();
      localStorage.setItem(
        "isPlayingFrom",
        JSON.stringify(
          state.playlist[state?.currentSong]?.playingFrom
            ? state.playlist[state?.currentSong]?.playingFrom
            : {
                playingFrom: state.playlist[state?.currentSong]?.songId
                  ? MUSIC_PLAYING_FROM.SONG
                  : MUSIC_PLAYING_FROM.EPISODE,
                id: state.playlist[state?.currentSong]?.songId
                  ? state.playlist[state?.currentSong]?.songId
                  : state.playlist[state?.currentSong]?.episodeId,
              }
        )
      );
      if (
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (
          state.playlist[state?.currentSong]?.isUserRoyalties &&
          state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
        ) {
          let random = Math.floor(Math.random() * 101);
          // console.log(random)
          if (
            random <=
            state.playlist[state?.currentSong]?.userRoyalties?.percentage
          ) {
            return userRoyaltyPayout(
              state.playlist[state?.currentSong]?.userRoyalties?.royaltyId
            );
          } else {
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.songId
            ) {
              changeArtistPaymentChannel(
                state.playlist[state.currentSong].royalties,
                `song ${getSongTitle(
                  state.playlist[state?.currentSong]
                )} (id: ${state.playlist[state?.currentSong]?.songId})`
              );
            }
            if (
              state.playlist[state?.currentSong]?.royalties &&
              state.playlist[state?.currentSong]?.episodeId
            ) {
              changePodcasterPaymentChannel(
                state.playlist[state?.currentSong]?.royalties,
                state.playlist[state?.currentSong]?.artistRoyalties,
                `episode ${
                  state.playlist[state.currentSong]?.episodeName
                } (id: ${state.playlist[state?.currentSong]?.episodeId})`
              );
            }
          }
          return;
        }
        if (
          state.playlist[state?.currentSong]?.royalties &&
          state.playlist[state?.currentSong]?.songId
        ) {
          changeArtistPaymentChannel(
            state.playlist[state.currentSong].royalties,
            `song ${getSongTitle(state.playlist[state.currentSong])} (id: ${
              state.playlist[state?.currentSong]?.songId
            })`
          );
        }
        if (
          state.playlist[state?.currentSong]?.royalties &&
          state.playlist[state?.currentSong]?.episodeId
        ) {
          changePodcasterPaymentChannel(
            state.playlist[state?.currentSong]?.royalties,
            state.playlist[state?.currentSong]?.artistRoyalties,
            `episode ${state.playlist[state.currentSong]?.episodeName} (id: ${
              state.playlist[state?.currentSong]?.episodeId
            })`
          );
        }
        updateWebPageIcon(
          state.playlist[state?.currentSong]?.albumArt
            ? state.playlist[state?.currentSong]?.albumArt
            : state.playlist[state?.currentSong]?.thumbnail
        );
        if (currentUser?.uid) {
          updateQueue(currentUser?.uid, {
            playlist: state.playlist,
            currentSong: state?.currentSong,
            isRepeat: state?.isRepeat,
            isShuffle: state?.isShuffle,
            isMute: state?.isMute,
            volume: state?.volume,
          });
        }
      }
    } else {
      document.title = "Sonar Muse";
      audioPlayer.current.pause();
      defaultPaymentPointer();
      defaultPaymentChannel();
      updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
      cancelAnimationFrame(whilePlaying);
    }
  };

  const playSong = (song) => {
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
    dispatch({ type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST, data: [song] });
    setCurrentSong(state?.playlist?.length - 1);
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: true });
  };

  const removeFromQueue = (song) => {
    let currentS = state?.currentSong;
    let currentPlaylist = state?.playlist;
    if (state.playlist.length === 1) {
      dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
    }
    if (state.playlist.length > 0) {
      currentPlaylist = state.playlist.filter(
        (s) => s.songId !== song.songId || s.episodeId !== song.episodeId
      );
      if (
        state?.playlist[state.currentSong]?.songId ||
        state?.playlist[state.currentSong]?.episodeId
      ) {
        let cSong = state?.playlist[state.currentSong]?.songId
          ? state?.playlist[state.currentSong]?.songId
          : state?.playlist[state.currentSong]?.episodeId;
        if (cSong === song.songId || cSong === song.episodeId) {
          currentS = 0;
        } else {
          currentS = currentPlaylist.findIndex(
            (s) => s.songId === cSong || s.episodeId === cSong
          );
        }
      }
    } else {
      currentPlaylist = [];
      currentS = 0;
    }
    dispatch({
      type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST,
      data: currentPlaylist,
    });
    dispatch({
      type: MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG,
      data: currentS,
    });
    if (
      state.playlist?.length > 0 &&
      state.playlist[currentS]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.NFT &&
      state.playlist[currentS]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.COLLECTION &&
      state.playlist[currentS]?.playingFrom?.playingFrom !==
        MUSIC_PLAYING_FROM.MUSIC_STORE
    ) {
      if (currentUser?.uid) {
        updateQueue(currentUser?.uid, {
          playlist: currentPlaylist,
          currentSong: currentS,
          isRepeat: state?.isRepeat,
          isShuffle: state?.isShuffle,
          isMute: state?.isMute,
          volume: state?.volume,
        });
      }
    }
  };

  const addToQueue = (songs) => {
    let playlist = [];
    let cSong = state?.currentSong;
    if (state.playlist.length > 0) {
      playlist = [...state.playlist, ...getUniqueListBy(songs, "songId")];
      dispatch({ type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST, data: playlist });
    } else {
      playlist = getUniqueListBy(songs, "songId");
      cSong = 0;
      dispatch({ type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST, data: playlist });
      dispatch({ type: MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG, data: 0 });
    }
    if (currentUser?.uid) {
      updateQueue(currentUser?.uid, {
        playlist: playlist,
        currentSong: cSong,
        isRepeat: state?.isRepeat,
        isShuffle: state?.isShuffle,
        isMute: state?.isMute,
        volume: state?.volume,
      });
    }
  };

  const playNext = (music) => {
    //console.log(music)
    let currentPlaylist = [];
    let newPlaylist = [];
    let cSong = state?.currentSong;
    if (state.playlist.length > 0) {
      if (music?.songId) {
        //console.log('song')
        currentPlaylist = state.playlist.filter(
          (s) => s.songId !== music?.songId
        );
        //console.log(currentPlaylist)
        newPlaylist = [
          ...currentPlaylist.slice(
            0,
            currentPlaylist.findIndex(
              (s) => s.songId === state.playlist[state.currentSong].songId
            ) + 1
          ),
          music,
          ...currentPlaylist.slice(
            currentPlaylist.findIndex(
              (s) => s.songId === state.playlist[state.currentSong].songId
            ) + 1
          ),
        ];
        cSong = currentPlaylist.findIndex(
          (s) => s.songId === state.playlist[state.currentSong].songId
        );
        dispatch({
          type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST,
          data: newPlaylist,
        });
        dispatch({
          type: MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG,
          data: cSong,
        });
      }
      if (music?.episodeId) {
        //console.log('episode')
        currentPlaylist = state.playlist.filter(
          (s) => s.episodeId !== music?.episodeId
        );
        //console.log(currentPlaylist)
        newPlaylist = [
          ...currentPlaylist.slice(
            0,
            currentPlaylist.findIndex(
              (s) => s.episodeId === state.playlist[state.currentSong].episodeId
            ) + 1
          ),
          music,
          ...currentPlaylist.slice(
            currentPlaylist.findIndex(
              (s) => s.episodeId === state.playlist[state.currentSong].episodeId
            ) + 1
          ),
        ];
        cSong = currentPlaylist.findIndex(
          (s) => s.episodeId === state.playlist[state.currentSong].episodeId
        );
        dispatch({
          type: MUSIC_PLAYER_ACTIONS.SET_PLAYLIST,
          data: newPlaylist,
        });
        dispatch({
          type: MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG,
          data: cSong,
        });
      }
      if (
        newPlaylist?.length > 0 &&
        newPlaylist[cSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        newPlaylist[cSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        newPlaylist[cSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (currentUser?.uid) {
          updateQueue(currentUser?.uid, {
            playlist: newPlaylist,
            currentSong: cSong,
            isRepeat: state?.isRepeat,
            isShuffle: state?.isShuffle,
            isMute: state?.isMute,
            volume: state?.volume,
          });
        }
      }
    } else {
      dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
    }
  };

  //Stop the current song
  const stop = () => {
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID, data: 0})
    dispatch({type: MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE, data: "skip"})
    audioPlayer.current.pause();
    defaultPaymentPointer();
    defaultPaymentChannel();
    dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
    timeTravel(0);
    updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
  };

  const play = async () => {
    if (audioPlayer?.current?.paused && !state.isPlaying) {
      return audioPlayer?.current
        .play()
        .then(() => {
          try {
            if (state?.playlist?.length > 0) {
              dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: true });
              animationRef.current = requestAnimationFrame(whilePlaying);
              audioPlayer.current.loop = state?.isRepeat === 2;
              audioPlayer.current.volume = state?.volume;
              audioPlayer.current.muted = state?.isMute;
            } else {
              dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
            }
          } catch (err) {
            if (
              err?.includes(
                "DOMException: The play() request was interrupted by a call to pause()"
              )
            ) {
              return play();
            } else {
              dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
              timeTravel(0);
            }
          }
          return;
        })
        .catch((e) => {
          /*  if (
            e?.includes(
              "DOMException: The play() request was interrupted by a call to pause()"
            )
          ) {
            return play();
          } else { */
          document.title = "Sonar Muse";
          dispatch({ type: MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY, data: false });
          timeTravel(0);
          updateWebPageIcon("https://dev.sonarmuse.org/favicon.ico");
          //}
          return;
        });
    }
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar?.current?.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    setcurrentTime(progressBar?.current?.value);
    progressBar?.current?.style.setProperty(
      `${Theme({}).accentColor}`,
      `${(progressBar?.current?.value / duration) * 100}%`
    );
  };

  const whilePlaying = () => {
    try {
      if (progressBar?.current && audioPlayer?.current) {
        progressBar.current.value =
          audioPlayer?.current?.currentTime > 0
            ? audioPlayer?.current?.currentTime
            : 0;
        changePlayerCurrentTime();
        animationRef.current = requestAnimationFrame(whilePlaying);
      }
      return;
    } catch (err) {
      if (progressBar?.current?.value) {
        progressBar.current.value = 0;
      }
    }
  };

  const backThirty = () => {
    timeTravel(Number(progressBar?.current?.value) - 30);
  };

  const forwardThirty = () => {
    timeTravel(Number(progressBar?.current?.value) + 30);
  };

  const timeTravel = (newTime) => {
    if (progressBar?.current?.value) {
      progressBar.current.value = newTime;
      changeRange();
    }
  };

  return {
    isPlaying: state?.isPlaying,
    isRepeat: state?.isRepeat,
    isShuffle: state?.isShuffle,
    currentSong: state?.currentSong,
    playlist: state?.playlist,
    volume: state?.volume,
    isMute: state?.isMute,
    isWaiting: isWaiting,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    setDuration,
    setCurrentSong,
    setPlaylist,
    handleToggle,
    toggleShuffle,
    toggleMute,
    toggleVolume,
    playNext,
    removeFromQueue,
    addToQueue,
    //setTimeJump,
    timeTravel,
    calcTime,
    play,
    playSong,
    togglePlayPause,
    changeRange,
    backThirty,
    forwardThirty,
    handleEnd,
  };
};
