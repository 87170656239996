import React, { useEffect, useState } from "react";
import useArtist from "../../../hooks/useArtist";
import useMusic from "../../../hooks/useMusic";
import { getObjectLength } from "../../../utils";
import { ALERT_TYPES, CONTENT_TYPES, MUSIC_PLAYING_FROM, SLIDER_TYPES } from "../../../utils/Enum";
import { useAlert } from "../../Alert/AlertContext";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import Carousel from "../../Carousel";
import ContentList from "../../ContentList";
import ContentWrapper from "../../ContentWrapper";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import ScrollToTop from "../../ScrollToTop";
import { ContentListSkeleton } from "../../Skeleton";
import { Slider } from "../../Slider";
import { isUserPayingOut } from "../../SonarMuseWebMonetization";
import { Snapshot, Snapshot2 } from "../../CountdownTimer/Data";
import CountdownTimer from "../../CountdownTimer";
import { getNewAlbumReleases, getNewPodcastEpisodes, getNewSongReleases } from "../../../utils/dbUtils/musicUtils";
import useVideos from "../../../hooks/useVideos";

const MusicContent = () => {
  const [content, setContent] = useState({});
  const [isLoaded, setLoaded] = useState(false)
  const [isCollectionsLoaded, setCollectionsLoaded] = useState(false)
  const [isLatestReleasesLoaded, setLatestReleasesLoaded] = useState(false)
  const [isLatestEpisodesLoaded, setLatestEpisodesLoaded] = useState(false)
  const [isTopReleasesLoaded, setTopReleasesLoaded] = useState(false)
  const [isVideosLoaded, setVideosLoaded] = useState(false)
  const [isNewPlaylistsLoaded, setNewPlaylistsLoaded] = useState(false)
  const [isTrendingPlaylistsLoaded, setTrendingPlaylistsLoaded] = useState(false)
  const [isGenresLoaded, setGenresLoaded] = useState(false)
  const [isSonarPlaylistsLoaded, setSonarPlaylistsLoaded] = useState(false)
  const [isLatestSonarPlaylistsLoaded, setLatestSonarPlaylistsLoaded] = useState(false)
  const [isRoyaltiesLoaded, setRoyaltiesLoaded] = useState(false);
  const [collections, setCollections] = useState([])
  const [latestReleases, setLatestReleases] = useState([])
  const [latestEpisodes, setLatestEpisodes] = useState([])
  const [topReleases, setTopReleases] = useState([])
  const [songVideos, setSongVideos] = useState([])
  const [sonarMusePlaylists, setSonarMusePlaylists] = useState([])
  const [latestSonarMusePlaylists, setLatestSonarMusePlaylists] = useState([])
  const [newestPlaylists, setNewestPlaylists] = useState([])
  const [trendingPlaylists, setTrendingPlaylists] = useState([])
  const [musicRoyalties, setMusicRoyalties] = useState([])
  const [genres, setGenres] = useState([])
  const {goTo} = ScrollToTop()
  const {toggleModal, modalContent} = useModal()
  const { addAlert } = useAlert();
  const {
    getNFTokenCollections,
    getAllGenres,
    getLatestReleases,
    getTopReleases,
    getLatestSongs,
    getLatestAlbums,
    getLatestEpisodes,
    getTrendingPlaylists,
    getNewestPlaylists,
    getSonarMusePlaylists,
    getLatestSonarMusePlaylists,
    getMusicRoyalties
  } = useMusic();
  const {} = useArtist({});
  const {getLatestSongVideos} = useVideos()

  useEffect(() => {

    getLatestSonarMusePlaylists().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            latestSonarMusePlaylists: data
        }})
        setLatestSonarMusePlaylists(data)
        
    }setLatestSonarPlaylistsLoaded(true)
    }).catch((err) => {
      setLatestSonarPlaylistsLoaded(true)
    });
    getMusicRoyalties(0).then(data => {
      if (data?.length > 0) {
    
       
        setMusicRoyalties(data);
      }
      setRoyaltiesLoaded(true)
    }).catch(err => {
      setRoyaltiesLoaded(true)
    })
    getSonarMusePlaylists().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            sonarMusePlaylists: data
        }})
        setSonarMusePlaylists(data)
        
    }setSonarPlaylistsLoaded(true)
    }).catch((err) => {
      setSonarPlaylistsLoaded(true)
    });
    getNewestPlaylists().then((data) => {
      if(data.length > 0){
        setContent(prev => {return { ...prev,
            newestPlaylists: data
        }})
        
    }setNewPlaylistsLoaded(true)
    }).catch((err) => {
      setNewPlaylistsLoaded(true)
    });

    getTrendingPlaylists().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            trendingPlaylists: data
        }})
    }
    setTrendingPlaylistsLoaded(true)

    }).catch((err) => {
     setTrendingPlaylistsLoaded(true)
    });

    getTopReleases().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            topReleases: data
        }})
    }
    setTopReleasesLoaded(true)
    }).catch((err) => {
      setTopReleasesLoaded(true)
    });

    getLatestEpisodes().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            latestEpisodes: data
        }})
        //getNewPodcastEpisodes(0, updateEpisodeReleases)
    }
    setLatestEpisodesLoaded(true)
    }).catch((err) => {
      setLatestEpisodesLoaded(true)
    });

    getLatestReleases().then((data) => {
      if(data?.length > 0){
        setContent(prev => {return { ...prev,
            latestReleases: data
        }})
        setLatestReleases(data)
        /* getNewAlbumReleases(0, updateAlbumReleases)
        getNewSongReleases(0, updateSongReleases) */
    }
    setLatestReleasesLoaded(true)
    }).catch((err) => {
     setLatestReleasesLoaded(true)
    });
    getAllGenres().then((data) => {
      if(data && getObjectLength(data) > 0){
        setContent(prev => {return { ...prev,
            allGenres: data
        }})
        setGenres(data)
    }
    setGenresLoaded(true)
    }).catch((err) => {
      setGenresLoaded(true)
    });
    getNFTokenCollections(0).then((data) => {
          setContent(prev => {return { ...prev,
              collections: data
          }
        })
        setCollections(data)
      setCollectionsLoaded(true)
    }).catch((err) => {
     setCollectionsLoaded(true)
    });

    getLatestSongVideos(0).then((data) => {
      if(data?.songs?.length > 0){
        setContent(prev => {
          return { ...prev,
          videos: data.songs
      }
    })
    setSongVideos(data.songs)
      }
      setVideosLoaded(true)
    }).catch(err => {
      setVideosLoaded(true)
    })
    return () => {}
   
  }, [])

  const updateSongReleases = (data) => {
    let songs = content?.latestReleases?.filter(r => r?.songId)
    setContent(prev => {
      return {
        ...prev,
        latestReleases: [...prev?.latestReleases?.filter(r => r?.albumId), data?.songs]
      }
    })
    setLatestReleases(prev => {
      return [...prev?.filter(r => r?.albumId), data?.songs]
    })
  }

  const updateAlbumReleases = (data) => {
    let albums = content?.latestReleases?.filter(r => r?.albumId)
    setContent(prev => {
      return {
        ...prev,
        latestReleases: [...prev?.latestReleases?.filter(r => r?.songId), data?.albums]
      }
    })
    setLatestReleases(prev => {
      return [...prev?.filter(r => r?.songId), data?.albums]
    })
  }

  const updateEpisodeReleases = (data) => {
    setContent(prev => {
      return {
        ...prev,
        latestEpisodes: data?.episodes
      }
    })
    setLatestEpisodes(data)
  }
  

/*   const getMusicContent = async () => {
    setLoaded(false)
    return await Promise.all([])
      .then((data) => { 
      })
      .catch((err) => {
        addAlert({
          title: 'Loading Error',
          type: ALERT_TYPES.DANGER,
          message: "Something went wrong. Reload the page",
        })
      });
  }; */

  return (
    <>
   
      <Carousel heading={`What's New`}  />
      {/* <CountdownTimer {...Snapshot2} /> */}

      {!isLatestReleasesLoaded && 
       
      
       <ContentWrapper heading={"New Music"}>
       <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON} />
     </ContentWrapper>}
     
      {(isLatestReleasesLoaded && content?.latestReleases) && 
      <ContentWrapper heading={"New Music"}>
       
       <ContentWrapper  heading={"Songs"} subheading={'View All'} onClick={() => goTo('/latestSongs')}>
       
       <Slider 
       type={SLIDER_TYPES.SONG} 
       id={"new songs music"}
       slides={content?.latestReleases.filter(m => m?.songId)} /> 
     </ContentWrapper>
     <ContentWrapper heading={"Albums"} subheading={'View All'} onClick={() => goTo('/latestAlbums')}>
       
       <Slider 
       type={SLIDER_TYPES.ALBUM} 
       id={"new album music"}
       slides={content?.latestReleases.filter(m => m?.albumId)} /> 
     </ContentWrapper>
      </ContentWrapper>}
      {!isCollectionsLoaded && <ContentWrapper heading={"NFToken Collections"}>
       <Slider  
       type={SLIDER_TYPES.PURCHASE_SKELETON}/>
     </ContentWrapper>}
      {(isCollectionsLoaded && content?.collections?.collections?.length > 0) &&
     <ContentWrapper heading={"NFToken Collections"} subheading={'View All'} onClick={() => goTo('/collections')}>
       <Slider
          type={SLIDER_TYPES.COLLECTION}
          id={"nft collections"}
          slides={content?.collections?.collections}
        />
      </ContentWrapper>} 
      {isRoyaltiesLoaded && musicRoyalties?.royalties?.length > 0 && <ContentWrapper heading={"Music Regium Shares"} subheading={'View All'} onClick={() => goTo('/regiums')}>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                    id={"music royalties"}
                   /*  onSlideClick={(i) =>
                      navigate( 
                        `collection/${collections[i].collectionId}`
                      ) 
                    }*/
                    slides={musicRoyalties?.royalties}
                  />
                </ContentWrapper>}
     {!isRoyaltiesLoaded && <ContentWrapper heading={"Music Regium Shares"}>
              
              <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
          
          </ContentWrapper> }
      {isVideosLoaded && content?.videos?.length > 0 &&
     <ContentWrapper heading={"Music Videos"} subheading={'View All'} onClick={() => goTo('/musicVideos')}>
     <Slider
        type={SLIDER_TYPES.VIDEO}
        id={"music videos"}
        slides={content?.videos}
      />
    </ContentWrapper>
     }

     {!isVideosLoaded && <><ContentWrapper heading={"Music Videos"}>
       <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON} />
     </ContentWrapper></>}
    
      {!isTopReleasesLoaded && <><ContentWrapper heading={"Top Albums"}>
       <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON} />
     </ContentWrapper>
     
     <ContentWrapper heading={"Top Songs"}>
       <ContentListSkeleton/>
     </ContentWrapper></>}

      {(isTopReleasesLoaded && content?.topReleases) &&
      <ContentWrapper heading={"Top of The Charts"}>

      <ContentWrapper heading={"Top Albums"}>
         <Slider
          type={SLIDER_TYPES.ALBUM}
          id={"top albums"}
          slides={content?.topReleases?.filter(m => m?.albumId)}
        />
      </ContentWrapper>

      <ContentWrapper id={"topSongs"} heading={"Top Songs"}>
         <ContentList
          content={content?.topReleases.filter(m => m?.songId)}
          id={"topSongs"}
          type={CONTENT_TYPES.SONG}
        />
      </ContentWrapper>
      </ContentWrapper>}

      {!isGenresLoaded && <ContentWrapper heading={"Top Genres"}>
    <Slider  
       type={SLIDER_TYPES.GENRE_SKELETON}
       id={"top genres"}/>
     </ContentWrapper>}

      {(isGenresLoaded && content?.allGenres?.length > 0) &&
      <ContentWrapper subheading={'View All'} heading={"Top Genres"} onClick={() => {}}>
        <Slider
          type={SLIDER_TYPES.GENRE}
          id={"top genres"}
          slides={content?.allGenres?.genres}
        />
      </ContentWrapper>}
      {(isLoaded && content?.newPodcasters?.length > 0) &&
      <ContentWrapper heading={"New Podcasts"}>
        <Slider
          type={SLIDER_TYPES.PODCAST}
          id={"newest podcasts"}
          slides={[1, 2, 3, 4, 5, 6]}
        />
      </ContentWrapper>}
      {!isLatestEpisodesLoaded && <ContentWrapper heading={"Latest Podcast Episodes"}>
       <Slider
         type={SLIDER_TYPES.MUSIC_SKELETON}
         id={"latest podcast episodes"}
       />
     </ContentWrapper>}
     
      {(isLatestEpisodesLoaded && content?.latestEpisodes?.length > 0) &&
      <ContentWrapper heading={"Latest Podcast Episodes"} subheading={'View All'} onClick={() => goTo('/latestEpisodes')}>
        <Slider
          type={SLIDER_TYPES.EPISODE}
          id={"latest podcast episodes"}
          slides={content?.latestEpisodes.episodes}
        />
      </ContentWrapper>}
      {(isLoaded && content?.topArtists) &&
      <ContentWrapper heading={"Top Artists"}>
        <Slider
          type={SLIDER_TYPES.ARTIST}
          id={"top artists"}
          slides={[1, 2, 3, 4, 5, 6]}
        />
      </ContentWrapper>
      }
       {!isSonarPlaylistsLoaded && <ContentWrapper heading={"Sonar Muse Playlists"}>
     <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON}
         id={"sonar muse playlists"} />
     </ContentWrapper>
     }
      {(isLatestSonarPlaylistsLoaded && content?.latestSonarMusePlaylists?.length > 0) && <ContentWrapper heading={"Sonar Muse Playlists"}>
       <Slider
          type={SLIDER_TYPES.PLAYLIST}
          id={"sonar muse playlists"}
          slides={content?.latestSonarMusePlaylists}
        />
      </ContentWrapper>}
      
      
     

    
     
    
     
     {/* <ContentWrapper heading={"New Podcasts"}>
       <Slider
         type={SLIDER_TYPES.MUSIC_SKELETON}
         id={"newest podcasts"}
       />
     </ContentWrapper> */}

     
     
     {/* <ContentWrapper heading={"Top Artists"}>
       <Slider
         type={SLIDER_TYPES.ARTIST_SKELETON}
         id={"top artists"}
       />
     </ContentWrapper> */}
     
    
      {
        /* (isLoaded && getObjectLength(content) === 0) &&
        <>
        <ContentWrapper heading={"No Content Available"}>
        Something went wrong. Reload page
      </ContentWrapper>
      
      
      </> */
      }
    </>
  );
};

export default MusicContent;
