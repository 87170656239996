import React, { useEffect, useState } from "react";
import { SLIDER_TYPES } from "../../../utils/Enum";
import ContentWrapper from "../../ContentWrapper";
import { Slider } from "../../Slider";
import ScrollToTop from "../../ScrollToTop";

export default function Discography({
  artist,
  popular,
  albums,
  singles,
  features,
  isLoaded,
  collections,
  royaltyTokens,
  getDiscography,
  discography
}) {
  const {goTo} = ScrollToTop()
  const [showAllSingles, setShowAllSingles] = useState(false);
  const [showAllAlbums, setShowAllAlbums] = useState(false);
  const [showAllFeatured, setShowAllFeatured] = useState(false);
  const [isSinglesLoaded, setSinglesLoaded] = useState(true);
  const [isAlbumsLoaded, setAlbumsLoaded] = useState(true);
  const [isFeaturedLoaded, setFeaturedLoaded] = useState(true);
 const [artistAlbums, setAlbums] = useState(albums)
  const [artistSingles, setSingles] = useState(singles)
  const [artistPopular, setPolular] = useState(popular)
  const [artistFeatures, setFeatures] = useState(features) 
  useEffect(()=> {
    setAlbums(albums)
    setSingles(singles)
    setPolular(popular)
    setFeatures(features)
    return () => {} 
  }, [showAllAlbums, showAllFeatured, showAllSingles, albums, singles, features, popular, discography, isLoaded])
  return (
    <>
      {!isLoaded && (
        <>
          <ContentWrapper>
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
            <Slider
              type={SLIDER_TYPES.MUSIC_SKELETON}
              id={"discography loader"}
            />
          </ContentWrapper>
        </>
      )}
      {isLoaded && (
        <>
          {popular?.length > 0 && (
            <ContentWrapper heading={"Popular Releases"}>
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS_MUSIC}
                slides={popular}
                id={"popular songs"}
              />
            </ContentWrapper>
          )}
          {collections?.length > 0 && (
            <ContentWrapper heading={"Music NFT Collections"}>
              <Slider
                type={SLIDER_TYPES.COLLECTION}
                slides={collections}
                id={"collections"}
              />
            </ContentWrapper>
          )}

          {royaltyTokens?.length > 0 && (
            <ContentWrapper heading={"Regium Share Tokens"}>
              <Slider
                type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                slides={royaltyTokens}
                id={"regium shares"}
              />
            </ContentWrapper>
          )}

          {albums?.length > 0 && (
            <ContentWrapper
              heading={"Albums"}
              subheading={discography?.albums?.length > 10 && (showAllAlbums ? "Hide" : "View All")}
              onClick={() => discography?.albums?.length > 10 && 
                goTo(`/artist/${artist}/albums`)
              }
            >
              <Slider
                type={SLIDER_TYPES.ALBUM}
                slides={
                    albums
                }
                id={"albums"}
              />
              {!isAlbumsLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}

          {singles?.length > 0 && (
            <ContentWrapper
              heading={"Singles"}
              subheading={discography?.songs?.length > 10 && (showAllSingles ? "Hide" : "View All")}
              onClick={() => discography?.songs?.length > 10 && goTo(`/artist/${artist}/singles`)}
            >
              <Slider
                type={SLIDER_TYPES.MISCELLANEOUS_MUSIC}
                slides={singles}
                heightScroll={showAllSingles}
                id={"singles"}
              />
              {!isSinglesLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}

          {features?.length > 0 && (
            <ContentWrapper
              heading={"Features & Appearences"}
              subheading={features?.length > 10 && (showAllFeatured ? "Hide" : "View All")}
              onClick={() => features?.length > 10 && goTo(`/artist/${artist}/appearences`)}
            >
              <Slider
                type={SLIDER_TYPES.SONG}
                slides={features}
                heightScroll={showAllFeatured}
                id={"features and appearences"}
              />
              {!isFeaturedLoaded && (
                <>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"discography loader"}
                  />
                </>
              )}
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
}
