import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import {ArtistDetailsContainer, ArtistDetailsText, ArtistImgContainer, ArtistInfoContainer, Audio, AudioControlButton, AudioPlayerContainer, AudioPlayPauseButton, AudioSkipButton, AudioText, Chapter, ControlsContainer, Img, ProgressBar, ProgressBarWrapper, VolumeIcon, VolumeKnob, VolumeWrapper, WidgetControlsWrapper, WidgetsContainer } from './AudioPlayerElements'
import {BsArrowLeftShort, BsArrowRepeat, BsArrowRightShort, BsFillSkipEndFill, BsFillSkipStartFill, BsShuffle} from 'react-icons/bs'
import {FaPlay, FaPause, FaVolumeMute, FaVolumeDown, FaVolumeUp} from 'react-icons/fa'
import {RiPlayList2Fill, RiPlayListAddFill} from 'react-icons/ri'
import Marquee from 'react-text-marquee';
import { Theme } from '../WebsiteThemeElements'
import defaultImg from '../../images/sm_album_art_placeholder.png'
import { useAuth } from '../../contexts/AuthContext'
import { ARTIST_SEARCH_TYPES } from '../../utils/Enum'
import { getArtistsNames, getSongTitle } from '../../utils'
import ToggleButton from '../ToggleButton'
import { useNavigate } from 'react-router-dom'

const AudioPlayer = ({
    timeJump,
    currentSong,
    isPlaying, 
    duration, 
    currentTime, 
    progressBar, 
    audioPlayer,
    changeRange,
    stopMainPlayer,
    setTimeJump,
    volume,
    isRepeat,
    calcTime, 
    backThirty, 
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong, 
    playlist,
    setPlaylist,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    handleEnd,
    isMute,
    openViewer}) => {
 
    const [scrollNav, setScrollNav] = useState(false);
    const {currentUser} = useAuth()
    const navigate  = useNavigate()
    useEffect(() => {
      return () => {
      };
    },);

   
    
    const changeNav = () => {
      if (window.scrollY <= (window.screen.height)) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", changeNav);
      return () => {}
    }, []);
  

    const chapters = [{
        start: 0,
        end: 0
    },
{
    start:0,
    end: 0
}]




    

    return (
        <>
                 
        <AudioPlayerContainer scrollNav={scrollNav}>
        <ArtistInfoContainer>
            <ArtistImgContainer onClick={openViewer} isOpen={isOpen} scrollNav={scrollNav}>
                <Img src={playlist[currentSong]?.albumArt ? 
                playlist[currentSong]?.albumArt: defaultImg } 
                alt={playlist[currentSong]?.songName} 
                onContextMenu={(e) => {e.preventDefault()}}/>
                </ArtistImgContainer>
            <ArtistDetailsContainer>
                <ArtistDetailsText onClick={openViewer}>{playlist[currentSong] ? getSongTitle(playlist[currentSong])?.length > 30 ? <Marquee text={getSongTitle(playlist[currentSong])}/> : getSongTitle(playlist[currentSong]) : ''}</ArtistDetailsText>
                <ArtistDetailsText onClick={openViewer}><small>{playlist[currentSong] && getArtistsNames(playlist[currentSong]?.artists, ARTIST_SEARCH_TYPES.NAME)}</small></ArtistDetailsText>
            </ArtistDetailsContainer>
        </ArtistInfoContainer>
        
        <WidgetControlsWrapper>
        <ControlsContainer>
        <AudioControlButton 
        scrollNav={scrollNav} 
        isActive={isRepeat}
        style={{width:'1rem',marginRight:'5%'}}
        onClick={() => {
            //console.log('repeat:', !isRepeat)
            handleToggle('repeat', !isRepeat)}}>
                <BsArrowRepeat/>
                </AudioControlButton>

        <AudioControlButton scrollNav={scrollNav} onClick={() => handleToggle("prev", true)}>{isPodcast ? <><BsArrowLeftShort/> 30</>: <><BsFillSkipStartFill/></>}</AudioControlButton>
        <Audio ref={audioPlayer} src={playlist[currentSong]?.url} loop={isRepeat} preload={"metadata"} volume={isMute? isMute : volume} onEnded={handleEnd} />
        <AudioPlayPauseButton 
        scrollNav={scrollNav} 
        onClick={() => handleToggle("play-pause", !isPlaying)}>
            {isPlaying ? <FaPause/> : <FaPlay style={{position: 'relative', left: '1%'}}/>}
        </AudioPlayPauseButton>
        <AudioControlButton 
        scrollNav={scrollNav} 
        onClick={() => handleToggle("next", true)}> 
        {isPodcast ? <>30 <BsArrowRightShort/>
        </> : <><BsFillSkipEndFill/></>}
        </AudioControlButton>
        <AudioControlButton 
            scrollNav={scrollNav} 
            isActive={isShuffle}
            style={{width:'1rem', marginLeft:'5%'}}
            onClick={() => {
                //console.log(!isShuffle)
                handleToggle("shuffle", !isShuffle)}}>
                <BsShuffle/>
        </AudioControlButton>
                
        </ControlsContainer>

       
       { playlist.length > 0 && <WidgetsContainer>{/*current time*/}
        <AudioText style={{marginLeft: '2%'}}>{(currentTime && !isNaN(currentTime)) && calcTime(currentTime)}</AudioText>
        {/*progress bar*/}
        <ProgressBarWrapper scrollNav={scrollNav}>
            <ProgressBar progressWidth={`${(audioPlayer?.current?.currentTime / duration) * 100}%`} scrollNav={scrollNav} type='range' defaultValue={'0'} ref={progressBar} onChange={changeRange}></ProgressBar>
            {chapters.map((chapter, i) => {
                let leftStyle = chapter.start / duration * 100
                let widthStyle = (chapter.end - chapter.start )/duration * 100
                return <Chapter key={i} left={leftStyle} width={widthStyle}></Chapter>})}
        </ProgressBarWrapper>

        {/*Duration*/}
        <AudioText>{playlist[currentSong]?.duration ? calcTime(playlist[currentSong].duration)  : (duration && !isNaN(duration)) ?calcTime(duration) : calcTime(0)  }</AudioText>
        </WidgetsContainer>}      
        
        </WidgetControlsWrapper>
        <ControlsContainer style={{fontSize: '1.3rem'}}>
            <AudioControlButton onClick={openViewer} style={{marginRight:'5%',color: Theme({}).secondaryColor}}>
                <RiPlayList2Fill fontSize={'1.3rem'}/>
            </AudioControlButton>
        <AudioControlButton onClick={toggleMute} style={{fontSize: '1.3rem', color: Theme({}).secondaryColor}}>
            <VolumeIcon>
                <ToggleButton customOnIcon={<FaVolumeMute fontSize={'1.3rem'}/> } customOffIcon={volume <= 0.4 ? <FaVolumeDown fontSize={'1.3rem'}/> : <FaVolumeUp fontSize={'1.3rem'}/>} toggleClick={toggleMute} toggle={isMute}/></VolumeIcon>
            <VolumeKnob type="range" value={isMute ? 0 : volume} onChange={toggleVolume}/>
        </AudioControlButton>
        </ControlsContainer>
        </AudioPlayerContainer>
        </>
    )
}


AudioPlayer.propTypes = {
    chapters: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number
    })),
    timeJump: PropTypes.number,
    currentSong: PropTypes.number.isRequired
}

AudioPlayer.defaultProps = {
    chapters: [],
    timeJump: 0,
    currentSong: 0,
}

export default AudioPlayer
