import React from 'react'
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(  
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )
export const NoLabelBarGraph = ({option, data}) => {
  return (
    <Bar options={{
      plugins: {
        legend: {
            display: false,
         } },
      scales:{
        x: {
          ticks: {
            display: false //this will remove only the label
       },
            display: false ////this will remove all the x-axis grid lines
        },
        y: {
          ticks: {
            display: false //this will remove only the label
       },
            display: false ////this will remove all the x-axis grid lines
        }

    }
    }} data={{...data, borderWidth: 1}}/>
  )
}

const BarGraph = ({option, data}) => {
  return (
    <Bar options={{
      plugins: {
        legend: {
            display: false,
         } },}} data={{...data, borderWidth: 1}}/>
  )
}

export default BarGraph