import React, { useEffect, useState } from "react";
import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { useModal } from "../../Modal/ModalContext";
import { ArtistSelectorModal, MusicContentMenu } from "../../MenuOptions";
import {
  CardContainer,
  CardContent,
  CardTitle,
  CreatorInfo,
  CreatorInfoContent,
  CreatorInfoWrapper,
  H6,
  InfoContentText,
  TextLink,
} from "../SongCard/SongCardElements";
import { Theme } from "../../WebsiteThemeElements";
import { useAuth } from "../../../contexts/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { getArtistsNames2, getSongTitle, getUniqueListBy } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { MdExplicit } from "react-icons/md";
import { RiVideoFill } from "react-icons/ri";
import { PlayButton, PlayContainer } from "../SongCard/SongCardElements";
import VideoPlayer from "../../VideoPlayer";
import OptionModal from "../../OptionModal";
import { useAudio } from "../../AudioPlayer/context/AudioContext";
import { getResizedImage } from "../../../utils/FileUploadsUtils";




const VideoCard = ({content, cardRef}) => {
    const { openModal, closeModal, modalContent } = useModal();
    const { currentUser } = useAuth();
    const { goTo, scrollToTop } = ScrollToTop();
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const {stop, isPlaying, togglePlayPause} = useAudio()
    const [isAlbumArtLoaded,setAlbumArtLoaded] = useState(false)
    const [albumArt, setAlbumArt] = useState(content?.albumArt)
  
    useEffect(() => {
      if(!isAlbumArtLoaded){
        getResizedImage(content?.albumArt).then(image => {
          setAlbumArt(image)
          
        }).catch(err => {
          setAlbumArt(content?.albumArt)
        })
        setAlbumArtLoaded(true)
      }
    }, [])
    return (
      <CardContainer id={content?.songId}
      onContextMenu={(e) => {
        e.preventDefault();
        if (content?.songId && currentUser?.uid) {
          openModal();
          modalContent(<MusicContentMenu music={content} />);
        }
      }} ref={cardRef} background={content?.color}>
        <div style={{ alignItems: "center", margin: "auto" }}>
          <SkeletonPurchaseCardImg
            src={isAlbumArtLoaded ? albumArt : content?.albumArt ? content?.albumArt : defaultAlbumArt}
          ><PlayContainer>
            <PlayButton style={{ width: "6rem", height: "6rem" }}
                      onClick={() => {
                        if(isPlaying){
                            togglePlayPause()
                            return
                           }
                        openModal();
                        modalContent(
                          <OptionModal heading={getSongTitle(content)}>
                            <VideoPlayer content={content} validateSubscription={true} stopMainPlayer={() => {
                               if(isPlaying){
                                togglePlayPause()
                                return
                               }
                            }} />
                          </OptionModal>
                        );
                      }}/>
          </PlayContainer></SkeletonPurchaseCardImg>
        </div>
        <CardContent>
          <CardTitle>
            <TextLink alt
              to={content?.songId ? `/song/${content?.songId}` : "#"}
            >
              {getSongTitle(content)}
            </TextLink>
          </CardTitle>
          <CreatorInfoWrapper>
            <CreatorInfo>
              <CreatorInfoContent>
                <InfoContentText>
                  {" "}
                  <TextLink
                    onClick={() => {
                      if(content?.artists?.length > 1){
                          openModal()
                         return modalContent(<ArtistSelectorModal artists={content?.artists}/>)
                      }
                      return scrollToTop()
                    }}
                    to={
                      content?.artists?.length === 1 ? `/artist/${content?.artists[0].artistId}`: 
                       "#"
                    }
                  >
                    {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                      ?.length > 30
                      ? `${getArtistsNames2(
                          content?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        ).substring(
                          0,
                          25
                        )}...`
                      : getArtistsNames2(
                          content?.artists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                  </TextLink>
                </InfoContentText>
              </CreatorInfoContent>
            </CreatorInfo>
          </CreatorInfoWrapper>
          {content?.album && 
            <CreatorInfoWrapper>
              <CreatorInfo>  
                < CreatorInfoContent>
                <InfoContentText>
                  <TextLink alt={true} to={`/album/${content?.album?.albumId}`}>from {content?.album?.albumName}</TextLink>
                </InfoContentText>
              </CreatorInfoContent>
              </CreatorInfo>
            </CreatorInfoWrapper>}
        </CardContent>
      </CardContainer>
    );
}

export default VideoCard