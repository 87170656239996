import styled from 'styled-components'
import { hexToRgba } from '../HexToRGBA'


export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const AudioPlayerContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    padding: 0 2%;
    border-top: groove 3px ${props => hexToRgba(props.theme.mainColor)};
    background: ${ props => hexToRgba(props.theme.secondaryColor,0.2)};
    border-radius: 50px;
`
export const AudioPlayerWrapper = styled.div`
    display: grid; 
    justify-content: flex-start;
    align-items: center;
    color: ${ props => props.theme.secondaryBg};
    background: ${ props => hexToRgba(props.theme.secondaryColor,0.2)};
    width: 100%;
    max-width: 90rem;
    grid-area: 1/2/2/2;
    justify-self: start;
    overflow: hidden;
    margin: 0 2%;
    grid-template-columns: auto 1fr;
    box-shadow: inset 0 0 0 0.5px ${ props => hexToRgba(props.theme.secondaryBg,0.1)};
    border-radius: 3px;

`

export const Audio = styled.audio`

`

export const ArtistInfoContainer = styled.div`

  display: flex;
  padding: 1% 0%;
  justify-content: center;
  flex: 1 1 100%;
  flex-direction: row;
  cursor: pointer;
`

export const ArtistImgContainer = styled.div`
  display: flex;
  border: 1px;
  box-shadow: 0.5px 1px 0px 0px  ${props => props.theme.secondaryBg};
  border-radius: 5%;
  margin-right: 5%;
  height: 2.8rem;
  width: 2.8rem;
  z-index: 20;

  &:hover{
    transform: scale(1.07);
    transition: 0.8s ease-out;
}
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 2rem;
  width: 2rem;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    height: 1.2rem;
  width: 1.2rem;
    transition: 0.8s all ease;
  } 

`

export const ArtistDetailsContainer = styled.div`
display: flex;
position: relative;
flex-direction: column;
justify-content: center;
overflow: hidden;

`
export const ArtistDetailsText = styled.text`
  display: flex;
  font-size: 0.8rem;
  white-space: nowrap;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    font-size: 0.4rem;
    justify-content: space-evenly;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    justify-content: space-evenly;
    transition: 0.8s all ease;
    font-size: 0.35rem;
  } 
  @media screen and (max-width: 960px) {
    justify-content: space-evenly;
    transition: 0.8s all ease;
    font-size: 0.35rem;
  } 
`

export const Img = styled.img`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const ControlsContainer = styled.div`
display: flex;
flex-direction: row;
padding: 1% 2%;
flex: 1 1 100%;
align-content: center;
justify-content: center;
`

export const WidgetsContainer = styled.div`
display: flex;
align-content: center;
justify-content: center;
margin: -2% 1%;
padding: 1% 2%;
flex: 1 1 100%;

@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    font-size: 0.4rem;
    justify-content: space-evenly;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    justify-content: space-evenly;
    transition: 0.8s all ease;
    font-size: 0.35rem;
  } 

`

export const AudioPlayerDurationTimerWrapper = styled.div`
    margin-top: 1%;
    display: flex;
    text-align: start;
    width: 100%;
    flex: 1 1 100%;
    align-items: center;
    font-size: 0.6rem;
    justify-content: space-between;
    transition: 0.8s all ease;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.4rem;
        justify-content: space-evenly;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        justify-content: space-evenly;
        transition: 0.8s all ease;
        font-size: 0.35rem;
      } 

`


export const AudioPlayerTimerText = styled.small`
      display: flex;
      padding: 0 2%;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        font-size: 0.4rem;
        justify-content: space-evenly;
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        justify-content: space-evenly;
        transition: 0.8s all ease;
        font-size: 0.35rem;
      } 

`

export const AudioPlayerSliderWrapper = styled.div`
    display: flex;

`

export const AudioPlayerSlider = styled.input`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
    padding: 0;
    width: 100%;
    flex: 1 1 100%;
    height: 3px;
    background-color:  ${ props => props.theme.secondaryBg};
    border: 0;
    cursor: pointer;
    color: ${ props => props.theme.mainColor};
    position: relative;
    direction: ltr;

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      justify-self: center;
      content: '';
      height: 3px;
      overflow: hidden;
      width: ${props => props.progressWidth};
      z-index: 1;
      background-color: ${props => props.theme.mainColor};
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        height: 0.21rem; 
        
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        transition: 0.8s all ease;
        height: 0.21rem;
          
        } 
    }
    &:after{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 4px;
      width: ${props => props.bufferWidth};
      z-index: 2;
      background-color: ${props => hexToRgba(props.theme.mainBg, 0.5)};
   
    }


    //Chrome, Edge, Safari settings
    ::-webkit-slider-runnable-track{
        background-color:  ${ props => props.theme.secondaryBg};
        color: ${ props => props.theme.mainColor};
      width: 100%;
   border-radius: 0.5rem;
   height: 3px;
   overflow: hidden;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.2rem; 
    
  } 
    }

    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   background-color: ${(props) => hexToRgba(props.theme.accentColor,1)};
   z-index: 100;
   color: ${ props => props.theme.mainColor};

   height: 1rem;
   width: 1rem;
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.23rem; 
    background-color: ${(props) => hexToRgba(props.theme.accentColor,0)};
    color: ${ props => hexToRgba(props.theme.mainColor,0)};
    
  }
    }

    //Firefox settings
    ::-moz-range-track{
        background-color:  ${ props => props.theme.secondaryBg};
        color: ${ props => props.theme.mainColor};

   border-radius: 0.5rem;
   height: 3px; 
   @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    height: 0.2rem;  
    
  }
    }

    ::-moz-range-thumb{
        border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: ${(props) => hexToRgba(props.theme.accentColor,1)};    
    height: 1rem;
    width: 1rem;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
      transition: 0.8s all ease;
      height: 0.23rem;  
      color: ${ props => hexToRgba(props.theme.mainColor,0)};
        
      }
    }
    &:hover {
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
      color: ${(props) => props.theme.mainColor};
    }
    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
      transition: 0.8s all ease;

      height: 0.2rem;
        
      } 
`

export const AudioPlayerControlsWrapper = styled.div`
      display: flex;
      justify-content: center;
      flex: 1 1 100%;
      text-align: center;
      box-sizing: border-box;
      vertical-align: top;

`
export const AudioPlayerPlaybackControlsWrapper = styled.div`

align-items: center;
display: grid;
grid-template-columns: repeat(3,1fr);

`


export const AudioPlayerControls = styled.div`
display: flex;
width: 30px;
align-items: center;
height: 22px;
font-size: 1rem;
border-radius: 4px;
cursor: pointer;
color: ${props => props?.isActive ? props.theme.accentColor : props.theme.secondaryBg};
@media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    width: 20px;
    height: 12px;
    font-size: 1rem;
  }

  &:hover{
    transform: scale(1.07);
    transition: 0.8s ease-out;
}
`

export const VolumeControlsWrapper = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: ${ props => props.theme.secondaryBg};
    
      


`

export const AudioPlayerVolumeSlider = styled.input`
    padding: 0;
    width: 70px;
    height: 3px;
    display: inline-block;
    justify-self: center;
    align-self: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: visible;
    background-color: ${ props => props.theme.secondaryBg};
    border: 0;
    cursor: pointer;
    color: ${(props) => props.theme.secondaryColor};

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      justify-self: center;
      content: '';
      height: 3px;
      overflow: hidden;
      width: ${props => props.volume};
      z-index: 1;
      background-color: ${props => props.theme.mainColor};
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        transition: 0.8s all ease;
        height: 0.21rem; 
        
      }
      @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        transition: 0.8s all ease;
        height: 0.21rem;
          
        } 
    }

    ::-webkit-slider-thumb{
        -webkit-appearance: none; /* Override default look */
   appearance: none;
   border-radius: 50%;
   border: solid 1px ${(props) => props.theme.accentColor};
   background-color: ${(props) => props.theme.mainBg};
   height: 0.7rem;
   width: 0.7rem;
    }

    ::-moz-range-thumb{
        border: none; /*Removes extra border that FF applies*/
    border-radius: 50%; /*Removes default border-radius that FF applies*/
    border: solid 1px ${(props) => props.theme.accentColor};
    background-color: ${(props) => props.theme.mainBg};
    height: 0.7rem;
    width: 0.7rem;
    }



${VolumeControlsWrapper}: hover &{
      transition: all 0.3s ease-in-out;
      color: ${(props) => props.theme.mainColor};
    }
`


export const AudioText = styled.text`
  display: flex;
  margin: 1.2% 1%;
  font-size: 0.7rem;
  @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
    transition: 0.8s all ease;
    font-size: 0.4rem;
    justify-content: space-evenly;
  }
  @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    justify-content: space-evenly;
    transition: 0.8s all ease;
    font-size: 0.35rem;
  } 
`

export const ProgressBarWrapper = styled.div`
display: flex;
position: relative;
padding-top: 2%;
padding-left: 1%;
padding-right: 1%;
`
