import React, { useEffect, useState } from 'react'
import useArtist from '../../hooks/useArtist';
import useMusic from '../../hooks/useMusic';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../HeroSection';
import ContentWrapper from '../ContentWrapper';
import { hexToRgba } from '../HexToRGBA';
import ContentList from '../ContentList';
import { CONTENT_TYPES, SLIDER_TYPES } from '../../utils/Enum';
import { Fade } from 'react-reveal';
import { Button } from '../ButtonElement';
import { ServicesH1 } from '../Services/ServicesElements';
import Services from '../Services';
import InfoSection from '../InfoSection';
import { homeObj3 } from '../InfoSection/Data';
import { Theme } from '../WebsiteThemeElements';
import { Slider } from '../Slider';
import { getNewAlbumReleases, getNewPodcastEpisodes, getNewSongReleases } from '../../utils/dbUtils/musicUtils';
import { ContentListSkeleton } from '../Skeleton';
import { getObjectLength } from '../../utils';
import useVideos from '../../hooks/useVideos';
import ScrollToTop from '../ScrollToTop';
import { updateWebPageIcon } from '../../utils/MusicUtils';

const LandingPage = () => {
  const [featuredArtists, setArtists] = useState([])
  const [collections, setCollections] = useState([])
  const [songVideos, setSongVideos] = useState([])
  const [isLoaded, setLoaded] = useState(false)
  const [isMusicLoaded, setMusicLoaded] = useState(false)
  const [isVideosLoaded, setVideosLoaded] = useState(false)
  const [isTopReleasesLoaded, setTopReleasesLoaded] = useState(false)
  const [isCollectionLoaded, setCollectionLoaded] = useState(false)
  const [isEpisodesLoaded, setEpisodesLoaded] = useState(false)
  const [isRoyaltiesLoaded, setRoyaltiesLoaded] = useState(false);
  const [topReleases, setTopRelease] = useState({})
  const [latestReleases, setLatestRelease] = useState([])
  const [latestEpisodes, setLatestEpisodes] = useState([])
  const [musicRoyalties, setMusicRoyalties] = useState([])
  const {getTopArtists} = useArtist({})
  const {getLatestReleases, getNFTokenCollections, getLatestEpisodes, getTopReleases,getMusicRoyalties} = useMusic({})
  const {getLatestSongVideos} = useVideos()
  const { currentUser } = useAuth();
  const {goTo} = ScrollToTop()
  const navigate  = useNavigate();

  useEffect(() => {
    updateWebPageIcon('https://sonarmuse.org/favicon.ico')
    return () => {}
  },[])


  const updateSongReleases = (data) => {
    let songs = latestReleases.filter(r => r?.songId)

    setLatestRelease(prev => {
      return [...prev?.filter(r => r?.albumId), ...data?.songs]
      
    })
  }

  const updateAlbumReleases = (data) => {
    let albums = latestReleases?.filter(r => r?.albumId)
    setLatestRelease(prev => {
      return [...prev?.filter(r => r?.songId), ...data?.albums]
      
    })
  }

  const updateEpisodeReleases = (data) => {
    setLatestEpisodes(data?.episodes
    )
  }
  useEffect(() => {
    if (currentUser?.uid) {
      navigate( "/home");
      return () => {}
    }
    getTopReleases().then((data) => {
      if(data?.length > 0){
        setTopRelease(data)
    }
    setTopReleasesLoaded(true)
    }).catch((err) => {
      setTopReleasesLoaded(true)
    });
      getMusicRoyalties(0).then(data => {
        if (data?.length > 0) {
      
         
          setMusicRoyalties(data);
        }
        setRoyaltiesLoaded(true)
      }).catch(err => {
        setRoyaltiesLoaded(true)
      })
    getNFTokenCollections(0).then((nfTokenCollections) => {
        setCollections(nfTokenCollections); 
        setCollectionLoaded(true)
      }).catch(err => {
        setCollectionLoaded(true)
      })
    getLatestEpisodes().then((episodes) => {
        setLatestEpisodes(episodes); 
        setEpisodesLoaded(true)
        //getNewPodcastEpisodes(0, updateEpisodeReleases)
      }).catch(err => {
        setEpisodesLoaded(true)
      })
      getLatestSongVideos(0).then((data) => {
        if(data?.songs?.length > 0){
          setSongVideos(data.songs)
        }
        setVideosLoaded(true)
      }).catch(err => {
        setVideosLoaded(true)
      })
      getLatestReleases().then((releases) => {
      if(releases){
      setLatestRelease(releases); 
      setMusicLoaded(true)
      }else{
      return
    }
    /* getNewAlbumReleases(0, updateAlbumReleases)
    getNewSongReleases(0, updateSongReleases) */
    }).catch(err => {
    });

     ;
     return () => {}
  }, [!isLoaded]);
  return (
    <> <HeroSection />
       
    <div style={{background: hexToRgba(Theme({}).secondaryColor, 0.5)}}><Fade right><ContentWrapper>
      {!isTopReleasesLoaded && <><ContentWrapper heading={"Top Albums"}>
       <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON} />
     </ContentWrapper>
     
     <ContentWrapper heading={"Top Songs"}>
       <ContentListSkeleton/>
     </ContentWrapper></>}
     {(isTopReleasesLoaded && getObjectLength(topReleases) > 0) &&
      <ContentWrapper heading={"Top of The Charts"}>

      <ContentWrapper heading={"Top Albums"}>
         <Slider
          type={SLIDER_TYPES.ALBUM}
          id={"top albums"}
          slides={topReleases?.filter(m => m?.albumId)}
        />
      </ContentWrapper>

      <ContentWrapper id={"topSongs"} heading={"Top Songs"}>
         <ContentList
          content={topReleases?.filter(m => m?.songId)}
          id={"topSongs"}
          type={CONTENT_TYPES.SONG}
        />
      </ContentWrapper>
      </ContentWrapper>}
    </ContentWrapper></Fade></div>
    
    {/* <CountdownTimer {...Snapshot}/> */}
     <div style={{background: hexToRgba(Theme({}).mainBg, 0.5)}}>
        <Fade right>
        <ContentWrapper>
        <ServicesH1 light={true}>Browse</ServicesH1>
        {collections?.collections?.length > 0 && isCollectionLoaded && 
        <ContentWrapper id={"collections"} heading={"NFToken Collections"} subheading={'View All'} onClick={() => goTo('/collections')}>
        <Slider
          slides={collections?.collections}
         id={"latestCollections"}
         type={SLIDER_TYPES.COLLECTION}
       />
       </ContentWrapper>
       
       }
       {!isCollectionLoaded && 
        <ContentWrapper id={"collections"} heading={"NFToken Collections"}>
        <Slider
         id={"latestCollections"}
         type={SLIDER_TYPES.PURCHASE_SKELETON}
       />
       </ContentWrapper>}
       {isRoyaltiesLoaded && musicRoyalties?.royalties?.length > 0 && <ContentWrapper heading={"Music Regium Shares"}  subheading={'View All'} onClick={() => goTo('/regiums')}>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE}
                    id={"music royalties"}
                    slides={musicRoyalties?.royalties}
                  />
                </ContentWrapper>}
     {!isRoyaltiesLoaded && <ContentWrapper heading={"Music Regium Shares"}>
              
              <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
          
          </ContentWrapper> }
      {(isMusicLoaded && latestReleases?.length > 0) &&  
      <>
      <ContentWrapper id={"latestSongs"} heading={"Latest Songs"} subheading={'View All'} onClick={() => goTo('/latestSongs')}>
        <Slider
          slides={latestReleases?.filter(m => m?.songId)}
         id={"latestSongs"}
         type={SLIDER_TYPES.SONG}
       />
       </ContentWrapper>
       <ContentWrapper heading={"Latest Albums"} subheading={'View All'} onClick={() => goTo(`latestAlbums`)}>
        <Slider
         type={SLIDER_TYPES.ALBUM}
         id={"latest albums"}
         slides={latestReleases?.filter(m => m?.albumId)}
       />
     </ContentWrapper></>}
     {
        !isMusicLoaded && 
        <><ContentWrapper id={"latestSongs"} heading={"Songs"}>
        <Slider
          
         id={"latestSongs"}
         type={SLIDER_TYPES.MUSIC_SKELETON}
       />
       </ContentWrapper>
       <ContentWrapper heading={"Albums"}>
        <Slider
         type={SLIDER_TYPES.MUSIC_SKELETON}
         id={"latest albums"}
         
       />
     </ContentWrapper></>
     }   
     {isVideosLoaded && songVideos?.length > 0 &&
     <ContentWrapper heading={"Music Videos"}>
     <Slider
        type={SLIDER_TYPES.VIDEO}
        id={"music videos"}
        slides={songVideos}
      />
    </ContentWrapper>
     }

     {!isVideosLoaded && <><ContentWrapper heading={"Music Videos"}>
       <Slider  
       type={SLIDER_TYPES.MUSIC_SKELETON} />
     </ContentWrapper></>}
       {latestEpisodes?.episodes?.length > 0 && isEpisodesLoaded &&
        <ContentWrapper id={"latestEpisodes"} subheading={'View All'} heading={"Podcast Episodes"} onClick={() => goTo('l')}>
        <Slider
          slides={latestEpisodes?.episodes}
         id={"latestEpisodes"}
         type={SLIDER_TYPES.EPISODE}
       />
       </ContentWrapper>}
       {!isEpisodesLoaded && 
       <ContentWrapper id={"latestEpisodes"} heading={"Podcast Episodes"}>
       <Slider
        id={"latestEpisodes"}
        type={SLIDER_TYPES.MUSIC_SKELETON}
      />
      </ContentWrapper>}
     
     </ContentWrapper></Fade></div>
  
    
     <Services />
     <InfoSection {...homeObj3} /></>
  )
}

export default LandingPage