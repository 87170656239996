import React, {useState } from "react";
import defaultImg from "../../../images/sm_album_art_placeholder.png";
import {
  AlbumArt,
  AlbumArtContainer,
  CloseButton,
  HeaderText,
  HeaderTextContainer,
  Img,
  Modal,
  PlaylistContainer,
  PlaylistContainerWrapper,
  PlaylistFooter,
  PlaylistHeader,
  PlaylistHeaderContainer,
  PlaylistNav,
  PlaylistNavContainer,
  PlaylistNavLink,
  PlaylistVideoContainer,
  PlaylistVideoContainerWrapper,
  PlaylistWrapper,
  Text,
} from "./AudioPlayerPlaylistElements";
import { AudioContext, useAudio } from "../context/AudioContext";
import { HeadingTextWrapper } from "../../Profile/ProfileDetails/ProfileDetailsElements";
import { convertTimeToSeconds, getArtistsNames, getCCInfo, getPodcastersNames, getSongTitle, stringFormatter } from "../../../utils";
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES, PODCASTER_SEARCH_TYPES } from "../../../utils/Enum";
import { useAuth } from "../../../contexts/AuthContext";
import { Zoom } from "react-reveal";
import ContentWrapper from "../../ContentWrapper";
import { isUserPayingOut } from "../../SonarMuseWebMonetization";
import RecordPlayer from "../../RecordPlayer";
import MusicInfo from "../../MusicPage/MusicInfo";
import { NavContainer } from "../../NavLink/NavLinkElements";
import { MusicLyricsWrapper } from "../../MusicPage/MusicPageElements";
import { FormText, FormWrapper as FW } from "../../CustomForm/CustomFormElements";
import { CustomButton } from "../../ButtonElement";
import { FormWrapper } from "../../MusicUploadsForm/MusicUploadsFormElements";
import { useUser } from "../../../hooks/useUser";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import StreamingSubscriptionModal from "../../StreamingSubscriptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { VideoPlayerSkeleton } from "../../Skeleton";
import VideoPlayer from "../../VideoPlayer";
import AudioVisualizer from "../../AudioVisualizer";
import { AudioPlayerQueueList } from "../../ContentList/SongList";
//import VideoPlayer from "../../VideoPlayer";

function AudioPlayerPlaylist({ onClose, isOpen, currentNav }) {
  const { currentUser, gravatarInfo, isUserSubscribed, getUserSubscription } = useAuth();
  const {openModal, modalContent, closeModal} = useModal()
  const {getUserQueue} = useUser({})
  const {
    currentSong,
    handleToggle,
    playlist,
    nfts,
    stopMainPlayer,
    setCurrentSong,
    isPlaying,
    timeTravel,
    togglePlayPause,
  } = useAudio();
  const [currentTab, setTab] = useState(currentNav ? currentNav : "playlist");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );

  
  const validatePlay = async() => {
    try{
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }else{
   if(!currentUser?.subscription){
     await getUserSubscription()
   }
   
    let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
     handleToggle("play-pause", true)
   } if(isPlay === "Network Error"){
    return handleToggle("play-pause", false);
   }else{
     
     openModal() 
         modalContent(<><StreamingSubscriptionModal onSuccess={() => {
          getUserSubscription(currentUser?.uid);
           handleToggle("play-pause", !isPlaying)
           openModal()
           modalContent(<OptionModal heading={"Subscription Enabled Sucessfully"}>
             <HoverOption onClick={ 
         closeModal()}>OK</HoverOption>
         </OptionModal>)
            }} onFail={() => {
           modalContent(<OptionModal heading={"Subscription Unsucessful"}>
             <HoverOption onClick={() => {closeModal()
             modalContent()}}>OK</HoverOption>
         </OptionModal>)}} cancel={() => {closeModal()
         modalContent()}} /></>)
   }
 }}catch(err){
   //console.log(err)
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }
 }  
 }


  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <MusicLyricsWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</MusicLyricsWrapper>
        );
      case "timestamps": 
      return (
        <>
        <ContentWrapper>
          <FW>
            <HeaderText>Timestamps</HeaderText>
          {playlist[currentSong]?.timestamps.map(timestamp => {
            return (
              <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                timeTravel(convertTimeToSeconds(timestamp?.time))
                }}>[{timestamp?.time}]</CustomButton> 
              - {timestamp?.label}</FormWrapper>
            )
          })}</FW>
        </ContentWrapper>
        </>
      )
      case "details":
        return (
          <ContentWrapper>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            />

            {playlist?.length > 0 ? (
              <MusicInfo
                type={playlist[currentSong]?.songId ? CONTENT_TYPES.SONG : CONTENT_TYPES.EPISODE}
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
        <>
          <VideoPlayer stopMainPlayer={stopMainPlayer} isPlaying={isPlaying} content={playlist[currentSong]} validateSubscription={true}/>
        </>)
      case "visuals":
        return <PlaylistWrapper>
          {{/* <VideoPlayer/> */}}
        </PlaylistWrapper>;
      default:
        return (
          <>
            <ContentWrapper>
              <RecordPlayer
                onClick={validatePlay}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                }
                alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
              />
            </ContentWrapper>
            <PlaylistWrapper>
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper>
          </>
        );
    }
  };
  return (
    <>
      <PlaylistContainer isOpen={isOpen} src={playlist[currentSong]?.albumArt}>
        <PlaylistContainerWrapper>
          <PlaylistHeaderContainer>
            <PlaylistHeader background={gravatarInfo?.profileBackground?.color}>
              <HeaderTextContainer>
                {playlist.length > 0 && (
                  <>
                    {isPlaying && (
                      <small style={{ fontStyle: "italics" }}>
                        Now Playing{" "}
                      </small>
                    )}
                    <HeaderText>
                      {playlist[currentSong]?.songId
                        ? getSongTitle(playlist[currentSong])
                        : playlist[currentSong]?.episodeId ? playlist[currentSong].episodeName :  ""}
                    </HeaderText>
                    <Text>
                      {playlist[currentSong]?.songId
                        ? getArtistsNames(
                            playlist[currentSong].artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )
                        : playlist[currentSong]?.episodeId ? getPodcastersNames(
                          playlist[currentSong].podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        ): ""}
                    </Text>
                  </>
                )}
              </HeaderTextContainer>
              <AlbumArtContainer>
                <AlbumArt
                  onClick={() => setTab("details")}
                  src={
                    playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                  }
                />
              </AlbumArtContainer>
              <CloseButton onClick={onClose} />
            </PlaylistHeader>
          </PlaylistHeaderContainer>
          <PlaylistNavContainer >
            <PlaylistNav>
              <PlaylistNavLink
                active={currentTab === "playlist"}
                onClick={() => setTab("playlist")}
              >
                Queue
              </PlaylistNavLink>
              {playlist[currentSong]?.lyrics && (
                <PlaylistNavLink
                  active={currentTab === "lyrics"}
                  onClick={() => setTab("lyrics")}
                >
                  {`${playlist[currentSong]?.songName} Lyrics`}
                </PlaylistNavLink>
              )}
              { playlist[currentSong]?.video && (
                <PlaylistNavLink
                  active={currentTab === "video"}
                  onClick={() => setTab("video")}
                >
                  Video
                </PlaylistNavLink>
              )}
              {playlist[currentSong]?.timestamps?.length > 0 && (
                <PlaylistNavLink
                  active={currentTab === "timestamps"}
                  onClick={() => setTab("timestamps")}
                >
                  {`${playlist[currentSong]?.episodeName } timestamps`}
                </PlaylistNavLink>
              )}
              <PlaylistNavLink
                active={currentTab === "details"}
                onClick={() => setTab("details")}
              >
                {playlist[currentSong]?.episodeName ? 'Episode' : playlist[currentSong]?.songName} Details
              </PlaylistNavLink>
            </PlaylistNav>
          </PlaylistNavContainer >
          <Zoom>{displayContent()}</Zoom>
        </PlaylistContainerWrapper>
      </PlaylistContainer>
      <PlaylistFooter />
    </>
  );
}

export function AudioPlayerVisualizer({onClose, isOpen, currentNav}){
  const { currentUser, gravatarInfo, isUserSubscribed, getUserSubscription } = useAuth();
  const {openModal, modalContent, closeModal} = useModal()
  const {getUserQueue} = useUser({})
  const {
    currentSong,
    handleToggle,
    playlist,
    nfts,
    stopMainPlayer,
    setCurrentSong,
    isPlaying,
    timeTravel,
    togglePlayPause,
  } = useAudio();
  const [currentTab, setTab] = useState(currentNav ? currentNav : "playlist");
  const [isPlaylistLoaded, setPlaylistLoaded] = useState(false)
  const [playlistTab, setPlaylistTab] = useState(
    isUserPayingOut() ? "playlist" : "nfts"
  );

  
  const validatePlay = async() => {
    try{
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }else{
   if(!currentUser?.subscription){
     await getUserSubscription()
   }
   
    let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
     handleToggle("play-pause", !isPlaying)
   }else{
     
     openModal() 
         modalContent(<><StreamingSubscriptionModal onSuccess={() => {
          getUserSubscription(currentUser?.uid);
           handleToggle("play-pause", !isPlaying)
           openModal()
           modalContent(<OptionModal heading={"Subscription Enabled Sucessfully"}>
             <HoverOption onClick={ 
         closeModal()}>OK</HoverOption>
         </OptionModal>)
            }} onFail={() => {
           modalContent(<OptionModal heading={"Subscription Unsucessful"}>
             <HoverOption onClick={() => {closeModal()
             modalContent()}}>OK</HoverOption>
         </OptionModal>)}} cancel={() => {closeModal()
         modalContent()}} /></>)
   }
 }}catch(err){
   //console.log(err)
   if(isPlaying){
     handleToggle("play-pause", !isPlaying)
     return
   }
 }  
 }


  const displayContent = () => {
    switch (currentTab) {
      case "lyrics":
        return (
          <MusicLyricsWrapper>{stringFormatter(playlist[currentSong]?.lyrics)}</MusicLyricsWrapper>
        );
      case "timestamps": 
      return (
        <>
        <ContentWrapper>
          <FW>
            <HeaderText>Timestamps</HeaderText>
          {playlist[currentSong]?.timestamps.map(timestamp => {
            return (
              <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                timeTravel(convertTimeToSeconds(timestamp?.time))
                }}>[{timestamp?.time}]</CustomButton> 
              - {timestamp?.label}</FormWrapper>
            )
          })}</FW>
        </ContentWrapper>
        </>
      )
      case "details":
        return (
          <ContentWrapper>
            <RecordPlayer
              onClick={validatePlay}
              stop={!isPlaying}
              albumArt={
                playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
              }
              alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
            />

            {playlist?.length > 0 ? (
              <MusicInfo
                type={playlist[currentSong]?.songId ? CONTENT_TYPES.SONG : CONTENT_TYPES.EPISODE}
                content={playlist[currentSong]}
              />
            ) : (
              "No Songs Available"
            )}
          </ContentWrapper>
        );
      case "video":
        return (
        <>
          <VideoPlayer stopMainPlayer={stopMainPlayer} isPlaying={isPlaying} content={playlist[currentSong]} validateSubscription={true}/>
        </>)
      case "visuals":
        return <PlaylistWrapper>
          {{/* <VideoPlayer/> */}}
        </PlaylistWrapper>;
      default:
        return (
          <>
            <ContentWrapper>
              <RecordPlayer
                onClick={validatePlay}
                stop={!isPlaying}
                albumArt={
                  playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                }
                alt={playlist[currentSong]?.songName ? playlist[currentSong]?.songName : playlist[currentSong]?.episodeName}
              />
            </ContentWrapper>
            <PlaylistWrapper style={{height: "fit-content", marginBottom: "2%"}}>
              <AudioPlayerQueueList
                content={playlist}
                onButtonClick={setCurrentSong}
                type={CONTENT_TYPES.SONG}
                displayFullList={true}
              />
            </PlaylistWrapper>
          </>
        );
    }
  };
  return (
    <>
      <PlaylistVideoContainer isOpen={isOpen}>
      <PlaylistVideoContainerWrapper>
        <AudioVisualizer visual={playlist[currentSong]?.visualizer?.url}>
        <PlaylistHeaderContainer>
            <PlaylistHeader background={gravatarInfo?.profileBackground?.color}>
              <HeaderTextContainer>
                {playlist.length > 0 && (
                  <>
                    {isPlaying && (
                      <small style={{ fontStyle: "italics" }}>
                        Now Playing{" "}
                      </small>
                    )}
                    <HeaderText>
                      {playlist[currentSong]?.songId
                        ? getSongTitle(playlist[currentSong])
                        : playlist[currentSong]?.episodeId ? playlist[currentSong].episodeName :  ""}
                    </HeaderText>
                    <Text>
                      {playlist[currentSong]?.songId
                        ? getArtistsNames(
                            playlist[currentSong].artists,
                            ARTIST_SEARCH_TYPES.NAME
                          )
                        : playlist[currentSong]?.episodeId ? getPodcastersNames(
                          playlist[currentSong].podcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        ): ""}
                    </Text>
                  </>
                )}
              </HeaderTextContainer>
              <AlbumArtContainer>
                <AlbumArt
                  onClick={() => setTab("details")}
                  src={
                    playlist[currentSong]?.albumArt
                    ? playlist[currentSong]?.albumArt
                    : playlist[currentSong]?.thumbnail ? playlist[currentSong]?.thumbnail :defaultImg
                  }
                />
              </AlbumArtContainer>
              <CloseButton onClick={onClose} />
            </PlaylistHeader>
          </PlaylistHeaderContainer>
          <PlaylistNavContainer >
            <PlaylistNav>
              <PlaylistNavLink
                active={currentTab === "playlist"}
                onClick={() => setTab("playlist")}
              >
                Queue
              </PlaylistNavLink>
              {playlist[currentSong]?.lyrics && (
                <PlaylistNavLink
                  active={currentTab === "lyrics"}
                  onClick={() => setTab("lyrics")}
                >
                  {`${playlist[currentSong]?.songName} Lyrics`}
                </PlaylistNavLink>
              )}
              { playlist[currentSong]?.video && (
                <PlaylistNavLink
                  active={currentTab === "video"}
                  onClick={() => setTab("video")}
                >
                  {`Video`}
                </PlaylistNavLink>
              )}
              {playlist[currentSong]?.timestamps?.length > 0 && (
                <PlaylistNavLink
                  active={currentTab === "timestamps"}
                  onClick={() => setTab("timestamps")}
                >
                  {`${playlist[currentSong]?.episodeName } timestamps`}
                </PlaylistNavLink>
              )}
              <PlaylistNavLink
                active={currentTab === "details"}
                onClick={() => setTab("details")}
              >
                {playlist[currentSong]?.episodeName ? 'Episode' : playlist[currentSong]?.songName} Details
              </PlaylistNavLink>
            </PlaylistNav>
          </PlaylistNavContainer >
          <Zoom>{displayContent()}</Zoom>
          
        </AudioVisualizer>
        <PlaylistFooter/>
       </PlaylistVideoContainerWrapper>
      </PlaylistVideoContainer>
    </>
  );

}

export default AudioPlayerPlaylist;
