import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAlbumCredits, getAllArtists, getArtistsNames, getArtistsNames2, getCCInfo, getPodcastersNames, getSongTitle, getUniqueListBy, stringFormatter, timestampToDate } from "../../../utils";
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES } from "../../../utils/Enum";
import { timestamp } from "../../Authorization/firebase";
import {
  CreditsHeading,
  CreditsText,
  DetailsWrapper,
  Img,
  MusicInfoContainer,
  MusicInfoContentWrapper,
  MusicInfoWrapper,
  SubHeading,
  Text,
} from "./MusicInfoElements";
import NFTokenInfo from "./NFTokenInfo";
import { useAuth } from "../../../contexts/AuthContext";
import useArtist from "../../../hooks/useArtist";
import { getAddressName } from "../../../utils/nfTokensUploads";
import { FormWrapper } from "../../CustomForm/CustomFormElements";

const MusicInfo = ({ content, type, background }) => {
  const navigate  = useNavigate();
  const {currentUser} = useAuth()
  const { getArtistName } = useArtist({});
  const [uploaderName, setUploaderName] = useState()

  const displayArtistName = () => {
    if(type === CONTENT_TYPES.ALBUM || type === CONTENT_TYPES.SONG || type === CONTENT_TYPES.EPISODE  ){
      return getArtistName(content?.uploadedBy).then(async(aName) => {
        if(aName){
       setUploaderName(aName)
       return }
       else{
       let issuerName = await getAddressName(content?.uploadedBy)
        setUploaderName(issuerName)
       return
      }
      }).catch(err => {
        setUploaderName(content?.uploadedBy)
        
       console.error(err)
       return
      })}
  };

  useEffect(() => {
    displayArtistName();
    return () => {}
  }, [content]);

  const displayContent = () => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        return (
          <MusicInfoWrapper>
            {content?.about?.trim()?.length > 0 &&<><FormWrapper><MusicInfoContainer>
               <SubHeading>About</SubHeading>
              <CreditsText style={{ marginBottom: "1%" }}>
                {stringFormatter(content.about)}
              </CreditsText>
             </MusicInfoContainer></FormWrapper> </>}
            <MusicInfoContentWrapper>
            
              <FormWrapper><MusicInfoContainer><SubHeading>Release Date</SubHeading>
              <CreditsText onClick={() => {}}>{`${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getDate()}/${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getMonth() + 1}/${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getFullYear()}`}</CreditsText>
            </MusicInfoContainer></FormWrapper>
            <FormWrapper><MusicInfoContainer>
              <SubHeading>Genre</SubHeading>
              <CreditsText onClick={() => {}}>{content?.genre?.name}</CreditsText></MusicInfoContainer></FormWrapper>
              {content?.subGenres && Object.keys(content)?.length > 0 && (<FormWrapper><MusicInfoContainer>
                <>
                  <SubHeading>SubGenre(s)</SubHeading>
                  {content.subGenres.map((subgenre, index) => {
                    return (
                      <CreditsText onClick={() => {}}>
                        {subgenre.name}{" "}
                        {subgenre?.mainGenre?.name &&
                          ` (${subgenre?.mainGenre?.name})`}
                      </CreditsText>
                    );
                  })}
                </>
              </MusicInfoContainer></FormWrapper>)}
              <FormWrapper><MusicInfoContainer>
              <CreditsHeading>Record Label</CreditsHeading>
              <CreditsText onClick={() => {}}>
                {content?.recordLabel ? content?.recordLabel : "Independent"}
              </CreditsText>
            </MusicInfoContainer></FormWrapper>
            {content?.licensing && (
              <FormWrapper><MusicInfoContainer>
                <CreditsHeading>License</CreditsHeading>
                <CreditsText onClick={() => {}}>{getCCInfo(content?.licensing)}</CreditsText>
              </MusicInfoContainer></FormWrapper>
            )}
            </MusicInfoContentWrapper>
            <FormWrapper><MusicInfoContainer>
              <SubHeading>Song Credits</SubHeading>
                  <FormWrapper>
              <CreditsHeading>Performed by</CreditsHeading>
              <CreditsText onClick={() => {}}>
                {getArtistsNames(
                  getAllArtists(content),
                  ARTIST_SEARCH_TYPES.NAME
                )}
              </CreditsText></FormWrapper>

              {content?.songCredits &&
                Object.keys(content?.songCredits).length > 0 && (
                  <>
                    {content?.songCredits?.composers && (
                      <FormWrapper><>
                        <CreditsHeading>Composed by</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                          {getArtistsNames(content?.songCredits?.composers
                            ,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                      </></FormWrapper>
                    )}
                    {content?.songCredits?.writers && (
                      <FormWrapper>
                        <CreditsHeading>Written by</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                          {getArtistsNames(
                            content?.songCredits?.writers,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                      </FormWrapper>
                    )}
                    {content?.songCredits?.producers && (
                      <FormWrapper>
                        <CreditsHeading>Produced by</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                          {getArtistsNames(
                            content?.songCredits?.producers,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                      </FormWrapper>
                    )}
                  </>
                )}
            </MusicInfoContainer></FormWrapper>
          </MusicInfoWrapper>
        );

      case CONTENT_TYPES.ALBUM:
        return (
          <MusicInfoWrapper>
             {content?.about?.trim()?.length > 0 &&<FormWrapper> <SubHeading>About</SubHeading>
              <CreditsText style={{ marginBottom: "1%" }}>
                {content.about}
              </CreditsText>
              </FormWrapper>}
            <MusicInfoContentWrapper>
              <FormWrapper><SubHeading>Release Date</SubHeading>
              <CreditsText onClick={() => {}}>{`${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getDate()}/${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getMonth() + 1}/${new Date(
                content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
              ).getFullYear()}`}</CreditsText></FormWrapper>
              <FormWrapper>
              <CreditsHeading>Record Label</CreditsHeading>
              <CreditsText onClick={() => {}}>
                {content?.recordLabel ? content?.recordLabel : "Independent"}
              </CreditsText>
            </FormWrapper>
            {content?.licensing && (
              <FormWrapper>
                <CreditsHeading>License</CreditsHeading>
                <CreditsText onClick={() => {}}>{getCCInfo(content?.licensing)}</CreditsText>
              </FormWrapper>
            )}
            </MusicInfoContentWrapper>
            <FormWrapper><MusicInfoContainer>
              <SubHeading>Album Credits</SubHeading>

              <FormWrapper><CreditsHeading>Performed by</CreditsHeading>
              <CreditsText onClick={() => {}}>
                {getArtistsNames(
                  getAllArtists(content),
                  ARTIST_SEARCH_TYPES.NAME
                )}
              </CreditsText></FormWrapper>

                
                
                    {
                      <FormWrapper>
                        <CreditsHeading>Composers</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                          {                          
                          getArtistsNames(getAlbumCredits(content?.songs, "composers"),
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                      </FormWrapper>
                    }
                    {
                      <FormWrapper>
                        <CreditsHeading>Writers</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                        {                          
                          getArtistsNames(getAlbumCredits(content?.songs, "writers"),
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                        </FormWrapper>
                      }
                    {
                      <FormWrapper>
                        <CreditsHeading>Producers</CreditsHeading>
                        <CreditsText onClick={() => {}}>
                        {                          
                          getArtistsNames(getAlbumCredits(content?.songs, "producers"),
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                        </CreditsText>
                      </FormWrapper>
                    }
            </MusicInfoContainer></FormWrapper>
            
            
          </MusicInfoWrapper>
        );
      case CONTENT_TYPES.NFTOKEN:
        return <NFTokenInfo content={content} />;
      
    case CONTENT_TYPES.EPISODE:
      return(<MusicInfoWrapper>
      <MusicInfoContentWrapper>
        {content?.about?.trim()?.length > 0 &&<FormWrapper> <SubHeading>About</SubHeading>
        <CreditsText style={{ marginBottom: "1%" }}>
          {stringFormatter(content.about)}
        </CreditsText>
        </FormWrapper>}
        <FormWrapper><SubHeading>Release Date</SubHeading>
        <CreditsText >{`${new Date(
          content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
        ).getDate()}/${new Date(
          content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
        ).getMonth() + 1}/${new Date(
          content?.releaseDate?._seconds ? timestampToDate(content?.releaseDate) : content.releaseDate
        ).getFullYear()}`}</CreditsText></FormWrapper>
      
      <FormWrapper>
      <CreditsHeading>Presented by</CreditsHeading>
          <CreditsText >
            {getPodcastersNames(content?.podcasters,
              ARTIST_SEARCH_TYPES.NAME
            )}
          </CreditsText>
          </FormWrapper>
      <FormWrapper>
        {content?.genres && Object.keys(content?.genres)?.length > 0 && (
          <>
            <SubHeading>Genre(s)</SubHeading>
            {content.genres.map((genre, index) => {
              return (
                <CreditsText >
                  {genre.name}{" "}
                </CreditsText>
              );
            })}
          </>
        )}
      </FormWrapper>  
      {content?.licensing && (
        <FormWrapper>
          <CreditsHeading>License</CreditsHeading>
          <CreditsText >{getCCInfo(content?.licensing)}</CreditsText>
        </FormWrapper>
      )}</MusicInfoContentWrapper>
      {content?.songs?.length > 0 && <FormWrapper><MusicInfoContainer>
        <SubHeading>Songs</SubHeading>
        <MusicInfoContentWrapper columns={2}>
       {content?.songs?.map(song => {
        return(
          <FormWrapper><CreditsText style={{cursor: 'pointer'}} onClick={() => {
            navigate(`/song/${song?.songId}`) 
          }}>
          {getSongTitle(song)} - {getArtistsNames2(song?.artists, ARTIST_SEARCH_TYPES.NAME)}
        </CreditsText></FormWrapper>
        )
       })}</MusicInfoContentWrapper>
      </MusicInfoContainer></FormWrapper>}
    
    </MusicInfoWrapper>
  );

case CONTENT_TYPES.ROYALTY_SHARE: 
return (
  <MusicInfoWrapper>
    <SubHeading>Previous Epoch Payouts</SubHeading>
  {content?.prevPayouts?.length > 0 && <FormWrapper>{content?.prevPayouts?.map((payout, index) => {
    
 return( <MusicInfoContainer key={index} style={{flexDirection: "row"}}>
    <CreditsHeading style={{width: "100%", justifyContent: "center", fontWeight: "bold"}}>{new Date(Object.keys(payout)[0]).getDate()}/{new Date(Object.keys(payout)[0]).getMonth()  + 1}/{new Date(Object.keys(payout)[0]).getFullYear()}:</CreditsHeading>
          <CreditsText style={{width: "100%", justifyContent: "center"}}>
            {`${payout[Object.keys(payout)[0]]/1000000} XRP`}
          </CreditsText>
  </MusicInfoContainer>)})}</FormWrapper>}
  {(!content?.prevPayouts || content?.prevPayouts?.length === 0) && <MusicInfoContainer>
    <CreditsHeading>No Payouts have been made yet.</CreditsHeading>
    </MusicInfoContainer>}
  </MusicInfoWrapper>
)
  default:
    break;
}
};

  return <>{displayContent()}</>;
};

export default MusicInfo;
