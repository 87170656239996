import React, { useCallback, useState } from "react";
import {
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  Select,
  SubHeading,
} from "./MusicPageElements";
import ContentWrapper from "../ContentWrapper";
import { Slider } from "../Slider";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  SLIDER_TYPES,
} from "../../utils/Enum";
import MusicInfo from "./MusicInfo";
import CommentsSection from "../CommentsSection";
import ContentList from "../ContentList";
import { useAuth } from "../../contexts/AuthContext";
import ScrollToTop from "../ScrollToTop";
import { useModal } from "../Modal/ModalContext";
import { useNavigate } from "react-router-dom";
import { FaPause, FaPlay } from "react-icons/fa";
import ImageUtils from "../../utils/ImageUtils";
import MusicEditPage from "../MusicEditPage";
import { NavContainer } from "../NavLink/NavLinkElements";
import { useEffect } from "react";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useAlert } from "../Alert/AlertContext";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import { CustomButton } from "../ButtonElement";
import { convertDurationToString, convertTimeToSeconds, getUniqueListBy, timestampToDate } from "../../utils";
import { FormWrapper as FW } from "../CustomForm/CustomFormElements";
import { HeaderText } from "../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements";
import { FormWrapper } from "../MusicUploadsForm/MusicUploadsFormElements";
import {MusicOptionsModal } from "../MenuOptions";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import OptionModal from "../OptionModal";
import { MdExplicit } from "react-icons/md";
import { RiVideoFill } from "react-icons/ri";
import { getResizedImage } from "../../utils/FileUploadsUtils";

const EpisodePage = ({
  id,
  episode,
  isSongPlaying,
  handleToggle,
  audioPlayer,
  updateWebPageIcon,
  hasPurchased,
  currentSong,
  playlist,
  deleteEpisode,
  query,
  location,
}) => {
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const { goTo } = ScrollToTop();
  const { setCurrentSong, setPlaylist,timeTravel, playSong,isQueueLoaded  } = useAudio();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
  const {addAlert} = useAlert()
  const [ currentTab,setTab] = useState('episode');
  const { defaultAlbumArt } = ImageUtils();
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(episode?.thumbnail)
  const [isPlaying, setPlaying] = useState(false)
  const navigate  = useNavigate();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const isPlayingFrom = useCallback(() => {
    if (
      localStorage?.getItem("isPlayingFrom") && 
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.EPISODE &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id === episode?.episodeId
    ) {
      return true;
    }
    return false;

  }, [episode])


  useEffect(() => {
    updateWebPageIcon(episode?.thumbnail)
    return () => {}
  },[])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(episode?.thumbnail).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(episode?.thumbnail)
      })
      setAlbumArtLoaded(true)
    }
  }, [])


  useEffect(() => {
    if(isSongPlaying && isPlayingFrom()){
      setPlaying(true)
    }else{
      setPlaying(false)
    }
  
   },[isSongPlaying, isPlayingFrom])

  const handleSelect = async (e) => {
    switch (e.target.value) {
      case "delete":
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`,{
          replace:true},
        );

        break;
      default:
        break;
    }
  };


  const togglePlay = async(pl) => {
    if (currentUser?.uid) {
      let playingFrom = {
          playingFrom: MUSIC_PLAYING_FROM.EPISODE,
          id: episode?.episodeId,
        };        

        setPlaylist([{...pl, 
              playingFrom :playingFrom
            }]);
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
    if(isQueueLoaded){
          // if(isPlaying && subscribed to coil)
          if (
            isSongPlaying &&
            isPlayingFrom()
          ){
            
            handleToggle("play-pause", !isSongPlaying)
            setPlaying(true)
            localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
            return
          }
          else {
            handleToggle("play");
            setCurrentSong(0)
            setPlaying(false)
            localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          }}
        
    } if(isPlay === "Network Error"){
      addAlert({
        title: "Network Error",
        type: ALERT_TYPES.DANGER,
        message:
          'Cannot play music. Please check your connection',
      })
      if (
        isSongPlaying
      ) {
        setPlaying(false)
        handleToggle("play-pause", !isSongPlaying)
      }
      return
     }
    
    else{
      addAlert({
        title: "Not Subscribed",
        type: ALERT_TYPES.WARNING,
        message: "You need to pay subscription fee to play this episode",
      });
          if (
            isSongPlaying
          ) {
            setPlaying(false)
            handleToggle("play-pause", !isSongPlaying)
          }
          openModal() 
            modalContent(<><StreamingSubscriptionModal onSuccess={() => {
              getUserSubscription(currentUser?.uid);
              handleToggle("play-pause", !isSongPlaying)
              setPlaying(true)
              modalContent(<OptionModal heading={"Subscription Unsucessful"}>
                <HoverOption onClick={ 
            closeModal()}>OK</HoverOption>
            </OptionModal>)
               }} onFail={() => {
              modalContent(<OptionModal heading={"Subscription Unsucessful"}>
                <HoverOption onClick={() => {closeModal()
                modalContent()}}>OK</HoverOption>
            </OptionModal>)}} cancel={() => {closeModal()
            modalContent()}} /></>)
        }
  } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this episode",
      });
    }
  };



  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
         onContextMenu={(e) => {
          e.preventDefault();
        }}
          color={episode?.color}
          src={isAlbumArtLoaded && episode?.thumbnail ? albumArt : defaultAlbumArt}
          alt={episode?.episodeName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              color={episode?.color}
              src={isAlbumArtLoaded && episode?.thumbnail ? albumArt : defaultAlbumArt}
              alt={episode?.episodeName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading><small>Podcast Episode</small></SubHeading>
            <MusicHeading>{episode?.explicit && <MdExplicit/>}{episode?.video?.url && <RiVideoFill />}{episode?.episodeName}</MusicHeading>
            <MusicSubheading>
              <MusicHeading
                style={{ cursor: "pointer" }}
              
              >
                {episode?.podcasters?.map((podcaster, index) => {
                  if (index < episode?.podcasters?.length - 1) {
                    return <div style={{marginRight: '0.5rem'}} onClick={() =>
                      goTo(`/podcaster/${podcaster?.podcasterId}`)
                    }>{podcaster?.podcasterName + " ○ "}</div>;
                  } else {
                    return <div onClick={() =>
                      goTo(`/podcaster/${podcaster?.podcasterId}`)
                    }>{podcaster?.podcasterName}</div>
                  }
                })}
              </MusicHeading>
            </MusicSubheading>
            <MusicHeadingSmall>{convertDurationToString(episode?.duration)}○{new Date(timestampToDate(episode?.releaseDate)).getFullYear()}</MusicHeadingSmall>
            <MusicOptionsContainer>
             
                <>
                  <MusicPlayPauseButton onClick={ () => {
                    togglePlay(episode)
                    }
                  }>
                    {isPlaying ?
                      (
                      <>
                        <FaPause style={{ position: "relative", left: "1%" }} />{" "}
                        Pause
                      </>
                    ) : (
                      <>
                        <FaPlay style={{ position: "relative", left: "1%" }} />{" "}
                        Play
                      </>
                    )}
                  </MusicPlayPauseButton>
                </>
             
              {currentUser?.uid && (
                <OptionButton
                onClick={() =>
                  {openModal()
                  modalContent(<MusicOptionsModal content={episode} type={ CONTENT_TYPES.EPISODE}/>)
                }
              }
                >
                  <OptionMenuButton />
                </OptionButton>
              )}
            </MusicOptionsContainer>
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>
     
        <>
          <NavContainer>
            <MusicNav>
              <MusicNavLink
                onClick={() => setTab("episode")}
                active={currentTab === "episode"}
              >
                Episode
              </MusicNavLink>
              <MusicNavLink
                onClick={() => setTab("about")}
                active={currentTab === "about"}
              >
                About {episode?.episodeName}
              </MusicNavLink>
              {episode?.isDownloadable && (
                <MusicNavLink
                  onClick={() => setTab("purchase")}
                  active={currentTab === "purchase"}
                >
                  Purchase {episode?.episodeName}
                </MusicNavLink>
              )}
            </MusicNav>
          </NavContainer>
          <MusicDetailsContainer>
            {currentTab === "episode" && (
              <>{episode?.timestamps?.length > 0 && <ContentWrapper>
              <FW>
                <HeaderText>Timestamps</HeaderText>
              {episode?.timestamps.map(timestamp => {
                return (
                  <FormWrapper style={{alignContent: "left", justifyContent:"left"}}><CustomButton onClick={() => {
                   
                      setPlaylist([episode])
                      setCurrentSong(0)
                      handleToggle('Play')
                    timeTravel(convertTimeToSeconds(timestamp?.time))
                    }}>[{timestamp?.time}]</CustomButton> 
                  - <text style={{whiteSpace: "break-spaces"}}>{timestamp?.label}</text></FormWrapper>
                )
              })}</FW>
            </ContentWrapper>}
              <MusicInfoWrapper>
                <ContentWrapper heading={"Episode"}>
                  <ContentList
                  onButtonClick={togglePlay}
                    type={CONTENT_TYPES.SONG}
                    content={[{
                      ...episode,
                      playingFrom: {
                        playingFrom: MUSIC_PLAYING_FROM.EPISODE,
                        id: episode.episodeId,
                      },
                    }]}
                  />
                </ContentWrapper>
                <MusicContentWrapper>
                  <ContentWrapper heading={`Comments`}>
                    <CommentsSection
                      type={CONTENT_TYPES.EPISODE}
                      id={episode?.episodeId}
                      contentName={episode?.episodeName}
                    />
                  </ContentWrapper>

                  {/* {isSALoaded && similarEpisodes?.filter(a => a?.episodeId !== episode?.episodeId)?.length > 0 && <ContentWrapper
                    heading={`Similar Episodes to ${episode.episodeName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.EPISODE}
                      id={"recommended episodes"}
                      slides={similarEpisodes}
                    />
                  </ContentWrapper>}
                  {!isSALoaded &&  <ContentWrapper
                    heading={`Similar Episodes to ${episode.episodeName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"recommended episodes"}
                    />
                  </ContentWrapper>}
                  {isAALoaded && podcasterEpisodes?.filter(a => a?.episodeId !== episode?.episodeId)?.length > 0 && <ContentWrapper
                    heading={`Episodes by ${episode.podcasters[0].podcasterName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.EPISODE}
                      id={"episodes by podcaster"}
                      slides={podcasterEpisodes}
                    />
                  </ContentWrapper>}
                  {!isAALoaded && <ContentWrapper
                    heading={`Episodes by ${episode.podcasters[0].podcasterName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"episodes by podcaster"}
                   />
                  </ContentWrapper>} */}
                </MusicContentWrapper>
              </MusicInfoWrapper></>
            )}
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.EPISODE} content={episode} />
            )}
            {currentTab === "purchase" && (
              <>
                <MusicInfoWrapper>
                  <ContentWrapper>
                    Coming Soon
                  </ContentWrapper>
                </MusicInfoWrapper>
              </>
            )}
          </MusicDetailsContainer>
        </>
 
    </MusicPageWrapper>
  );
};

export default EpisodePage;
