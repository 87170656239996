import Marquee from "react-fast-marquee";
import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";



export const AudioPlayerContainer = styled.div`
display: flex;
position: fixed;
flex-direction: row;
align-items:center;
min-width: 100%;
max-width: 100%;
height: 13vh;
border-radius: 25px;
background: ${(props) => props?.isLoaded ? hexToRgba(props.theme.mainBg, 0.85) : hexToRgba(props.theme.secondaryColor, 0.15)};
backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(8px);
justify-content: flex-start;
padding: 1% 2%;
bottom: 0%;
z-index: 100;
overflow: hidden;
border-top: groove 3px ${props => hexToRgba(props.theme.mainColor)};
color: ${(props) => props.theme.secondaryBg};
  //filter: ${props => props.scrollNav? 'drop-shadow(0px 4px 0px rgba(179, 111, 247, 0.76))': `drop-shadow(0px 4px 4px ${props.theme.accentColor})`};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
    height: 10vh;
  }
  
`

export const ArtistInfoContainer = styled.div`

  display: flex;
  padding: 2% 0%;
  left: 0;
  flex: 1 1 40%;
  flex-direction: row;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    flex: 1 1 30%;
  }
`

export const ArtistImgContainer = styled.div`
  display: flex;
  border: 1px;
  box-shadow: 0.5px 1px 0px 0px  ${props => props.theme.secondaryBg};
  border-radius: 5%;
  margin-right: 5%;
  height: 2.8rem;
  width: 2.8rem;
  z-index: 20;

  &:hover{
    transform: scale(1.07);
    transition: 0.8s ease-out;
}

`

export const ArtistDetailsContainer = styled.div`
display: flex;
position: relative;
flex-direction: column;
justify-content: center;
overflow: hidden;

`
export const ArtistDetailsText = styled.text`
  display: flex;
  font-size: 0.8rem;
  white-space: nowrap;
  @media screen and (max-width: 960px) {
    font-size: 0.6em;
  }
  @media screen and (max-width: 680px) {
    display: none;
  }
`

export const ControlsContainer = styled.div`
display: flex;
flex-direction: row;
padding: 1% 2%;
flex: 1 1 80%;
align-content: center;
justify-content: center;
`

export const WidgetsContainer = styled.div`
display: flex;
align-content: center;
justify-content: center;
margin: -2% 1%;
padding: 1% 2%;
flex: 1 1 20%;
width: 100%;
@media screen and (max-width: 960px) {
  display: none;
}
`


export const WidgetControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5% 3%;
  flex: 1 1 50%;
  padding: 5% 3%;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 960px) {
    flex: 1 1 100%;
    margin: 5% 1%;
  }
`

export const Audio = styled.audio`

`

export const ProgressBarWrapper = styled.div`
display: flex;
position: relative;
padding-top: 2%;
padding-left: 1%;
padding-right: 1%;
`

export const Chapter = styled.div`
display: block;
height: 11px;
background-color: black;
position: absolute;
top:4px;
left: ${props => props.left};
width: ${props => props.width};;
pointer-events: none;
z-index: 2;
`


export const AudioControlButton = styled.button`
background: none;
border: none;
justify-content: center;
display: flex;
font-size: 30px;
margin: 8px 0px 0px;
align-items; center;
cursor: pointer;
  color: ${(props) => props.isActive ? props.theme.mainColor:  props.theme.secondaryColor};

&:hover {
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.mainColor};
  }

`
export const AudioPlayPauseButton = styled.button`
display: flex;
border-radius: 50%;
padding: 0% 6%;
margin: 3% 0%;
background: ${(props) => props.theme.secondaryBg};
height: 2.3rem;
font-size: 2rem;
width: 2.3rem;
color: ${(props) =>  props.theme.accentColor};
justify-content: center;
align-items: center;
background: transparent;

&:hover {
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.mainColor};
}

`

export const ProgressBar = styled.input`


    appearance: none;
    background: ${(props) => props.theme.secondaryBg};
    position: relative;
    width: 30vw;
    border-radius: 50px;
    height: 3px;
    outline: none;
    

    
    /*Safari settings */
    ::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.secondaryBg};
    width: 30vw;
    border-radius: 50px;
    height: 3px;
    outline: none;
    }


    /*firefox settings */
    ::-moz-range-track {
      background: ${(props) => props.theme.mainBg};
      width: 30vw;    
      border-radius: 10px;
      height: 3px;
      outline: none;
    }

    ::-moz-focus-outer{
        border: 0;
    }

    /* progress bar safari & chrome */
   ::-webkit-runnable-track { 
     
    }

    &:before{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 4px;
      width: ${props => props.progressWidth};
      z-index: 2;
      background-color: ${props => props.theme.mainColor};
   
    }


  



    ::-moz-range-progress{
      &:before{
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 4px;
        width: ${props => props.progressWidth};
        z-index: 2;
        background-color: ${props => props.theme.mainColor};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }


    /* knob chrome + safari */
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: solid 3px ${props => props.theme.mainColor};
        background-color: ${props => props.theme.mainColor};
        position: relative;
        margin: -3px 0px 0px;
        box-sizing: border-box;
        z-index: 3;
        cursor: pointer;
        


        &:hover {
          border-radius: 50px;
          transition: all 0.3s ease-in-out;
          color: ${(props) => props.theme.mainColor};
        }

    /* Knob while dragging - chrome + safari */
        &:active{
        transform: scale(1.3);
        background-color: ${props => props.theme.mainColor};
      }
    }

    ::-moz-range-thumb{
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: solid 3px ${props => props.theme.mainColor};
        background-color: ${props => props.theme.mainColor};
        position: relative;
        box-sizing: border-box;
        z-index: 3;
        cursor: pointer;

        /* Knob while dragging - firefox */

        &:active{
            transform: scale(1.2);
            background-color: ${props => props.theme.mainColor};
          }
    }

    &:hover {
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
      color: ${(props) => props.theme.mainColor};
    }

`

export const Img = styled.img`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 2.8rem;
  height: 2.8rem;
`

export const AudioText = styled.text`
  display: flex;
  margin: 1.2% 1%;
  font-size: 0.7rem;
`

export const VolumeWrapper = styled.div`
  width: 100px;
  display: flex;
  margin: 0 20px;
`

export const VolumeIcon = styled.div`
    display: flex;
    cursor: pointer;
`


export const VolumeKnob = styled.input`
    display: none;
    background: ${(props) => props.theme.secondaryBg};
    position: absolute;
    border-radius: 50px;
    height: 3px;
    outline: none;
    z-index: 0;
    cursor: pointer;

    ${VolumeIcon}:hover & {
      display: flex;
      left: 80%;
      z-index: 8;
  }
`

