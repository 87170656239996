import { FaChevronCircleDown, FaChevronDown, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";

export const Modal = styled.div`
  display: flex;
  position: sticky;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const PlaylistContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: url(${props => props.src}) center;
    background-size: cover;
    background-color: ${(props) => props.theme.mainBg};
    max-width: 100vw;
    min-height: 100vh;
    height: 100%;
    top: ${props => props.isOpen ? '0%' : '-150%'};
    position: relative;
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
      }

`
export const PlaylistVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.mainBg};
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    
    top: ${props => props.isOpen ? '0%' : '-150%'};
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
      }

`
export const PlaylistContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props?.visualizer ? hexToRgba(props.theme.mainBg, 0.4) :  hexToRgba(props.theme.mainBg, 0.8)};
    max-width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding: 3% 2%
    top: ${props => props.isOpen ? '0%' : '-150%'};
    position: relative;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: ${(props) => props?.visualizer ? 'none' : `blur(40px)`};
    overflow: hidden;
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
      }

`

export const PlaylistVideoContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => hexToRgba(props.theme.mainBg, 0.4)};
    width: 100%;
    height: 100%;
    top: ${props => props.isOpen ? '0%' : '-150%'};
    position: relative;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(30px);
    color: ${(props) => props.theme.secondaryColor};
      @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
      }

`

export const PlaylistHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 15px;
  height: 30vh;
  z-index: 10;
  background: ${props => hexToRgba(props.theme.mainBg, 0.2)};
  `
  export const  PlaylistHeader = styled.div`
  display: flex;
  position:relative;
  flex-direction: row;
  
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 8% 3%;
  
  `
  
  export const Img = styled.div`
    position: absolute;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background: ${props => `url(${props.background}) no-repeat fixed center`};
    width: 100%;
    height:100%;
  `
  
  export const AlbumArtContainer = styled.div`
  display: flex;
  z-index: 2;
  margin: 2%;
  flex: 1 1 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  
  
  `
  
  
  export const  AlbumArt = styled.img`
    display: flex;
    width: 10rem;
    height: 10rem;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);
    &:hover{
      transform: scale(1.03);
  
  }
  
  `
  export const HeaderTextContainer = styled.div`
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    color: ${props => props.theme.secondaryBg};
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

    
  `

  export const HeaderText = styled.div`
    display: flex;
    z-index: 2;
    padding: 0.5%;
    font-weight: 700;
    font-size: 1.5rem;
    @media screen and (max-width: 480px) {
      font-size: 0.75rem;
    }
  
  `
  export const Text = styled.text`
    display: flex;
    z-index: 2;
    padding: 0.5%;
    font-weight: 300;
    @media screen and (max-width: 480px) {
      font-size: 0.55rem;
    }
  `
export const PlaylistNavContainer =styled(NavContainer)`
display: flex;
    margin: 5% 0;
`

export const PlaylistNav = styled(Nav)`
`

export const PlaylistNavLink = styled(NavLink)`
`
export const PlaylistWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding: 1.5%;
  white-space: pre-wrap;
  text-align: center;
`
export const CloseButton = styled(FaChevronCircleDown)`
display: flex;
  color: ${props => props.theme.secondaryBg};
  float: right;
  right: 0;
  z-index: 10;
  position: absolute;
  cursor: pointer;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 50%;
  margin: 1%;
  top: 0;
  &:hover {
   
    color: ${props => props.theme.mainBg};
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
  }


  

`
export const PlaylistFooter = styled.div`
  background: ${props => props.theme.mainBg};
  height: 20vh;
  display: flex;
  bottom: 0;

`