import React from 'react'
import { AudioVisualizerContainer, AudioVisualizerContent, Video } from './AudioVisualizerElements'
import { PlaylistFooter } from '../AudioPlayer/AudioPlayerPlaylist/AudioPlayerPlaylistElements'

const AudioVisualizer
 = ({visual, children}) => {
  return (
    <>
    <AudioVisualizerContainer>
        <Video autoplay={true} loop={true} muted src={visual} preload={"auto"} onContextMenu={(e) => {e.preventDefault()}}/>
        <AudioVisualizerContent>
          <div style={{display:"flex", width: "100%", height: "100%", flexDirection: "column", paddingBottom: "2%"}}>
            {children}
          </div>
            
        </AudioVisualizerContent>
        
    </AudioVisualizerContainer>
    <PlaylistFooter/>
    </>
  )
}

export default AudioVisualizer