import styled from 'styled-components'

export const  ControlButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;



`


export const AudioControlButton = styled.div`
background: none;
border: none;
justify-content: center;
display: flex;
font-size: 30px;
margin: 8px 0px 0px;
align-items; center;
cursor: pointer;
  color: ${(props) => props.isActive ? props.theme.mainColor:  props.theme.secondaryColor};

&:hover {
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.mainColor};
  }

`
export const AudioPlayPauseButton = styled.div`
display: flex;
border-radius: 50%;
padding: 0% 6%;
margin: 3% 0%;
background: ${(props) => props.theme.secondaryBg};
height: 2.3rem;
font-size: 2rem;
width: 2.3rem;
color: ${(props) =>  props.theme.accentColor};
justify-content: center;
align-items: center;
background: transparent;

&:hover {
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.mainColor};
}

`