import React, {  useState, useRef, useEffect, useContext } from "react"
import AudioPlayer, { AudioPlayerSkeleton } from "../../AudioPlayer1";
import { useAuth } from "../../../contexts/AuthContext";
import { useSonarMusePlayer } from "../../../hooks/AudioHook";
import AudioPlayerPlaylist, { AudioPlayerVisualizer } from "../AudioPlayerPlaylist";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserQueue} from "../../../utils/dbUtils/userUtils";

export const AudioContext = React.createContext();

export function useAudio() {
    return useContext(AudioContext);
  }


export const AudioProvider = ({children}) => {
    const {currentUser, gravatarInfo} = useAuth()
    const [isOpen, setOpen] = useState(false)
    const [isQueueLoaded, setQueueLoaded] = useState(false)
    const location = useLocation()
    const navigate  = useNavigate()
   const url1 = new URL(window.location.href);
        const params = new URLSearchParams(url1.search);
  
    const {
     isPlaying, 
     stop,
    duration, 
    currentTime, 
    progressBar, 
    audioPlayer,
    playSong,
    addToQueue,
    playNext,
    handleToggle,
    changeRange,
    isRepeat,
    setTimeJump,
    calcTime, 
    backThirty, 
    forwardThirty,
    isShuffle,
    setPlaylist,
    playlist, 
    volume,
    removeFromQueue,
    play,
    timeTravel,
    togglePlayPause,
    setCurrentSong,
    toggleMute,
    toggleVolume,
    isMute,
    handleEnd,
    currentSong} = useSonarMusePlayer()

    const openViewer = () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        
        if(isOpen){
            window.scrollY = 0
            document.body.scrollY = 0
            params.delete("queue");
            navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
                replace:true},
              );
        }else{
            params.set("queue", "true")
            navigate(`?${params.toString()}`,{
                replace:true},
              );
        }
        setOpen(!isOpen)
}

useEffect(() => {
   
    if(!params.has("queue")){
        closeViewer()
   }
   if(params.has("queue")){
        setOpen(true)
   }
    return () => {
      
    }
  }, [params])

useEffect(() => {
    if(!isQueueLoaded){
    getUserQueue(currentUser?.uid, setPlaylist,setCurrentSong, handleToggle, isQueueLoaded, setQueueLoaded)
}
}, [currentUser, isQueueLoaded])


 const closeViewer = (url) => {
    const url1 = new URL(window.location.href);
    const params = new URLSearchParams(url1.search);
    setOpen(false)    
    if(params.has("queue")){
         params.delete("queue");
         navigate(`${params.toString().length > 0 ? `?${params.toString()}` : '#'}`,{
            replace:true},
          )}
    }

    const handleMainPlayerEnd = () => {
        handleEnd()
    }

    const removeMusicFromQueue = (song) => {
        removeFromQueue(song)
    }

    const addMusicToQueue = (music) => {
        addToQueue(music)
    }

    const stopMainPlayer = () => {
        if(isPlaying){
            togglePlayPause()
        }
        return
    }

    const setMusicPlaylist = (music) => {
        setPlaylist(music)

    }

    const playMusic = (music) => {
        playSong(music)

    }

    const nextSong = () => {
        playNext()
    }

    const handleMusicToggle = (type, val) => {
         handleToggle(type, val)

       
    }

    const setCurrentSong1 = (val) => {
        setCurrentSong(val)
    }


   const  value = {
    isQueueLoaded,
    isOpen,
    stop,
    play,
    openViewer,
    isPlaying, 
    duration, 
    currentTime, 
    progressBar, 
    audioPlayer,
    timeTravel,
    playSong,
    handleToggle,
    changeRange,
    addToQueue,
    isRepeat,
    setTimeJump,
    calcTime, 
    backThirty, 
    forwardThirty,
    isShuffle,
    setPlaylist,
    playlist, 
    volume,
    isMute,
    togglePlayPause,
    setCurrentSong,
    currentSong,
    playNext,
    removeFromQueue,
    toggleMute,
    toggleVolume,
    closeViewer,
    stopMainPlayer,
    handleEnd
    }

    
return (
  
    <AudioContext.Provider value={value}>
       {isOpen && !playlist[currentSong]?.visualizer?.url && <AudioPlayerPlaylist isOpen={isOpen} onClose={closeViewer} setCurrentSong={setCurrentSong}/>} 
       {isOpen && playlist[currentSong]?.visualizer?.url && <AudioPlayerVisualizer isOpen={isOpen} onClose={closeViewer} setCurrentSong={setCurrentSong}/>}
      <div style={{display: isOpen ? "none": "block", maxWidth:"100vw"}}> {children}</div>
    {currentUser?.uid &&
    <>   
    {!isQueueLoaded && <AudioPlayerSkeleton/>}
     {isQueueLoaded && <AudioPlayer {...value}/>}
     </> 
     
     }
    </AudioContext.Provider>
)
    

}