import React, { useCallback, useEffect, useRef, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicLyricsWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt, FaCrown } from "react-icons/fa";
import { GiScrollUnfurled } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { Slider } from "../Slider";
import Marquee from "react-text-marquee";
import { useAuth } from "../../contexts/AuthContext";
import { filterLikes, getArtistsNames, getSongTitle, stringFormatter, timestampToDate } from "../../utils";
import { useContext } from "react";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useNavigate, useLocation } from "react-router-dom";
import QRCodeForm from "../QRCodeForm";
import { useAlert } from "../Alert/AlertContext";

import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import Modal from "../Modal";
import PurchaseModal from "../PurchaseModal";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import {MusicOptionsModal } from "../MenuOptions";
import useMusic from "../../hooks/useMusic";
import { NavContainer } from "../NavLink/NavLinkElements";
import MusicEditPage from "../MusicEditPage";
import OptionModal from "../OptionModal";
import { FormButton, FormInput1 } from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import CommentForm from "../CommentForm";
import CommentsSection from "../CommentsSection";
import useArtist from "../../hooks/useArtist";
import ScrollToTop from "../ScrollToTop";
import { shuffleSongs } from "../../utils/MusicUtils";
import { MdExplicit } from "react-icons/md";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import { MusicStoreSongList } from "../ContentList/SongList";
import { TextLink } from "../SignUp/SignUpElements";
import VideoPlayer from "../VideoPlayer";
import { VideoPlayerSkeleton } from "../Skeleton";
import { RiVideoFill } from "react-icons/ri";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { getResizedImage } from "../../utils/FileUploadsUtils";

function SongPage({
  id,
  song,
  isSongPlaying,
  handleToggle,
  audioPlayer,
  stopMainPlayer,
  deleteSong,
  timeTravel,
  hasPurchased,
  currentSong,
  playlist,
  currentTab,
  setTab,
  query,
  location,
  updateWebPageIcon
}) {
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const {goTo} = ScrollToTop()
  const { modalContent, toggleModal, isOpen, openModal, closeModal } = useModal();
  const [similarSongs, setSimilarSongs] = useState({});
  const [artistSongs, setArtistSongs] = useState({});
  const [playlists, setPlaylists] = useState({})
  const [collections, setCollections] = useState({})
  const navigate  = useNavigate();
  const [requestPurchase, setRequest] = useState(false);
  const [requestNFTMint, setMintRequest] = useState(false);
  const [body, setBody] = useState({});
  const [isPlaying, setPlaying] = useState(false)
  const { getSimilarSongs, getNFTokenCollectionsBySong,getPlaylistsContainingSong } = useMusic();
  const {searchArtistSingles} = useArtist({})
  const [isLoaded, setLoaded] = useState(false)
  const [isASLoaded, setASLoaded] = useState(false)
  const [isNFTLoaded, setNFTLoaded] = useState(false)
  const [isPLoaded, setPLoaded] = useState(false)
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(song?.albumArt)
  const { addAlert } = useAlert();
  const {theme} = useWebsiteTheme()


  const { setCurrentSong, setPlaylist, play, isQueueLoaded } = useAudio();
  const onSuccess = () => {};
  const isPlayingFrom = useCallback(() => {
    if (
      localStorage?.getItem("isPlayingFrom") && 
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.SONG &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id === song?.songId
    ) {
      return true;
    }
    return false;
  },[song]);

  useEffect(() => {
    updateWebPageIcon(song?.albumArt)
    return () => {}
  },[])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(song?.albumArt).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(song?.albumArt)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

  useEffect(() => {
    if(isSongPlaying && isPlayingFrom()){
      setPlaying(true)
    }else{
      setPlaying(false)
    }
  
   },[isSongPlaying, isPlayingFrom])


  const playSong = (i) => {
    if (currentUser?.uid ){
      if(isUserSubscribed()) {
      setPlaylist([song]);
      setCurrentSong(i);
      handleToggle("play");
    } else {
      audioPlayer.current.src = song.url;
    }
  }
  };

  const togglePlay = async (pl) => {
    let playingFrom = {
        playingFrom: MUSIC_PLAYING_FROM.SONG,
        id: song?.songId,
      };
    if (currentUser?.uid){
      setPlaylist(pl);
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
      if(isQueueLoaded){
      
        // if(isPlaying && subscribed to coil)
        if (
          isSongPlaying &&
          isPlayingFrom()        ) {
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          setPlaying(false)
          await handleToggle("play-pause");
          return
        }
        if (
          isSongPlaying &&
          !isPlayingFrom()        ) {
          setPlaylist(pl);
          setCurrentSong(0);
          
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));

          await handleToggle("play");
          setPlaying(true)
          return
        }
        if (
          !isSongPlaying &&
          !isPlayingFrom()        ) {
          setPlaylist(pl);
          setCurrentSong(0);
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          await handleToggle("play-pause");
          setPlaying(true)
          return
        } else {
          setPlaylist(pl);
          setCurrentSong(0);
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
         await handleToggle("play");
         setPlaying(true)
          return
        }
        
        }
      }
      if(isPlay === "Network Error"){
        if (
          isSongPlaying
        ) {
          handleToggle("pause")
          setPlaying(false)
        }
       }
      else{
        addAlert({
          title: "Network Error",
          type: ALERT_TYPES.DANGER,
          message:
            'Cannot play music. Please check your connection',
        })
        if (
          isSongPlaying
        ) {
          handleToggle("pause")
          setPlaying(false)
        }
        openModal() 
            modalContent(<><StreamingSubscriptionModal onSuccess={() => {
              getUserSubscription(currentUser?.uid);
              handleToggle("play-pause", !isSongPlaying)
              setPlaying(true)
              localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
              modalContent(<OptionModal heading={"Subscription Sucessful"}>
                <HoverOption onClick={() =>
            closeModal()}>OK</HoverOption>
            </OptionModal>)
               }} onFail={() => {
              modalContent(<OptionModal heading={"Subscription Unsucessful"}>
                <HoverOption onClick={() => {closeModal()
                modalContent()}}>OK</HoverOption>
            </OptionModal>)}} cancel={() => {closeModal()
            modalContent()}} /></>)
      }
    
    } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this song",
      });
    }
  };



  const onFailure = () => {
    setRequest(false);
  };

  const cancelPurchase = () => {
    setRequest(false);
  };

  /*  const displaySimilarSongs = async() => {
      return await getSimilarSongs(song).then(songs => {
        if(songs){
          setSimilarSongs(songs)
          return ()
        }
      })
     
    
  } */

  useEffect(() => {
    setLoaded(false)
    setASLoaded(false)
    setNFTLoaded(false)
    if(!isLoaded){
      getSimilarSongs(song?.songId).then(data => {
        if(data){

          setSimilarSongs(data)
          setLoaded(true)
        }
      }).catch(err => {
        setLoaded(true)
        console.error(err)
        return
  
      })
      searchArtistSingles(song?.artists[0]?.artistId).then(data => {
        if(data){
          setArtistSongs(data)
          setASLoaded(true)
        }
      }).catch(err => {
        setASLoaded(true)
        console.error(err)
        return
  
      })
    getNFTokenCollectionsBySong(song?.songId, 0).then(data => {
      if(data){
        setCollections(data)
        
      }
      setNFTLoaded(true)
    }).catch(err => {
      setNFTLoaded(true)
      console.error(err)
      return

    })
    getPlaylistsContainingSong(song?.songId, 0).then(data => {
      if(data){
        setPlaylists(data)
        
      }
      setPLoaded(true)
    }).catch(err => {
      setPLoaded(true)
      console.error(err)
      return

    })
    }
      return () => {}
  }, [id])
  

  const postComment = () => {
    if (!isOpen) {
      toggleModal();
    }
    modalContent(
      <>
        <CommentForm
          contentName={getSongTitle(song)}
          contentId={song?.songId}
          type={CONTENT_TYPES.SONG}
        />
      </>
    );
  };

  const musicOptions = (e) => {
    switch (e.target.value) {
      case "NFT mint":
        setBody({
          sender: currentUser?.uid,
          receiver: song.uploadedBy,
          value: song.price,
          id: song.songId,
          type: "song",
          uri: currentUser?.uid,
          memo: `${currentUser?.uid} is minting their upload ${getSongTitle(
            song
          )}`,
          instruction: `${
            currentUser?.uid
          } is minting the single ${getSongTitle(song)} by ${getArtistsNames(
            song?.artists,
            ARTIST_SEARCH_TYPES.NAME
          )} `,
        });
        setMintRequest(true);
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`,{
          replace:true},
        );

        break;
      case "download":
        break;
      default:
        break;
    }
  };

  const purchaseOptions = (e) => {
    switch (e.target.value) {
      case MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE:
        toggleModal();
        modalContent(
          <PurchaseModal type={MUSIC_PURCHASE_OPTIONS.DIGITAL_SONG_PURCHASE} />
        );
        break;
      case MUSIC_PURCHASE_OPTIONS.NFT_PURCHASE:
        break;
      case MUSIC_PURCHASE_OPTIONS.DOWNLOAD:
        break;

      default:
        break;
    }
    e.target.value = "Buy";
  };

  return (
    <>
      {requestPurchase && song?.isDownloadable && (
        <Modal isOpen={requestPurchase} onRequestClose={cancelPurchase}>
          <QRCodeForm
            heading={`Purchasing ${getSongTitle(song)} by ${getArtistsNames(
              song.artists,
              ARTIST_SEARCH_TYPES.NAME
            )}`}
            body={body}
            cancelQr={cancelPurchase}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.DIGITAL_SONG_PURCHASE}
          ></QRCodeForm>
        </Modal>
      )}
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            src={isAlbumArtLoaded ? albumArt : song?.albumArt}
            alt={song.songName}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage 
            src={isAlbumArtLoaded ? albumArt : song?.albumArt}
            alt={song.songName} />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
            <SubHeading><small>{song?.album ? <div style={{color: theme.secondaryBg, cursor: "pointer"}} onClick={() => goTo(`/album/${song?.album?.albumId}`)}>from Album {song?.album?.albumName}</div> : `Single`}</small></SubHeading>

              <MusicHeading>{song?.explicit && <MdExplicit/>}{song?.video?.url && <RiVideoFill />}{getSongTitle(song)}</MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                >
                  {song?.artists?.map((artist, index) => {
                    if (index < song.artists.length - 1) {
                      return <div key={artist?.artistId} style={{marginRight: '0.5rem'}} onClick={() =>
                        goTo(`/artist/${artist.artistId}`)
                      }>{artist.artistName + " ○ "}</div>;
                    } else {
                      return <div key={artist?.artistId} onClick={() =>
                        goTo(`/artist/${artist.artistId}`)
                      }>{artist.artistName}</div>;
                    }
                  })}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>{song?.genre?.name}○{new Date(timestampToDate(song?.releaseDate)).getFullYear()}</MusicHeadingSmall>

              <MusicOptionsContainer>
                
                  <>{(song?.isStreamable /* || song?.isSubscription */) && <MusicPlayPauseButton
                    onClick={() => togglePlay([{...song,
                          playingFrom: {
                              playingFrom: MUSIC_PLAYING_FROM.SONG,
                              id: song?.songId,
                            }}])
                     
                    }
                  >
                    {isPlaying ? (
                      <>
                        <FaPause style={{ position: "relative", left: "1%" }} />{" "}
                        Pause
                      </>
                    ) : (
                      <>
                        <FaPlay style={{ position: "relative", left: "1%" }} />{" "}
                        Play
                      </>
                    )}
                  </MusicPlayPauseButton>}
               </>
              
                {currentUser?.uid && (
                  <OptionButton
                    onClick={() =>
                      {openModal()
                      modalContent(<MusicOptionsModal content={song} type={ CONTENT_TYPES.SONG}/>)
                    }
                    }
                  >
                    <OptionMenuButton />
                  </OptionButton>
                )}
              </MusicOptionsContainer>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        
          <>
            <NavContainer>
              <MusicNav>
                <MusicNavLink
                  onClick={() => setTab("music")}
                  active={currentTab === "music"}
                >
                  Music
                </MusicNavLink>
                {song?.lyrics?.trim()?.length > 0 && <MusicNavLink
                  onClick={() => setTab("lyrics")}
                  active={currentTab === "lyrics"}
                >
                  Lyrics
                </MusicNavLink>}
                {song?.video && <MusicNavLink 
                onClick={() => setTab("video")}
                active={currentTab === "video"}
                >
                  Video
                  </MusicNavLink>}
                <MusicNavLink
                  onClick={() => setTab("about")}
                  active={currentTab === "about"}
                >
                  About {song?.songName}
                </MusicNavLink>
                {song?.isUserRoyalties && song?.userRoyalties?.royaltyId && (
                  <MusicNavLink
                    onClick={() => setTab("regium")}
                    active={currentTab === "regium"}
                  >
                   <FaCrown color="#a28834"/>Regium Share Token for {song.songName}
                  </MusicNavLink>
                )}
                {song?.isDownloadable && (
                  <MusicNavLink
                    onClick={() => setTab("purchase")}
                    active={currentTab === "purchase"}
                  >
                    Purchase {song.songName}
                  </MusicNavLink>
                )}
               
              </MusicNav>
            </NavContainer>
            <MusicDetailsContainer>
              {currentTab === "music" && (
                <ContentWrapper>
                  {(song?.isStreamable && isQueueLoaded) && <ContentList
                    onButtonClick={playSong}
                    type={CONTENT_TYPES.SONG}
                    content={[{...song,
                      playingFrom: {
                          playingFrom: MUSIC_PLAYING_FROM.SONG,
                          id: song.songId,
                        }}]}
                  />}
                                    {(!song?.isStreamable || !isQueueLoaded) && <MusicStoreSongList content={[song]} isPlaying={isSongPlaying} currentSong={currentSong} playlist={() =>{}}/>}

                </ContentWrapper>
              )}
            </MusicDetailsContainer>
            {currentTab === "video" && 
            <MusicContentWrapper style={{marginBottom: "5%"}}>
            <VideoPlayer content={song} stopMainPlayer={stopMainPlayer} isPlaying={isSongPlaying} validateSubscription={true}/>
            <ContentWrapper heading={`Comments`}>
                 <CommentsSection type={CONTENT_TYPES.SONG} id={song?.songId} contentName={getSongTitle(song)}/>
                </ContentWrapper>
            </MusicContentWrapper> 
            }
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.SONG} content={song} />
            )}
            {song?.lyrics?.trim()?.length > 0 && currentTab === "lyrics" && (
              <ContentWrapper><MusicLyricsWrapper>
                {stringFormatter(song?.lyrics)}
              </MusicLyricsWrapper></ContentWrapper>
            )}
            {currentTab === "regium" && (
              <MusicContentWrapper>
                <MusicAboutWrapper>Purchase {song.songName} (${song.userRoyalties?.ticker}) regium share token to earn revenue from streams{song?.isDownloadable && ' and sales'}.</MusicAboutWrapper>
                <ContentWrapper heading={`Regium Share Token for ${song.songName}`}>
                  <Slider id={'regium token'} type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE} slides={[{...song?.userRoyalties, musicDetails: song}]}/>
                </ContentWrapper>
              </MusicContentWrapper>
            )}
            {currentTab === "music" && (
              <MusicContentWrapper>
                <ContentWrapper heading={`Comments`}>
                 <CommentsSection type={CONTENT_TYPES.SONG} id={song?.songId} contentName={getSongTitle(song)}/>
                </ContentWrapper>
                {isNFTLoaded && collections.length > 0 && <ContentWrapper
                  heading={`NFToken Collections Containing The Song ${song.songName}`}
                >
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"collections"}
                    slides={collections.collections}
                  />
                </ContentWrapper>}
                {!isNFTLoaded && <ContentWrapper
                  heading={`NFToken Collections Containing The Song ${song.songName}`}
                  onClick={() => collections.length > 10 ? navigate(`/song/${song?.songId}/collections`) : false}
                  subheading={collections?.length > 10 && "Show All"}
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"collections"}
                  />
                </ContentWrapper>}
                {isLoaded && similarSongs?.length > 0 && <ContentWrapper heading={`Similar Songs to ${song.songName}`} onClick={() => similarSongs?.length > 10 &&
                  navigate(`/song/${song.songId}/similarSongs`)
                } subheading={similarSongs?.length > 10 && 'View All'}>
                  <Slider
                    type={SLIDER_TYPES.SONG}
                    id={"recommended songs"}
                    slides={
                      similarSongs.songs
                    }
                  />
                </ContentWrapper>}
                {!isLoaded && <ContentWrapper heading={`Similar Songs to ${song.songName}`}>
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"recommended songs"}
                  />
                </ContentWrapper>}
                {isASLoaded && (artistSongs?.length > 0 && artistSongs.songs.filter(s => s.songId !== song?.songId)?.length > 0) && <ContentWrapper
                  heading={`Songs by ${song.artists[0].artistName}`}
                  onClick={() => artistSongs?.length > 10 && 
                    navigate(`/artist/${song?.artists[0]?.artistId}/singles`)
                  }
                  subheading={artistSongs?.length > 10 && "View All"}
                >
                  <Slider
                    type={SLIDER_TYPES.SONG}
                    id={"songs by artist"}
                    slides={artistSongs.songs.filter(s => s.songId !== song?.songId)}
                  />
                </ContentWrapper>}
                {!isASLoaded && <ContentWrapper
                  heading={`Songs by ${song?.artists[0]?.artistName}`}
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"songs by artist"}
                  />
                </ContentWrapper>}

                {isPLoaded && (playlists?.length > 0 && playlists.playlists.filter(p => p.playlistId !== playlist?.playlistId)?.length > 0) && <ContentWrapper
                  heading={`Playlists containing ${song.songName}`}
                  onClick={() => playlists?.length > 10 && 
                    navigate(`/playlists/${song?.songId}/songs`)
                  }
                  subheading={playlists?.length > 10 && "View All"}
                >
                  <Slider
                    type={SLIDER_TYPES.PLAYLIST}
                    id={"playlists containing song"}
                    slides={playlists.playlists.filter(p => p.playlistId !== playlist?.playlistId)}
                  />
                </ContentWrapper>}
                {!isPLoaded && <ContentWrapper
                  heading={`Playlists containing ${song.songName}`}
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"playlists containing song"}
                  />
                </ContentWrapper>}
              
              </MusicContentWrapper>
            )}
            {currentTab === "purchase" && (
              <MusicContentWrapper>
                {song?.isDownloadable && (
                  <ContentWrapper heading={`Purchase ${song.songName}`}>
                    <Slider
                      type={SLIDER_TYPES.DIGITAL_SONG}
                      id={"digital purchase"}
                      slides={[song]}
                    />
                  </ContentWrapper>
                )}
                {/* song?.isMintable && (
                  <>
                    <ContentWrapper
                      heading={`Available ${song.songName} NFToken Collections`}
                    >
                      <Slider
                        type={SLIDER_TYPES.COLLECTION}
                        id={"nft collections"}
                        heightScroll={true}
                        slides={[]}
                      />
                    </ContentWrapper>

                    <ContentWrapper heading={`${song.songName} NFToken Owners`}>
                      <Slider
                        type={SLIDER_TYPES.USER}
                        id={"users"}
                        slides={[1, 2, 3, 4, 5, 6, 7, 8]}
                      />
                    </ContentWrapper>
                  </>
                ) */}
              </MusicContentWrapper>
            )}
          </>
      </MusicPageWrapper>
    </>
  );
}

export default SongPage;
