import { strToHex } from "."

export const ERRORS = { // Error messages
  NETWORK: "Network Error",
  STATUS_401: "Request failed with status code 401",
  STATUS_500: "Request failed with status code 500",
  FAILED_REQUEST: "Request failed "
}
export const SERVERS = {
  NFT_DEV_NET: "wss://xls20-sandbox.rippletest.net:51233",
  TEST_NET: ["wss://s.altnet.rippletest.net:51233"],
  MAINNET: ["wss://fh.xrpl.ws"],
  DEV_NET: ["wss://s.devnet.rippletest.net:51233","wss://s.devnet.rippletest.net:51233"],
}

export const XRPL_SERVERS = {
  NFT_DEV_NET: "wss://xls20-sandbox.rippletest.net:51233",
  TEST_NET: ["wss://s.altnet.rippletest.net:51233"],
  MAINNET: ["wss://fh.xrpl.ws"],
  DEV_NET: ["wss://s.devnet.rippletest.net:51233","wss://s.devnet.rippletest.net:51233"],
}

export const SECRETS = {
  NFT_DEV_NET: [
    "sh9Qkx1t7QYyHogrfNXjAG5UHAEot",
  "ssM1nCGiWBb9wpYaTbhCthweu8KoT", 
  "ssMkqDhGWViZuZqcD2xjjXzBvJHRT"],
  DEV_NET: [
    "ssxfZbeL5b119vpKEYAdq96AYPgvB", 
    "sn9725pfmmyAaJV1ao1k51PezBS6L", 
    "sn4g4rBqQhcZnbUmAXJpsQ3xDnYzs"],
  MAIN_NET_NFT_MINTER_SECRET: process.env.REACT_APP_MAIN_NET_NFT_MINTER_SECRET,
  MAIN_NET_COMMENTS_SECRET : process.env.REACT_APP_MAIN_NET_COMMENTS_SECRET,
  MAIN_NET_DIGITAL_PURCHASE_SECRET : process.env.REACT_APP_MAIN_NET_DIGITAL_PURCHASE_SECRET,
  MAIN_NET_NFT_PURCHASE_SECRET : process.env.REACT_APP_MAIN_NET_NFT_PURCHASE_SECRET,
  MAIN_NET_SUBSCRIPTION_MICROPAYMENTS_SECRET : process.env.REACT_APP_MAIN_NET_SUBSCRIPTION_MICROPAYMENTS_SECRET
}

export const WEBSITE_THEME_ACTIONS = {
  CHANGE_THEME: 'change theme',
  CHANGE_MAIN_COLOR: 'change main color'
}

export const XRPL_ERRORS = {
  DISCONNECTED: "WebSocket is not open: readyState 2",
  DISCONNECTED2: "WebSocket is not open: readyState 0" ,
  SEQUENCE: "Preliminary result: tefPAST_SEQ",
  TIMEOUT: "TimeoutError"
}

export const ALERT_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger'
}

export const NOTIFICATION_TYPES  = {
  LIKE: 'like',
  COMMENT: 'comment',
  PLAYS: 'plays',
  FOLLOW: 'follow',
  ARTIST_FOLLOW: 'artist follow',
  PODCASTER_FOLLOW: 'podcaster follow',
  RECORD_LABEL_FOLLOW: 'record label follow',
  TIP: 'tip',
  SUBSCRIPTION: 'subscription',
  PURCHASE: 'purchase',
  PENDING_NFTOKEN: 'pending nftoken',
  MUSIC_RELEASE: 'music release',
  COLLECTION_AIRDROP: 'collection airdrop'
}

export const NOTIFICATION_DISPATCH = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION'
}

export const CONTENT_TYPES = { //Content types for list view
  DRAFT: "draft",
  COLLECTION : "collection",
  NFTOKEN: 'nftoken',
  ALBUM: "album",
  SONG: "song" ,
  PLAYLIST: "playlist",
  ARTIST: 'artist',
  USER: 'user',
  ROYALTY_SHARE: 'royalty share',
  RECORD_LABEL: 'record label',
  PODCASTER: "podcasters",
  GENRE: 'genre',
  PODCAST: 'podcast',
  EPISODE: 'episode',
  COMMENT: 'comment',
  NOTIFICATION: 'notification',
  MISCELLANEOUS: 'miscellaneous'
}
export const SLIDER_TYPES = { //Slider types
  DRAFT: "draft",
  COLLECTION : "collections",
  NFTOKEN : "nftoken",
  NFTOKEN_COLLECTIONS: "nftoken collections",
  ALBUM: "albums",
  SONG: "songs" ,
  PLAYLIST: "playlists",
  ARTIST: 'artists',
  GENRE: 'genres',
  USER: 'users',
  DIGITAL_ALBUM: 'digital albums',
  DIGITAL_SONG: 'digital songs',
  MUSIC_ROYALTY_SHARE: "royalty share",
  PODCAST: 'podcasts',
  PODCASTER: "podcasters",
  EPISODE: 'podcast episodes',
  VIDEO: "Music Video",
  MISCELLANEOUS: 'miscellaneous',
  MISCELLANEOUS_MUSIC: 'miscellaneous music',
  RECORD_LABEL: 'record label',
  PURCHASE_SKELETON: 'purchase skeleton',
  USER_SKELETON: 'user skeleton',
  MUSIC_SKELETON: 'music skeleton',
  GENRE_SKELETON: 'genre skeleton',
  ARTIST_SKELETON: 'artist skeleton',
  DOWNLOAD: 'download',
  SWERL_VOUCHER: "swerl vouchers"

}

export const ARTIST_FORM_TYPES = { //Artist form input types
    MAIN_ARTIST : 'mainArtist',
    FEATURE : 'feature',
    COMPOSER: "composer",
    PRODUCER: "producer",
    WRITER: "writer",
    PAYOUT: "payout"
  }
  
  export const ARTIST_FORM_INPUT_TYPES = { //Artist Profile form input types
    NAME: "Name",
    FULL_NAME: "Full Name",
    ADDRESS: "Address",
    EMAIL: "Email",
    PROFILE_PICTURE: "profile picture",
    ID: "ID",
    NFT_STORAGE_API_KEY: "NFT.Storage API Key",
    SUBSCRIPTION_FEE: "Subscription Fee",
    PAYOUT_PERCENTAGE: "Payout Percentage",
    PAYMENT_POINTER: "Payment Pointer",
    BIO: "Bio",
    DONATION_ADDRESS: "Donation Address",
    DONATION_CAUSE: "Donation Cause",
    DONATION_TARGET: "Donation Target",
  
  }

  export const PODCASTER_FORM_INPUT_TYPES = { //Artist Profile form input types
    NAME: "Name",
    FULL_NAME: "Full Name",
    ADDRESS: "Address",
    EMAIL: "Email",
    PROFILE_PICTURE: "profile picture",
    ID: "ID",
    NFT_STORAGE_API_KEY: "NFT.Storage API Key",
    SUBSCRIPTION_FEE: "Subscription Fee",
    PAYOUT_PERCENTAGE: "Payout Percentage",
    PAYMENT_POINTER: "Payment Pointer",
    BIO: "Bio"
  
  }

  export const LABEL_FORM_INPUT_TYPES = { //Artist Profile form input types
    NAME: "Name",
    ADDRESS: "Address",
    EMAIL: "Email",
    PROFILE_PICTURE: "profile picture",
    ID: "ID",
    NFT_STORAGE_API_KEY: "NFT.Storage API Key",
    SUBSCRIPTION_FEE: "Subscription Fee",
    PAYOUT_PERCENTAGE: "Payout Percentage",
    PAYMENT_POINTERS: "Payment Pointer",
    BIO: "Bio"
  
  }

  export const ARTIST_SEARCH_TYPES = { //Artist search types 
    ADDRESS: "Address",
    ID: "ID",
    NAME: "Name",
    FULL_NAME: "Full Name"
}

  export const PODCASTER_SEARCH_TYPES = { //Podcaster search types 
    ADDRESS: "Address",
    ID: "ID",
    NAME: "Name",
}

export const MUSIC_PLAYER_ACTIONS = { //Actions for music player
  SET_PLAYLIST: "Set Playlist",
  UPDATE_PLAYLIST: "Update Playlist",
  PLAY_NEXT: "Play Next",
  SET_CURRENT_SONG: "Set Current Song",
  TOGGLE_PLAY: "Toggle Play",
  TOGGLE_SHUFFLE: "Toggle Shuffle",
  TOGGLE_REPEAT: "Toggle Repeat",
  TOGGLE_MUTE: "Toggle Mute",
  VOLUME_CHANGE: "Volume Change",
  UPDATE_PLAY_TYPE: "Update Play Type",
  UPDATE_PLAY_ID: "Update Play ID"
}

export const VIDEO_PLAYER_ACTIONS = { //Actions for video player
  SET_PLAYLIST: "Set Playlist",
  //UPDATE_PLAYLIST: "Update Playlist",
  //PLAY_NEXT: "Play Next",
  SET_CURRENT_VIDEO: "Set Current Video",
  TOGGLE_PLAY: "Toggle Play",
  TOGGLE_SHUFFLE: "Toggle Shuffle",
  //TOGGLE_REPEAT: "Toggle Repeat",
  TOGGLE_MUTE: "Toggle Mute",
  VOLUME_CHANGE: "Volume Change",
  TOGGLE_FULL_SCREEN_MODE: "Toggle Full Screen Mode",
  TOGGLE_NORMAL_SCREEN_MODE: "Toggle Normal Screen Mode",
  TOGGLE_PIP_MODE: "Toggle PIP Mode",
  TOGGLE_THEATRE_MODE: "Toggle Theatre Mode",
  TOGGLE_PLAYBACK_SPEED: "Toggle Playback Speed",
  UPDATE_PLAY_TYPE: "Update Play Type",
  UPDATE_PLAY_ID: "Update Play ID"
}

export const VIDEO_SCREEN_SIZE = {
  FULL_SCREEN: 'full screen',
  PIP: 'PIP',
  THEATRE: 'theatre mode',
  NORMAL: 'normal'
}

export const MUSIC_PLAYING_FROM = {
  COLLECTION: 'Collection',
  SONG: 'Song',
  ALBUM: 'Album',
  MUSIC_STORE: 'Music Store',
  PLAYLIST: 'Playlist',
  NFT: 'NFT',
  RECOMMENDATION: 'Recommendation',
  PURCHASE: 'Purchase',
  ARTIST: 'Artist',
  PODCASTER: 'Podcaster',
  EPISODE: 'Episode'
}

export const MUSIC_PLAYING_FROM_STATS_COLORS = {
  Collection: '#D0D0D0',
  Song: '#1D1E33',
  Album: '#9D9101',
  Music_Store: '#E4A010',
  Playlist: '#6D3F5B',
  NFT: '#063971',
  Recommendation: '#EAE6CA',
  Purchase: '#35682D',
  Artist: '#CB2821',
  Podcaster: '#755C48',
  Episode: '#23282B'
}

export const MUSIC_PURCHASE_OPTIONS = {
  DOWNLOAD: "Music Download",
  DIGITAL_ALBUM_PURCHASE: "Digital Album Purchase",
  DIGITAL_SONG_PURCHASE: "Digital Song Purchase",
  NFT_PURCHASE: "NFT Purchase",
  GIFT_Purchase: "Gift Purchase",
  FREE_MINT: "Free Mint"
  
}

export const PAYMENT_WALLETS = {
  COMMENTS: "rHiFB5atodVeRy2eihaCaoeVasf3ZLAhR6",
  NFT_PURCHASE: "rpayuRm38Dkie3zchd9BXhX5uQ5HKY392J",
  DIGITAL_PURCHASE: "rpAyMWT4GSDdLuhVDnn3cbRz1ZHTbuPR4W",
  SUBSCRIPTION_MICROPAYMENTS: "rajo5fChv8E13KVkQ8wttdrZd87cnsonar",
  NFTOKEN_MINTER: "rJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ"
}


export const TRANSACTION_OPTIONS = {
  SIGN_UP: "Sign Up", // Sign up for a Sonar Muse Account
  SIGN_IN: "Sign In", //Sign in to the platform
  DIGITAL_ALBUM_PURCHASE: "Digital Album Purchase", //Digital Album Purchase
  DIGITAL_SONG_PURCHASE: "Digital Song Purchase", //Digital Song Purchase
  NFTOKEN_MINT: "NFToken Mint", //Artist NFToken Mint from collection
  NFTOKEN_BURN: "NFToken Burn",
  NFTOKEN_TRANSFER: "NFToken Transfer",
  NFTOKEN_PURCHASE: "NFToken Purchase", //User NFToken Purchase from collection
  NFTOKEN_OFFER_ACCEPT: "NFToken Offers Accept", // User NFToken Accept from purchase made from collection
  NFTOKEN_OFFERS_ACCEPT: "NFToken Offer Accept", // User NFToken Accept from purchase made from collection
  CREATE_NFTOKEN_COLLECTION: "Create NFToken Collection", // Artist NFToken Create
  NFTOKEN_GIFT: "NFToken Gift",
  GIFT_MUSIC_ROYALTY: "Music Royalty Gift",
  CLAIM_MUSIC_ROYALTY_RESERVES: "Music Royalty Reserve Claim",
  NFTOKEN_DISCOUNT: "NFToken Discount",
  TIP_USER: "Tip", //Tip a user account
  SUBSCRIBE_TO_ARTIST: "Subscribe to Artist",//Subscribe to an Artist
  COMMENT: "Comment",//Comment on a song, album, NFToken Collection
  MUSIC_UPLOAD: {
   SONG: "song upload",
   ALBUM: "album upload",
   EPISODE: "episode upload"
  }, //Music upload to the platform
  UPDATE_USER_PROFILE: "Update User Profile", //Update user profile information
  UPDATE_ARTIST_PROFILE: "Update Artist Profile", //Update artist profile information
  UPDATE_ARTIST_STORAGE_TOKEN: "Update Artist Storage Token", //Update artist profile information
  UPDATE_PODCASTER_PROFILE: "Update Podcaster Profile", //Update artist profile information
  UPDATE_LABEL_PROFILE: "Update Label Profile", //Update label profile information
  UPDATE_PODCASTER_STORAGE_TOKEN: "Update Podcaster Storage Token", //Update podcatser profile information
  UPDATE_SONG: "Update Song", //Update song information
  UPDATE_EPISODE: "Update Episode", //Update song information
  UPDATE_ALBUM: "Update Abum", //Update album information
  UPDATE_PLAYLIST: "Update Playlist", //Update playlist information
  UPDATE_COLLECTION: "Update Collection", //Update Collection
  UPDATE_ROYALTY_SHARE: "Update Royalty Share",
  DELETE_COLLECTION: "Delete Collection",
  DEACTIVATE_USER_ACCOUNT: "Deactivate User Account", //Deactibvate user account
  DEACTIVATE_ARTIST_ACCOUNT: "Deactivate Artist Account",//Deactivate artist account
  DEACTIVATE_PODCASTER_ACCOUNT: "Deactivate Podcaster Account",//Deactivate artist account
  ENABLE_PUSH_NOTIFICATIONS: "Enable Push Notifications",
  DISABLE_PUSH_NOTIFICATIONS: "Disable Push Notifications",
  ENABLE_WEB_MONETIZATION: "Enable Web Monetization",
  AUTHORIZE_MONETIZATION_CLAIM: "Authorize Web Monetization Funds Claim",
  AUTHORIZE_MONETIZATION_DISABLE: "Authorize Web Monetization Funds Disable",
  CLAIM_MONETIZATION_FUNDS: "Claim Web Monetization Funds",
  DISABLE_WEB_MONETIZATION: "Disable Web Monetization",
  PAY_STREAMING_SUBSCRIPTION: "Pay Streaming Subscription",
  TOP_UP_STREAMING_SUBSCRIPTION: "Top Up Streaming Subscription",
  REDEEM_SWERL_VOUCHER: "Redeem Swerl Voucher",
  PURCHASE_SWERL_VOUCHER: "Purchase Swerl Voucher",
  SWERL_VOUCHER_OFFERS_ACCEPT: "Swerl Voucher Offers Accept",
  COLLECTION_WHITELIST: 'Collection Whitelist',
  GET_MUSIC_LIBRARY: 'Get Music Library',
  GET_ACCOUNT_INFO: "Get Account Info",
  CHANGE_ARTIST_LABEL_PODCASTER_ADDRESS: 'Change Artist/Label/Podcaster Address',
  ARTIST_PROFILE_CHECKER: 'Artist Profile Checker',
  ARTIST_REGISTRATION: 'Artist Registration',
  LABEL_REGISTRATION: 'Label Registration',
  PODCASTER_REGISTRATION: 'Podcaster Registration',
  MARKETING_CAMPAIGN: 'Marketing Campaign',
  PURCHASE_MUSIC_ROYALTY: "Purchase Music Royalty"
}

export const SONAR_MUSE_NFT_STORAGE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDY4NzkyZUM5QmZGOEY1NDlhMjI4RkI2NDE3M0E4MmM4ODkwRjkyOGMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2NDcwNjYxNTAzNiwibmFtZSI6InNvbmFyIG11c2UifQ.Lp6tPAyn7KdUwGMswuveOYVyp01lEZCUbxPwbXq0mZ0"

export const MINTING = {
  FLAG: {
    BURNABLE: "tfBurnable",
    TRANSFERABLE: "tfTransferable",
    ONLY_XRP: "tfOnlyXRP",
  },
  FLAG_DESCRIPTION: {
    BURNABLE:
      "Allow the issuer (or an entity authorized by the issuer) to destroy the minted NFToken from this collection. (The NFToken's owner can always do so.)",
    TRANSFERABLE:
      "The minted NFToken from this collection can be transferred to others. If this flag is not enabled, the token can still be transferred from or to the issuer.",
    ONLY_XRP: "The minted NFToken from this collection can only be bought or sold for XRP.",
  },
  TYPE: {
    CUSTOM: "Custom",
    ALBUM: "Album",
    SINGLE: "Single",
  },
  ADDRESS: "rJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ"
};


export const MEDIA_SCREEN = { //Screen sizes for devices
  MOBILE: '480',
  TABLETS: '768',
  LAPTOPS: '1024',
  DESKTOPS: '1025',
  TV: '1500'
}

export const CURRENCIES = {
  XRP: 'XRP',
  WYM: 'WYM', 
  BTC: 'BTC',
  ETH: 'ETH',
  ZAR: "ZAR",
  USD: 'USD',
  GBP: 'GBP',
  USDC: 'USDC',
  EUR: 'EUR',
  AUD: 'AUD',
  JPY: 'JPY',
  CHF: 'CHF'
}

export const CURRENCIES_STATS_COLORS = {
  XRP: '#0E294B',
  WYM: '#5D9B9B', 
  BTC: '#9E9764',
  ETH: '#CDA434',
  ZAR: "#35682D",
  USD: '#E6D690',
  GBP: '#F44611',
  USDC: '#763C28',
  EUR: '#282828',
  AUD: ' #A52019',
  JPY: '#6D3F5B',
  CHF: '4E3B31'
}

export const PAYOUT_TYPE = {
  ARTIST: "aId",
  PODCASTER: "pId",
  RECORD_LABEL: "recId",
  USER: "uid",
  ROYALTY_SHARE: 'rsh'
}

export const CAMPAIGN_TYPE = {
  ARTIST: "Artist",
  PODCASTER: "Podcaster",
  RECORD_LABEL: "Label",
  ALBUM: "Album",
  EPISODE: "Episode",
  SINGLE: "Single",
  COLLECTION: "Collection",
  REGIUM_TOKEN: "Regium Token",
  PLAYLIST: "Playlist"
}

export const SEARCH_TYPE = {
  
}

export const VIDEO_RES = {
  P_4320: 4320,
  P_2160: 2160,
  P_1440: 1440,
  P_1080: 1080,
  P_720: 720,
  P_480: 480,
  P_360: 360,
  P_240: 240,
}

export const AUDIO_EXPORT_TYPES = {
  MP3: 'MP3',
  FLAC: 'FLAC',
  AAC:'AAC',
  OGG: "OGG Vorbis",
  WAV: "WAV",
  AIFF: "AIFF"
}

