import React, { useEffect, useState } from "react";
import useMusic from "../../hooks/useMusic";
import CommentForm from "../CommentForm";
import CommentList from "../ContentList/CommentList";
import { FormTextArea, FormWrapper } from "../CustomForm/CustomFormElements";
import LoadingScreen from "../LoadingScreen";
import { ContentListSkeleton } from "../Skeleton";
import { Slider } from "../Slider";
import  useComments from "./CommentsContext";

const CommentsSection = ({ type, id, contentName }) => {
  const { comments, setComments, isLoaded, setLoaded, getComments} = useComments({type: type, id: id});
  



  return (
    <>
    <CommentForm contentId={id} type={type} contentName={contentName} isLoaded={isLoaded} getComments={getComments}/>
      {comments?.length === 0 && 
          
              <Slider/>
      }
      {comments?.length > 0 && isLoaded && 
      
        <CommentList comments={comments} />
      
      }
      {!isLoaded && <ContentListSkeleton />}
    </>
  );
};

export default CommentsSection;
