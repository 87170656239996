import React from 'react'
import { FaCreativeCommons, FaCreativeCommonsBy, FaCreativeCommonsNc, FaCreativeCommonsNd, FaCreativeCommonsSa, FaCreativeCommonsZero, FaRegCopyright } from "react-icons/fa";
import { Link } from 'react-router-dom';
import QRCode from 'qrcode'
import styled from 'styled-components';
import axiosInstance from "../components/Authorization/client";
import { CCFormWrapper, CCIconsWrapper, CCInfoIcon } from "../components/CreativeCommonsSelector/CreativeCommonsSelectorElements";
import { Data } from "../components/CreativeCommonsSelector/Data";
import ScrollToTop from '../components/ScrollToTop';
import { ARTIST_SEARCH_TYPES, MUSIC_PLAYING_FROM, PODCASTER_SEARCH_TYPES } from "./Enum"
import { isValidAddress, isValidClassicAddress } from 'xrpl';
const axios = axiosInstance



export const IlpRegex = '(?=^.{1,1023}$)^(g|private|example|peer|self|test[1-3]?|local)([.][a-zA-Z0-9_~-]+)+$'
export const isrcReg = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
export const xrpAddressRegex = /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/;
export const floatRegex = /^[+-]?((\.\d+)|(\d+(\.\d+)?)|(\d+\.))$/

export function isNumber(value) {
    //console.log(value)
    return value.match(/^[-+]?[0-9]+(\.[0-9]+)*$/)
  }

export const ArtistLink = styled(Link)`
    text-decoration: none;
    white-space: nowrap;
    color: ${props => props.theme.secondaryColor};

`

 export const getArtistsNames = (artists, type) => {
    //const {goTo} = ScrollToTop()
    switch (type) {
        case ARTIST_SEARCH_TYPES.NAME:
            if(artists && artists?.length > 2){            
                return <>{artists.map((artist, index) => {
                    if(index < artists.length - 2)
                    {
                    
                            return <><ArtistLink onClick={() => {} } to={`/artist/${artist.artistId}`}>{`${artist.artistName}`} </ArtistLink>{` ○ `}</>
                    
                    }
                    if(index === artists.length - 2)
                    {
                            return <> <ArtistLink onClick={() => {} } to={`/artist/${artist.artistId}`}>{`${artist.artistName}`} </ArtistLink>{` ○ `}</>
                    }
                    if(index === artists.length - 1)
                    {
                        return <> <ArtistLink onClick={() => {} } to={`/artist/${artist.artistId}`}>{`${artist.artistName}`} </ArtistLink></>
                    }
                })}</>
            
            
            }
            if(artists?.length === 1){
               return <><ArtistLink onClick={() => {} } to={`/artist/${artists[0].artistId}`}>{` ${artists[0].artistName} `} </ArtistLink></>
            }
            if (artists?.length === 2 ) 
            {
                return <><ArtistLink onClick={() => {} } to={`/artist/${artists[0].artistId}`}>{` ${artists[0].artistName} `}</ArtistLink>{` & `}<ArtistLink onClick={() => {}} to={`/artist/${artists[1].artistId}`}>{` ${artists[1].artistName} `}</ArtistLink></>
            }
            else{
                return
            }
           
        case ARTIST_SEARCH_TYPES.ID:
                return 
        case ARTIST_SEARCH_TYPES.FULL_NAME:
            return 
        case ARTIST_SEARCH_TYPES.ADDRESS:
                return 

        default:
            break;
    }
}

 export const getArtistsNames2 = (artists, type) => {
    switch (type) {
        case ARTIST_SEARCH_TYPES.NAME:
            if(artists?.length > 2){
            return artists.map((artist, index)  => {
                if(index < artists.length - 2){
                    return artist.artistName + ` , `
                }
                if(index === artists.length - 2)
                {
                        return artist.artistName + ' & '
                }
                if(index === artists.length - 1)
                {
                    return artist.artistName
                }
            
            }).join(' ')
                 
            
           
            }
            if(artists?.length === 1){
               return artists[0].artistName
            }
            if (artists?.length === 2 ) 
            {
                return `${artists[0].artistName} & ${artists[1].artistName}`
            }
            else{
                return
            }
           
        case ARTIST_SEARCH_TYPES.ID:
                return 
        case ARTIST_SEARCH_TYPES.FULL_NAME:
            return 
        case ARTIST_SEARCH_TYPES.ADDRESS:
                return 

        default:
            break;
    }
}

 export const getPodcastersNames = (podcasters, type) => {
    //const {goTo} = ScrollToTop()
    switch (type) {
        case PODCASTER_SEARCH_TYPES.NAME:
            if(podcasters && podcasters?.length > 2){            
                return <>{podcasters.map((podcaster, index) => {
                    if(index < podcasters.length - 2)
                    {
                    
                            return <><ArtistLink onClick={() => {} } to={`/podcaster/${podcaster.podcasterId}`}>{`${podcaster.podcasterName} `} </ArtistLink>{` ○ `}</>
                    
                    }
                    if(index === podcasters.length - 2)
                    {
                            return <> <ArtistLink onClick={() => {} } to={`/podcaster/${podcaster.podcasterId}`}>{`${podcaster.podcasterName} `} </ArtistLink>{` ○ `}</>
                    }
                    if(index === podcasters.length - 1)
                    {
                        return <> <ArtistLink onClick={() => {} } to={`/podcaster/${podcaster.podcasterId}`}>{`${podcaster.podcasterName} `} </ArtistLink></>
                    }
                })}</>
            
            
            }
            if(podcasters?.length === 1){
               return <><ArtistLink onClick={() => {} } to={`/podcaster/${podcasters[0].podcasterId}`}>{` ${podcasters[0].podcasterName} `} </ArtistLink></>
            }
            if (podcasters?.length === 2 ) 
            {
                return <><ArtistLink onClick={() => {} } to={`/podcaster/${podcasters[0].podcasterId}`}>{` ${podcasters[0].podcasterName} `}</ArtistLink>{` & `}<ArtistLink onClick={() => {}} to={`/podcaster/${podcasters[1].podcasterId}`}>{` ${podcasters[1].podcasterName} `}</ArtistLink></>
            }
            else{
                return
            }
           
        case PODCASTER_SEARCH_TYPES.ID:
                return 
        case PODCASTER_SEARCH_TYPES.FULL_NAME:
            return 
        case PODCASTER_SEARCH_TYPES.ADDRESS:
                return 

        default:
            break;
    }
}

 export const getPodcastersNames2 = (podcasters, type) => {
    switch (type) {
        case PODCASTER_SEARCH_TYPES.NAME:
            if(podcasters?.length > 2){
            return podcasters.map((podcaster, index)  => {
                if(index < podcasters.length - 2){
                    return podcaster.podcasterName + ` , `
                }
                if(index === podcasters.length - 2)
                {
                        return podcaster.podcasterName + ' & '
                }
                if(index === podcasters.length - 1)
                {
                    return podcaster.podcasterName
                }
            
            }).join(' ')
                 
            
           
            }
            if(podcasters?.length === 1){
               return podcasters[0].podcasterName
            }
            if (podcasters?.length === 2 ) 
            {
                return `${podcasters[0].podcasterName} & ${podcasters[1].podcasterName}`
            }
            else{
                return
            }
           
        case PODCASTER_SEARCH_TYPES.ID:
                return 
        case PODCASTER_SEARCH_TYPES.FULL_NAME:
            return 
        case PODCASTER_SEARCH_TYPES.ADDRESS:
                return 

        default:
            break;
    }
}

export const getSongTitle = (song) => {
    
    if(song?.featuredArtists && song?.featuredArtists?.length > 0){
        
    return `${song.songName} (feat. ${getArtistsNames2(song.featuredArtists, ARTIST_SEARCH_TYPES.NAME)})`
    }
    return song.songName
}

export const filterLikes = (likes) => {
         
    //console.log(likes)
    if(likes){
    let cLikes = likes?.map(likes => {
        if(likes?.songId){
            //console.log(likes.songId)
        return likes.songId
    }})
    return cLikes
}else{
    return []
}
}

export const getCCInfo = (id) => { //returns Creative Commons Information
    let cc = Data.filter(data => data.id === id)
    return (
        <>
            <CCFormWrapper >{displayIcons(cc[0]?.icons, cc[0]?.id)}<>{cc[0]?.name}</></CCFormWrapper>
        </>
    )
}

export const  displayIcons = (icons, id) => { // return Creative Commons icons
    return (
    <CCIconsWrapper>
        {icons?.map(icon => {
            let i 
        switch(icon){
            case "attribute":
               i = <><FaCreativeCommons/><FaCreativeCommonsBy/></>
               break
            case "shareAlike":
                i = <FaCreativeCommonsSa/>
                break
            case "nonCommercial":
                i = <FaCreativeCommonsNc/>
                break
            case "nonDerivatives":
                i = <FaCreativeCommonsNd/>
                break
            case "zero":
                i = <><FaCreativeCommons/><FaCreativeCommonsZero/></>
                break
            case "copyright":
                i = <FaRegCopyright/>
                break
            default:
                i = <FaRegCopyright/>
                break
        }
        return i
    })}{id !== "ARR" && <CCInfoIcon />}
    </CCIconsWrapper>)
    }

export const getObjectLength = (myObj) => {
    if(myObj){
        return Object?.keys(myObj)?.length  
    }
    return 0
    
}

export const validateAddress = (address) => {
    if(isValidAddress(address)){
        return true
    }else{
        return false
    }
}

export const stringFormatter = (s) => {
    if(s?.length > 0){
           return s.replaceAll("////", "\n");
 
    }
    return
}

export function toUnicode(str) {
	return str.split('').map(function (value, index, array) {
        //console.log(value)
		var temp = value.charCodeAt(0).toString(16).toUpperCase();
		if (temp.length > 2) {
			return '\\u' + temp;
		}
		return value;
	}).join('');
}

export function fromUnicode(str) {
	return str.split(' ').map(function (value, index, array) {
        //console.log(value)
        //console.log(temp)
		return unicodeToChar(value)
	}).join(' ');
}
export function unicodeToChar(text) {
    return text.replace(/\\u[\dA-F]{4}/gi, 
           function (match) {

                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
           });
 }

export const generateQRCode = async(text) => {
    try {
        return await QRCode.toDataURL(text,{ 
            errorCorrectionLevel: 'H', 
        quality: 0.8 })
      } catch (err) {
        console.error(err)
        return null
      }
}

export const strToHex = (str) => { //covert a string to hex for the transaction Memos
    var arr1 = [];
	for (var n = 0, l = str.length; n < l; n ++) 
     {
		var hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	 }
	return arr1.join('');
}

export const hexToStr = (hexStr) => { //covert a hex to str for the transaction Memos
    var hex  = hexStr.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;

}

export function sha256(ascii) {
    function rightRotate(value, amount) {
        return (value>>>amount) | (value<<(32 - amount));
    };
    
    var mathPow = Math.pow;
    var maxWord = mathPow(2, 32);
    var lengthProperty = 'length'
    var i, j; // Used as a counter across the whole file
    var result = ''
  
    var words = [];
    var asciiBitLength = ascii[lengthProperty]*8;
    
    //* caching results is optional - remove/add slash from front of this line to toggle
    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
    // (we actually calculate the first 64, but extra values are just ignored)
    var hash = sha256.h = sha256.h || [];
    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
    var k = sha256.k = sha256.k || [];
    var primeCounter = k[lengthProperty];
    /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/
  
    var isComposite = {};
    for (var candidate = 2; primeCounter < 64; candidate++) {
        if (!isComposite[candidate]) {
            for (i = 0; i < 313; i += candidate) {
                isComposite[i] = candidate;
            }
            hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
            k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
        }
    }
    
    ascii += '\x80' // Append Ƈ' bit (plus zero padding)
    while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
    for (i = 0; i < ascii[lengthProperty]; i++) {
        j = ascii.charCodeAt(i);
        if (j>>8) return; // ASCII check: only accept characters in range 0-255
        words[i>>2] |= j << ((3 - i)%4)*8;
    }
    words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
    words[words[lengthProperty]] = (asciiBitLength)
    
    // process each chunk
    for (j = 0; j < words[lengthProperty];) {
        var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
        var oldHash = hash;
        // This is now the undefinedworking hash", often labelled as variables a...g
        // (we have to truncate as well, otherwise extra entries at the end accumulate
        hash = hash.slice(0, 8);
        
        for (i = 0; i < 64; i++) {
            var i2 = i + j;
            // Expand the message into 64 words
            // Used below if 
            var w15 = w[i - 15], w2 = w[i - 2];
  
            // Iterate
            var a = hash[0], e = hash[4];
            var temp1 = hash[7]
                + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                + ((e&hash[5])^((~e)&hash[6])) // ch
                + k[i]
                // Expand the message schedule if needed
                + (w[i] = (i < 16) ? w[i] : (
                        w[i - 16]
                        + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                        + w[i - 7]
                        + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
                    )|0
                );
            // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
            var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj
            
            hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
            hash[4] = (hash[4] + temp1)|0;
        }
        
        for (i = 0; i < 8; i++) {
            hash[i] = (hash[i] + oldHash[i])|0;
        }
    }
    
    for (i = 0; i < 8; i++) {
        for (j = 3; j + 1; j--) {
            var b = (hash[i]>>(j*8))&255;
            result += ((b < 16) ? 0 : '') + b.toString(16);
        }
    }
    return result;
  };


export const dateFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: "medium",
    timeStyle: "short",
})

export const datetoRippleEpoch = (date) => {
    //console.log(Math.floor(new Date(date).getTime() / 1000).toFixed(0) + 946684800)
    return parseInt((Math.floor(new Date(date).getTime() / 1000).toFixed(0) + 946684800).slice(0, 10))
}

export const newlineFormatter = (s) => {
    return s.replaceAll("\n" , "////");
}

export const containsObject = (obj, list) => {
    //console.log(obj)
    for (let i = 0; i <= list.length-1; i++) {
        //console.log(`comparison ${i+1}:`)
        //console.log(JSON.stringify(list[i]))
        //console.log(JSON.stringify(obj))
        
        if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
            return true;
        }
    }
  
    return false;
  }

export const getAllArtists = (song) => {
    let artists = []
    if(song?.artists){
        if(song?.featuredArtists){
        
        artists.push(...song.artists, ...song.featuredArtists)
    }else{
        artists.push(...song.artists)
    }}else if(song?.mainArtists){
        if(song?.featuredArtists){
        
            artists.push(...song.mainArtists, ...song.featuredArtists)
        }else{
            artists.push(...song.mainArtists)
        }
    }
    return artists
} 

export const getPaymentPointer = async(aId) => {
    return await axios.get(`/api/${aId}/getPaymentPointer`)
            .then((data) => {
                if(data.data.message || data.data.error || data.data === false  )
                {
                    return false
                }
                return data.data
            })
            .catch(err => {
                return false
            }) 

}

export const uploadSongsToIPFS = async(aId) => {
    return await axios.get(`/api/songs/setSongsAsMintable`)
            .then((data) => {
                if(data.data.message || data.data.error || data.data === false  )
                {
                    return false
                }
                return data.data
            })
            .catch(err => {
                return false
            }) 

}

export function getUniqueListBy(arr, key) {
    return arr.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === arr.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });
}

export function validateImageSize(file){
    var reader = new FileReader();

//Read the contents of Image File.
reader.readAsDataURL(file);
reader.onload = function (e) {

  //Initiate the JavaScript Image object.
  var image = new Image();

  //Set the Base64 string return from FileReader as source.
  image.src = e.target.result;

  //Validate the File Height and Width.
  image.onload = function () {
    var height = this.height;
    var width = this.width;
    //console.log(`width: ${width} - height: ${height} `)
    if (height < 1600 || width < 1600) {
        alert("Height and Width must not be less than 1600px.");
      return false;
    }
    return true;
  };
};
}

export const downloadFile = (file) => {
    window.open(file, "_blank", "noopener, noreferrer")
}


export function convertDuration(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    
    let duration
    if(hours !== '00'){
       duration = hours+':'+minutes+':'+seconds;// Return is HH : MM : SS
    }else if(hours === '00'){
        duration = minutes+':'+seconds; // Return is MM : SS
    }
    return  duration 
}

export function convertDurationToString(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    
    let duration
    if(hours !== '00'){
       duration = `${hours} hour ${minutes !== "00" ? `${minutes} min`: ''} ${seconds !== "00" ? `${seconds} sec`: ''}`;// Return is HH : MM : SS
    }else if(hours === '00'){
        duration = `${minutes !== "00" ? `${minutes} min`: ''} ${seconds !== "00" ? `${seconds} sec`: ''}` // Return is MM : SS
    }
    return  duration 
}


export function convertTimeToSeconds(time){
    var hms = time;   // your input string
    var a = hms.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 

    //console.log(seconds);
    return seconds
}

export function getExpirationTimestamp(date1) {
    let date = new Date()
    let diff = date1.getTime() - date.getTime();
    let seconds = Math.floor(diff / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours   = Math.floor(minutes / 60)
    let days    = Math.floor(hours / 24)
    let months  = Math.floor(days / 30)
    let years   = Math.floor(days / 365)

    seconds %= 60;
    minutes %= 60;
    hours %= 24;
    days %= 30;
    months %= 12;
    // add 0 if value < 10; Example: 2 => 02
/*     if (months   < 10) {days   = "0"+months;}
 */    if (days   < 10) {days = "0"+days;}
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (months < 10) {months = "0"+months;}
    if (years < 10) {years = "0"+years;}
     if(parseInt(years) > 1){
        return `${years} years left`
    }
    if(parseInt(years) === 1){
        return `${years} year left`
    }
     if(parseInt(months) > 1){
        return `${months} months left`
    }
    if(parseInt(months) === 1){
        return `${months} month left`
    }
    if(parseInt(days) > 1){
        return `${days} days left`
    }
    if(parseInt(days) === 1){
        return `${days} day left`
    }
    if(parseInt(hours) > 1){
        return `${hours} hours left`
    }
    if(parseInt(hours) === 1){
        return `${hours} hour left`
    }
    if(parseInt(minutes) > 1){
        return `${minutes} minutes left`
    }
    if(parseInt(minutes) === 1){
        return `${minutes} minute left`
    }
    if(parseInt(seconds) > 1){
        return `${seconds} seconds left`
    }
    if(parseInt(seconds) === 1){
        return `${seconds} second left`
    }
}
export function getTimestamp(date1) {
    let date = new Date()
    let diff =  date.getTime() - date1.getTime();
    let seconds = Math.floor(diff / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours   = Math.floor(minutes / 60)
    let days    = Math.floor(hours / 24)
    let months  = Math.floor(days / 30)
    let years   = Math.floor(days / 365)

    seconds %= 60;
    minutes %= 60;
    hours %= 24;
    days %= 30;
    months %= 12;
    // add 0 if value < 10; Example: 2 => 02
/*     if (months   < 10) {days   = "0"+months;}
 */    if (days   < 10) {days = "0"+days;}
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (months < 10) {months = "0"+months;}
    if (years < 10) {years = "0"+years;}
     if(parseInt(years) > 1){
        return `${years} years ago`
    }
    if(parseInt(years) === 1){
        return `${years} year ago`
    }
     if(parseInt(months) > 1){
        return `${months} months ago`
    }
    if(parseInt(months) === 1){
        return `${months} month ago`
    }
    if(parseInt(days) > 1){
        return `${days} days ago`
    }
    if(parseInt(days) === 1){
        return `${days} day ago`
    }
    if(parseInt(hours) > 1){
        return `${hours} hours ago`
    }
    if(parseInt(hours) === 1){
        return `${hours} hour ago`
    }
    if(parseInt(minutes) > 1){
        return `${minutes} minutes ago`
    }
    if(parseInt(minutes) === 1){
        return `${minutes} minute ago`
    }
    if(parseInt(seconds) > 1){
        return `${seconds} seconds ago`
    }
    if(parseInt(seconds) === 1){
        return `${seconds} second ago`
    }
}


export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  export const timestampToDate = (timestamp) => {
    if(timestamp?._seconds){
       return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds/1000000).toISOString()
   
    }
    if(timestamp?.seconds){
       return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds/1000000).toISOString()
   
    }
    return timestamp
  }


  export const getAlbumCredits = (songs, type) => {
    let credits = []
    switch (type) {
      case "composers":
        songs.filter(s => s?.songCredits?.composers?.length > 0).map(s => {
          credits.push(...s?.songCredits?.composers)
           return 
        })
        return getUniqueListBy(credits, 'artistId')

      case "writers":
        songs.filter(s => s?.songCredits?.writers?.length > 0).map(s => {
          credits.push(...s?.songCredits?.writers)
           return 
        })
        return getUniqueListBy(credits, 'artistId')
        break;
      case "producers":
        songs.filter(s => s?.songCredits?.producers?.length > 0).map(s => {
          credits.push(...s?.songCredits?.producers)
           return 
        })
        return getUniqueListBy(credits, 'artistId')
        break;
    
      default:
        break;
    }
    
  }


  const sortObject = (object) => {
    return Object.keys(object).sort().reduce(
        (obj, key) => { 
          obj[key] = object[key]; 
          return obj;
        }, 
        {}
      )
  }

  
  export const groupInfoByDate = (object, dateProperty, filter) => {
    let groupedObject = {}
    let keys = []
    let newGroupedObj = {}
    let now = new Date().toISOString()
    switch (filter) {
        case "7days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).toISOString()?.split("T")[0]]: prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]],
                    current
                  ] : [current],
                }),
                {}
              );

              for(let i = 0; i < 6; i++){
                if(!groupedObject[new Date(new Date(now).setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]]){
                  groupedObject[new Date(new Date(now).setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]] = []
                }
              }
              return sortObject(groupedObject)
            break;
        case "14days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).toISOString()?.split("T")[0]]: prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]],
                    current
                  ] : [current],
                }),
                {}
              );
              for(let i = 0; i < 13; i++){
                if(!groupedObject[new Date(new Date(now).setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]]){
                  groupedObject[new Date(new Date(now).setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]] = []
                }
              }
              return sortObject(groupedObject)
            break;
        case "30days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).toISOString()?.split("T")[0]]: prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]],
                    current
                  ] : [current],
                }),
                {}
              );
              for(let i = 0; i < 30; i++){
                if(!groupedObject[new Date(new Date(now)?.setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]]){
                  groupedObject[new Date(new Date().setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]] = []
                }
              }
              return sortObject(groupedObject)
            break;
        case "60days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).toISOString()?.split("T")[0]]: prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).toISOString()?.split("T")[0]],
                    current
                  ] : [current],
                }),
                {}
              );
              for(let i = 0; i < 60; i++){
                if(!groupedObject[new Date(new Date(now).setDate(new Date(now).getDate() - i))?.toISOString()?.split("T")[0]]){
                  groupedObject[new Date(now).toISOString()?.split("T")[0]] = []
                }
              }
              return sortObject(groupedObject)
            break;
        case "180days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).getMonth()]: prev[new Date(current[dateProperty]).getMonth()]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).getMonth()],
                    current
                  ] : [current],
                }),
                {}
              );
              for(let i = 0; i < 6; i++){
                if(!groupedObject[new Date(new Date(now).setMonth(new Date(now).getMonth() - i))?.getMonth()]){
                  groupedObject[new Date(new Date(new Date(now).setMonth(new Date(now).getMonth() - i)))?.getMonth()] = []
                }
              }
              groupedObject = sortObject(groupedObject)
              keys = Object.keys(sortObject(groupedObject))
              newGroupedObj = {}
              keys.map(k => {
                newGroupedObj = {
                    ...newGroupedObj,
                    [new Date(k).toLocaleString('default', { month: 'short' })]: groupedObject[k] 
                }
                return k
              })
              return newGroupedObj
            break;
        case "365days":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).getMonth()]: prev[new Date(current[dateProperty]).getMonth()]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).getMonth()],
                    current
                  ] : [current],
                }),
                {}
              );
              for(let i = 0; i < new Date(now).getMonth() +1; i++){
                if(!groupedObject[new Date(new Date(new Date(now).setMonth(new Date(now).getMonth() - i)))?.getMonth()]){
                  groupedObject[new Date(new Date(new Date(now).setMonth(new Date(now).getMonth() - i)))?.getMonth()] = []
                }
              }
              groupedObject = sortObject(groupedObject)
              keys = Object.keys(sortObject(groupedObject))
              newGroupedObj = {}
              keys.map(k => {
                newGroupedObj = {
                    ...newGroupedObj,
                    [new Date(k).toLocaleString('default', { month: 'short' })]: groupedObject[k] 
                }
                return k
              })
              return newGroupedObj
            break;
        case "AllTime":
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [new Date(current[dateProperty]).getFullYear()]: prev[new Date(current[dateProperty]).getFullYear()]?.length > 0 ? [
                    ...prev[new Date(current[dateProperty]).getFullYear()],
                    current
                  ] : [current],
                }),
                {}
              );
              return sortObject(groupedObject)
        default:
            groupedObject = object.reduce(
                (prev, current) => ({
                  ...prev,
                  [`${new Date(current[dateProperty]).toISOString()?.split("T")[1].split(":")[0]}:00`]: prev[current[dateProperty]?.split("T")[0]]?.length > 0 ? [
                    ...prev[current[dateProperty]?.split("T")[0]],
                    current
                  ] : [current],
                }),
                {}
              ); for(let i = 0; i < 24; i++){
                if(!groupedObject[`${23 - i < 10 ? `0${23-i}`: 23-i}:00`]){
                  groupedObject[`${23 - i < 10 ? `0${23-i}`: 23-i}:00`] = []
                }
              }
              return groupedObject
            break;
    }
  }

  export const getPlayCount =(filteredPlays) => {
    let fullPlays = 0
    let skips = 0
    let partialPlays = 0
    filteredPlays?.filter(play => play?.playType === "full" || play?.repeat > 0)?.map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                fullPlays += (play?.repeat > 0 ? (play?.repeat * 0.5) + 1 : 1)
              }else{
                skips += (play?.repeat > 0 ? (play?.repeat * 0.2) + 0.2 : 0.2)
              }
    })
    filteredPlays?.filter(play => play?.playType === "skip" && !play?.repeat)?.map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                skips += (play?.repeat > 0 ? (play?.repeat * 0.2) + 1 : 0.2)
                return
              }else{
                skips += (play?.repeat > 0 ? (play?.repeat * 0.2) + 0.2 : 0.2)
                return
              }
    })
    
    filteredPlays?.filter(play => play?.playType === "partial" && !play?.repeat)?.map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                partialPlays +=  (play?.repeat > 0 ? (play?.repeat * 0.5) + 1 : 0.5)
                return
              }else{
                skips += (play?.repeat > 0 ? (play?.repeat * 0.2) + 0.2 : 0.2)
                return
              }
    })
    let groupedPlays  = fullPlays + skips + partialPlays
    return Math.round(groupedPlays)
  }

  export const getGroupedPlays = (filteredPlays) => {
    let fullPlays = 0
    let skips = 0
    let partialPlays = 0
    filteredPlays?.filter(play => play?.playType === "full" || play?.repeat > 0).map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                fullPlays += 1
              }else{
                skips += 1
              }
    })
   
    filteredPlays?.filter(play => play?.playType === "skip" && !play?.repeat).map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                skips += 1
                return
              }else{
                skips += 1
                return
              }
    })
    
    filteredPlays?.filter(play => play?.playType === "partial" && !play?.repeat).map(play => {
        if(play?.playingFrom?.playingFrom !== MUSIC_PLAYING_FROM.NFT &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.COLLECTION &&
            play?.playingFrom?.playingFrom !==
              MUSIC_PLAYING_FROM.MUSIC_STORE){
                partialPlays +=  1
                return
              }else{
                skips += 1
                return
              }
    })
    
    return {
      skip: skips,
      partial: partialPlays,
      full: fullPlays,
    }
  }

  export const groupByPlayingFrom = (plays) => {
    let groupedObject = plays.reduce(
      (prev, current) => ({
        ...prev,
        [current?.playingFrom?.playingFrom]: prev[current?.playingFrom?.playingFrom] > 0 ? prev[current?.playingFrom?.playingFrom] + 1 : 1,
      }),
      {}
    )
    return groupedObject
  }

  export const getNumListeners = (listeners)  => {
    let object = {}
    
    Object.keys(listeners).map(k => {
      object = {
        ...object,
        [k]: [...new Set(listeners[k].map(item => item.uid))].length
      }
      return
    })
    return object
  }

  export const groupPurchasesByAmount = (purchases) => {
    let groupedObject = purchases.reduce((prev, current) => ({
      ...prev,
      [current?.amount?.currency ? current?.amount?.currency : "XRP"] : prev[current?.amount?.currency ? current?.amount?.currency : "XRP"] ? parseFloat(prev[current?.amount?.currency ? current?.amount?.currency : "XRP"]) + parseFloat(current?.amount?.value ? current?.amount?.value : current?.amount) : parseFloat(current?.amount?.value ? current?.amount?.value : current?.amount)
    }),{})
    if(groupedObject?.XRP > 0){
      groupedObject = {
        ...groupedObject,
        XRP : parseFloat(groupedObject["XRP"]/1000000).toFixed(3)
      }
    }
    return groupedObject
  }

  export const getEpochData = (payouts) => {
    let newObject = {}
    payouts.map(p => {
      newObject ={
        ...newObject,
        [Object.keys(p)[0].split("T")[0]] : parseFloat(Object.values(p)[0] / 1000000).toFixed(3)
      }
    })
    return sortObject(newObject)
  }

  export const calcRoyaltyPurchaseQuantity = (purchases) => {
    let quantity = 0
    purchases.map(p => {
      quantity += p?.quantity
    })
    return quantity
  }