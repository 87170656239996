import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useMusic from "../../hooks/useMusic";
import { useUser } from "../../hooks/useUser";
import { getObjectLength } from "../../utils";
import {
  ALERT_TYPES,
  AUDIO_EXPORT_TYPES,
  CONTENT_TYPES,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { downloadFiles } from "../../utils/FileUploadsUtils";
import { VideoUtils } from "../../utils/ImageUtils";
import { useAlert } from "../Alert/AlertContext";
import { Button, Button1, CustomButton, CustomButton1 } from "../ButtonElement";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import { Header } from "../Header";
import { VideoBg } from "../HeroSection/HeroElements";
import LoadingScreen from "../LoadingScreen";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import QRCodeForm from "../QRCodeForm";
import ScrollToTop from "../ScrollToTop";
import { Container } from "../SignUp/SignUpElements";
import { Slider } from "../Slider";
import AlbumDownload from "./AlbumDownload";
import CollectionDownload from "./CollectionDownload";
import NFTokenDownload from "./NFTokenDownload";
import SongDownload from "./SongDownload";
import { MintButtonConainer } from "../NFTCollectionCreator/NFTCollectionCreatorElements";
import { getAddressName } from "../../utils/nfTokensUploads";
import {
  FormButton1,
  FormH1,
  FormLabel,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { ContentContainer } from "../ContentWrapper/ContentWrapperElements";
import { Nav, NavContainer, NavLink } from "../NavLink/NavLinkElements";
import { DownloaderButtonConainer } from "./MusicDownloaderElements";
import OptionModal from "../OptionModal";

const MusicDownloader = ({ type, id }) => {
  const { currentUser } = useAuth();
  const { addAlert } = useAlert();
  const { video1 } = VideoUtils();
  const { closeModal, openModal, modalContent } = useModal();
  const {
    getAlbum,
    getSong,
    getNFTokenCollection,
    downloadMusic,
    requestMusicDownload,
  } = useMusic();
  const { getNFTokenDetails, getDigitalDownloads, getNFTokens1 } = useUser({});
  const navigate = useNavigate();
  const { goTo } = ScrollToTop();
  const [music, setMusicInfo] = useState();
  const [cUser, setCUser] = useState(
    currentUser?.uid ? currentUser?.uid : undefined
  );
  const [profileImg, setProfileImg] = useState(
    currentUser?.uid ? currentUser?.photoURL : DEFAULT_IMAGE(300)
  );

  const [isLoaded, setLoaded] = useState(false);
  const [tab, setTab] = useState("all");
  const [isLibraryLoaded, setLibraryLoaded] = useState(false);
  const [library, setLibrary] = useState({});
  const [downloads, setDownloads] = useState([]);
  const [downloadContent, setDownloadContent] = useState();
  const [userName, setUserName] = useState(currentUser?.uid);
  useEffect(() => {
    getMusic();
    return () => {};
  }, [!isLoaded, type, id]);

  useEffect(() => {
    getUserLibrary(cUser);
    getAddressName(cUser).then((data) => {
      if (getObjectLength(data) > 0) {
        setUserName(data?.name);
        setProfileImg(data?.profileUrl + "?s=200&d=mp");
      }
    });
  }, [cUser]);

  const getUserLibrary = async (address) => {
    setLibraryLoaded(false);
    setLibrary({});
    if (address) {
      return await getDigitalDownloads(address)
        .then(async (data) => {
          let digitals = {};
          if (data) {
            digitals = {
              albums:
                data?.albums?.length > 0
                  ? data?.albums.map((a) => {
                      return { ...a, owner: address };
                    })
                  : undefined,
              songs:
                data?.songs?.length > 0
                  ? data?.songs.map((s) => {
                      return { ...s, owner: address };
                    })
                  : undefined,
            };
          }
          await getNFTokens1(address)
      .then(async(data) => {
        if(data?.length > 0){
          //console.log(data)
         let nfTokens = []
        if(data?.length > 0){
          let requests = []
          for(let i = 0; i < data.length; i++){
            requests.push(getNFTokenDetails(data[i]?.tokens[0]?.NFTokenID))

        }
        await Promise.all(requests).then((tokens) => {
          nfTokens = tokens.map((t, index) => {
            if(!t?.error && t){
              return {
                ...t,
                ...data[index],
                nftoken:{
                 ...t.nftoken ,
                NFTokenID: t.nftoken.nft_id,
                Issuer: t.nftoken.issuer,
                URI: t.nftoken.uri,
                Taxon: t.nftoken.nft_taxon,},
                owner: address
              }
            }else{
              return {
                ...data[index],
                owner: address
              }
            }
          }).filter(t => t?.nftoken?.metadata?.mintType)
        }).catch(err => {
          console.error(err)
          nfTokens = data.map(t => {
              return {
                ...t,
                owner: address
              }
            }).filter(t => t?.nftoken?.metadata?.mintType)
           
          })
          setLibrary({
            ...digitals,
            nfTokens: nfTokens,
          });
      }
    } else {
      setLibrary({
        ...digitals,
      });
    }
      })
      setLibraryLoaded(true)
        })
        .catch((err) => {
          console.error(err);
          setLibraryLoaded(true);
          return;
        });
    }
  };

  const onSignInSuccess = (address) => {
    //console.log(address);
    setCUser(address);
    closeModal();
    modalContent();
  };

  const onSignInFail = () => {
    closeModal();
    modalContent();
  };

  const signIn = () => {
    openModal();
    modalContent(
      <QRCodeForm
        heading={"Downloader Sign In"}
        onSuccess={onSignInSuccess}
        onFail={onSignInFail}
        cancelQr={() => {
          closeModal();
          modalContent();
        }}
        type={TRANSACTION_OPTIONS.GET_MUSIC_LIBRARY}
        body={{
          memo: "User is signing transaction to retreive music library",
          sender: cUser,
          instruction: "User is signing transaction to retreive music library",
        }}
      />
    );
  };

  const getMusic = async () => {
    if (type && id) {
      switch (type) {
        case CONTENT_TYPES.ALBUM:
          getAlbum(id)
            .then((data) => {
              //console.log('tokenInfo: ',data)
              if (data && !data.error) {
                setMusicInfo(data);
                setLoaded(true);
              } else {
                navigate(`/home`);
              }
            })
            .catch((err) => {
              navigate(`/home`);
              console.error(err);
            });
          break;
        case CONTENT_TYPES.SONG:
          getSong(id)
            .then((data) => {
              //console.log('tokenInfo: ',data)
              if (data && !data.error) {
                setMusicInfo(data);
                setLoaded(true);
              } else {
                navigate(`/home`);
              }
            })
            .catch((err) => {
              navigate(`/home`);
              console.error(err);
            });
          break;
        case CONTENT_TYPES.COLLECTION:
          getNFTokenCollection(id)
            .then((data) => {
              //console.log('tokenInfo: ',data)
              if (data && !data.error) {
                setMusicInfo(data);
                setLoaded(true);
              } else {
                navigate(`/home`);
              }
            })
            .catch((err) => {
              navigate(`/home`);
              console.error(err);
            });
          break;
        case CONTENT_TYPES.NFTOKEN:
          getNFTokenDetails(id)
            .then((data) => {
              //console.log("tokenInfo: ", data);
              if (data && !data.error) {
                setMusicInfo(data);
                setLoaded(true);
              } else {
                navigate(`/musicDownloader`);
              }
            })
            .catch((err) => {
              navigate(`/musicDownloader`);
              console.error(err);
            });
          break;

        default:
          setLoaded(true);
          break;
      }
    } else {
      setLoaded(true);
    }
  };

  const download = async (music, type, id, audioType) => {
    let content;
    addAlert({
      title: "Starting Download",
      type: ALERT_TYPES.INFO,
      message: `Processing download. Please wait.`,
    });
    if (cUser) {
      //download
      try {
        content = await requestMusicDownload(cUser, type, id, audioType);
        //setDownloadContent(content)
        // //console.log(content);
        if (content?.message) {
          addAlert({
            title: "Processing Download",
            type: ALERT_TYPES.SUCCESS,
            message: `Download Processing You Will Receive a Notification Once The Download is Ready`,
          });
          return;
        }
        if (content?.ref && content?.url) {
          await downloadFiles(content);
          addAlert({
            title: "Album Download",
            type: ALERT_TYPES.SUCCESS,
            message: `Downloading ${type[0].toUpperCase()}${type.substring(1)}`,
          });
          return;
        }
        if(content?.error){
          addAlert({
            title: `Download Error`,
            type: ALERT_TYPES.DANGER,
            message: `Downloading Could Not Be Processed. Try Again Later`,
          })
        }
      } catch (err) {
        addAlert({
          title: "Download Error",
          type: ALERT_TYPES.DANGER,
          message: `Download could not be processed. Try again later`,
        });
        return;
      }
      return;
    }
    addAlert({
      title: "Album Download",
      type: ALERT_TYPES.WARNING,
      message: `You Cannot Download This ${type[0].toUpperCase()}${type.substring(1)}`,
    });
  };

  const downloadFromLibrary = async (content) => {
    let contentId = content?.albumId
      ? content?.albumId
      : content?.songId
      ? content?.songId
      : content?.nftoken?.metadata?.mintType
      ? content?.nftoken?.NFTokenID
      : undefined;
    let contentType = content?.albumId
      ? CONTENT_TYPES.ALBUM
      : content?.songId
      ? CONTENT_TYPES.SONG
      : content?.nftoken?.metadata?.mintType
      ? CONTENT_TYPES.NFTOKEN
      : undefined;

    if (contentId && contentType) {
      download(content, contentType, contentId, AUDIO_EXPORT_TYPES.MP3);
    } else {
      addAlert({
        title: "Music Download",
        type: ALERT_TYPES.DANGER,
        message: `Cannot Process Download`,
      });
    }

    /*if(contentType && contentId){
      addAlert({
        title: "Starting Download",
        type: ALERT_TYPES.INFO,
        message: `Processing download. Please Do Not Close Tab.`,
      });
        content = await requestMusicDownload(cUser,contentType, contentId);
        //console.log(content);
        await downloadFiles(content);
        addAlert({
          title: "Music Download",
          type: ALERT_TYPES.SUCCESS,
          message: `Download in progress`,
        });
    }*/
  };

  return (
    <>
      {!music && isLoaded && (
        <>
          <Header title={"Music Downloader"} />
          <ContentContainer>
            {cUser && (
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignContent: "space-between",
                  marginTop: "2%",
                }}
              >
                <FormH1>
                  {" "}
                  <img
                    onClick={() => goTo(`/u/${cUser}`)}
                    src={profileImg ? profileImg : DEFAULT_IMAGE(200)}
                    alt={cUser}
                    style={{ borderRadius: "50%", cursor: "pointer" }}
                  />{" "}
                </FormH1>
                <FormH1
                  style={{ cursor: "pointer" }}
                  onClick={() => goTo(`/u/${cUser}`)}
                >
                  {userName}
                </FormH1>
              </div>
            )}

            <ContentWrapper>
              {getObjectLength(library) > 0 && (
                <FormWrapper style={{ justifyContent: "center" }}>
                  {library?.nfTokens?.length > 0 && (
                    <FormLabel style={{ justifyContent: "center" }}>
                      Unique Music Collections:
                      {library?.nfTokens?.length}
                    </FormLabel>
                  )}
                  {(library?.albums?.length > 0 ||
                    library?.songs?.length > 0) && (
                    <FormLabel style={{ justifyContent: "center" }}>
                      Owned Digital Music: {library?.albums?.length} Albums |{" "}
                      {library?.songs?.length} Singles
                    </FormLabel>
                  )}
                </FormWrapper>
              )}
              <DownloaderButtonConainer>
                {!cUser && (
                  <CustomButton onClick={signIn}>
                    Sign In With Xaman
                  </CustomButton>
                )}
                {cUser && (
                  <FormButton1
                    onClick={() => {
                      getUserLibrary(cUser);
                      addAlert({
                        title: "Retreiving Music Library",
                        type: ALERT_TYPES.INFO,
                        message: `Retreiving Music Library. Please Wait.`,
                      });
                    }}
                  >
                    Load My Library
                  </FormButton1>
                )}
                {cUser && !currentUser?.uid && (
                  <FormButton1
                    onClick={() => {
                      goTo(`/u/${cUser}`);
                    }}
                  >
                    View My Profile
                  </FormButton1>
                )}

                <FormButton1 onClick={() => goTo("/store")}>
                  Visit Music Store
                </FormButton1>
                {cUser && !currentUser?.uid && (
                  <CustomButton
                    onClick={() => {
                      setLibrary();
                      setCUser();
                    }}
                  >
                    Sign Out
                  </CustomButton>
                )}
              </DownloaderButtonConainer>
            </ContentWrapper>
            {cUser && isLibraryLoaded && getObjectLength(library) > 0 && (
              <>
                {getObjectLength(library) > 2 && (
                  <Nav>
                    <NavContainer>
                      <NavLink
                        active={tab === "all"}
                        onClick={() => setTab("all")}
                      >
                        All
                      </NavLink>
                      <NavLink
                        active={tab === "nft"}
                        onClick={() => setTab("nft")}
                      >
                        Music NFTokens
                      </NavLink>
                      <NavLink
                        active={tab === "digital"}
                        onClick={() => setTab("digital")}
                      >
                        Digital Purchase
                      </NavLink>
                    </NavContainer>
                  </Nav>
                )}
                <ContentWrapper
                  onClick={() => (cUser ? getUserLibrary(cUser) : {})}
                  heading={
                    tab === "nft"
                      ? "My Music NFT Library"
                      : tab === "digital"
                      ? "My Digital Library"
                      : "My Library"
                  }
                  subheading={
                    userName !== cUser
                      ? userName
                      : `${cUser?.substring(0, 8)}...${cUser?.substring(
                          cUser?.length - 4,
                          cUser?.length
                        )}`
                  }
                >
                  {tab === "all" && (
                    <Slider
                      id={"download library"}
                      type={SLIDER_TYPES.DOWNLOAD}
                      onSlideClick={downloadFromLibrary}
                      heightScroll
                      slides={() => {
                        let l = [];
                        if (library?.albums?.length > 0) {
                          l.push(...library?.albums);
                        }
                        if (library?.songs?.length > 0) {
                          l.push(...library?.songs);
                        }
                        if (library?.nfTokens?.length > 0) {
                          l.push(...library?.nfTokens);
                        }
                        return l;
                      }}
                    />
                  )}
                  {tab === "digital" && (
                    <Slider
                      id={"digital download library"}
                      type={SLIDER_TYPES.DOWNLOAD}
                      onSlideClick={downloadFromLibrary}
                      heightScroll
                      slides={() => {
                        let l = [];
                        if (library?.albums?.length > 0) {
                          l.push(...library?.albums);
                        }
                        if (library?.songs?.length > 0) {
                          l.push(...library?.songs);
                          return l;
                        }
                      }}
                    />
                  )}
                  {tab === "nft" && (
                    <Slider
                      id={"nftoken download library"}
                      type={SLIDER_TYPES.DOWNLOAD}
                      onSlideClick={downloadFromLibrary}
                      heightScroll
                      slides={() => {
                        let l = [];
                        if (library?.nfTokens?.length > 0) {
                          l.push(...library?.nfTokens);
                        }
                        return l;
                      }}
                    />
                  )}
                </ContentWrapper>
              </>
            )}
            {cUser && isLibraryLoaded && getObjectLength(library) === 0 && (
              <>
                <ContentWrapper
                  heading={"No Music Library Found"}
                ></ContentWrapper>
              </>
            )}
            {cUser && !isLibraryLoaded && (
              <ContentWrapper heading={"Loading Library..."}>
                <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={"loading"} />
                <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={"loading1"} />
                <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={"loading2"} />
              </ContentWrapper>
            )}
          </ContentContainer>
        </>
      )}
      {music && isLoaded && (
        <>
          {type === CONTENT_TYPES.ALBUM && (
            <AlbumDownload download={download} album={music} />
          )}
          {type === CONTENT_TYPES.SONG && (
            <SongDownload download={download} song={music} />
          )}
          {type === CONTENT_TYPES.COLLECTION && (
            <CollectionDownload download={download} collection={music} />
          )}
          {type === CONTENT_TYPES.NFTOKEN && (
            <NFTokenDownload download={download} token={music} />
          )}
        </>
      )}

      {!isLoaded && (
        <>
          <Header title={"Music Downloader"} />
          <Slider type={SLIDER_TYPES.MUSIC_SKELETON} id={"loading"} />
        </>
      )}
    </>
  );
};

export default MusicDownloader;
