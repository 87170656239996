import { FaTimes } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { hexToRgba } from '../HexToRGBA'


export const NotificationContainer = styled.aside`
    display: flex;
    border-radius: 25px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: ${(props) => hexToRgba(props.theme.mainBg, 0.9)};
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
   
`

export const CloseIcon = styled(FaTimes)`
    color: ${(props) => props.scrollNav ? props.theme.secondaryColor : props.theme.secondaryBg  }; 
`

export const NotificationWrapper = styled.div`
    display: flex;
    color:  ${props => props.theme.secondaryBg};
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const NotificationRoute = styled(NavLink)`
    color: ${(props) => props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    text-decoration: none;
    border-bottom: 1px groove ${props => hexToRgba(props.theme.accentColor,0.2)};
    list-style: none;
    transition: 0.2s ease-in-out;
    color: ${props => props.theme.secondaryBg};
    cursor:pointer;

    &:hover {
        
        transition: all 0.2s ease-in-out;
        color:  ${props => props.theme.mainBg};
        background ${props => hexToRgba(props.theme.secondaryBg, 0.5)};
    }

`

export const NotificationDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    font-size: 1rem;
    white-space: white-space;
`

export const NotificationIcon = styled.div`
    display: flex-box;
    font:size: 1rem;
    color: ${props => props.theme.secondaryBg};

`

export const NotificationMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    @media screen and (max-width: 480px)
    {
        grid-template-rows: repeat(6, 60px);
    }
`
