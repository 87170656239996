import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useUser } from "../../hooks/useUser";
import { Fade, Reveal } from "react-reveal";
import { animateScroll as scroll } from "react-scroll";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import {
  CloseIcon,
  Icon,
  NotificationContainer,
  NotificationDetails,
  NotificationMenu,
  NotificationRoute,
  NotificationWrapper,
} from "./NotificationsElements";
import OptionModal from "../OptionModal";
import { FormH1, FormImg, FormImgWrapper, FormText } from "../CustomForm/CustomFormElements";
import { CONTENT_TYPES, NOTIFICATION_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import { getObjectLength, getSongTitle, getTimestamp, timestampToDate } from "../../utils";
import ContentList from "../ContentList";
import {
  DetailsContainer,
  DetailsText,
  Table,
  TableData,
  TableHeading,
  TableRow,
} from "../ContentList/ContentListElements";
import { ContentContainer } from "../ContentWrapper/ContentWrapperElements";
import { useNotifications } from "./NotificationContext";
import { FcLike } from "react-icons/fc";
import useMusic from "../../hooks/useMusic";
import { useNavigate } from "react-router-dom";
import { useModal } from "../Modal/ModalContext";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import {
  FaComments,
  FaDonate,
  FaMusic,
  FaTrophy,
  FaUserPlus,
} from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdMobileFriendly, MdOutlinePending } from "react-icons/md";
import { timestamp } from "../Authorization/firebase";
import ScrollToTop from "../ScrollToTop";
import { Nav, NavContainer, NavLink } from "../NavLink/NavLinkElements";
import QRCodeForm from "../QRCodeForm";

export default function Notifications() {
  const {
    notifications,
    getUserNotifications,
    markNotificationAsRead,
    isArtist,
  } = useNotifications();
  const {currentUser} = useAuth()
  const { getSong, getAlbum, getPlaylist, getMusicComment, getNFTokenCollection } = useMusic();
  const { getTip} = useUser({});
  const [currentTab, setCurrentTab] = useState(
    notifications?.filter((n) => n?.read === false).length > 0
      ? "unread"
      : "read"
  );
  const navigate  = useNavigate();
  const { goTo } = ScrollToTop();
  const { toggleModal, modalContent, isOpen } = useModal();

  useEffect(() => {
    getUserNotifications()
    return () => {}
  }, []);


  const onCollectSuccess = (data) => {
    modalContent(
      <OptionModal
      isOpen={true}
    heading={`The NFToken from Collection ${data.collection.name} #${data.collection.mintInfo.taxon} has been claimed!`}>
        <FormImgWrapper><FormImg src={data?.collection?.cover?.url} alt={data.collection.name}/></FormImgWrapper>
        <FormText> {`Token ID:  ${data.tokenId.substring(0,10)}... Minted!`}</FormText>
         <HoverOption onClick={() => {
              navigate( `/nftoken/${data.tokenId}`)
          }}>
        View NFToken
        </HoverOption>
      </OptionModal>
    )
  }

  const setNotificationTitle = (notification) => {
    switch (notification.type) {
      case NOTIFICATION_TYPES.LIKE:
        if (notification?.songId) {
          return (
            <>
              {`${notification?.senderId?.substring(
                0,
                3
              )}...${notification?.senderId?.substring(
                notification?.senderId?.length - 5,
                notification?.senderId?.length - 1
              )}  liked a song of yours`}
            </>
          );
        }
        if (notification?.albumId) {
          return `${notification?.senderId?.substring(
            0,
            3
          )}...${notification?.senderId?.substring(
            notification?.senderId?.length - 5,
            notification?.senderId?.length - 1
          )} liked an album of yours`;
        }
        if (notification?.playlistId) {
          return `${notification?.senderId?.substring(
            0,
            3
          )}...${notification?.senderId?.substring(
            notification?.senderId?.length - 5,
            notification?.senderId?.length - 1
          )} liked a playlist of yours`;
        }

        break;
      case NOTIFICATION_TYPES.FOLLOW:
        return (
          <>
            {`${notification?.senderId?.substring(
              0,
              3
            )}...${notification?.senderId?.substring(
              notification?.senderId?.length - 5,
              notification?.senderId?.length - 1
            )} followed your account`}
          </>
        );

      case NOTIFICATION_TYPES.ARTIST_FOLLOW:
        return (
          <>
            {`${notification?.senderId?.substring(
              0,
              3
            )}...${notification?.senderId?.substring(
              notification?.senderId?.length - 5,
              notification?.senderId?.length - 1
            )} followed your artist account`}
          </>
        );
      case NOTIFICATION_TYPES.COMMENT:
        return (
          <>
            {`${notification?.senderId?.substring(
              0,
              3
            )}...${notification?.senderId?.substring(
              notification?.senderId?.length - 5,
              notification?.senderId?.length - 1
            )} commented on your ${notification?.commentType}`}
          </>
        );

      case NOTIFICATION_TYPES.MUSIC_RELEASE:
        break;
      case NOTIFICATION_TYPES.PENDING_NFTOKEN:
        return(<>
            {`You have a pending NFToken to collect`}
          </>)
  
      case NOTIFICATION_TYPES.PLAYS:
        break;
      case NOTIFICATION_TYPES.SUBSCRIPTION:
        break;
      case NOTIFICATION_TYPES.TIP:
        return (
          <>
            {`${notification?.senderId?.substring(
              0,
              3
            )}...${notification?.senderId?.substring(
              notification?.senderId?.length - 5,
              notification?.senderId?.length - 1
            )} sent you a tip!`}
          </>
        );

      default:
        break;
    }
  };

  async function NotificationDetailsViewer(notification) {
    switch (notification?.type) {
      case NOTIFICATION_TYPES.LIKE:
        if (notification?.songId) {
          if (!isOpen) {
            toggleModal();
          }
          modalContent(<ModalLoadingScreen transparent={true} />);
          await getSong(notification.songId)
            .then((song) => {
              markNotificationAsRead(notification.notificationId)
                .then((data) => {
                  if (data) {
                    getUserNotifications();
                  }
                })
                .catch((err) => {
                  //console.log(err);
                });
              modalContent(
                <>
                  <OptionModal heading={<FcLike />}>
                    <FormText>
                      <span>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/u/${notification?.senderId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {notification.senderId}
                        </div>{" "}
                        liked your song{" "}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/song/${notification?.songId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {getSongTitle(song)}
                        </div>
                      </span>
                    </FormText>
                    <HoverOption
                      onClick={() => {
                        getUserNotifications();
                        modalContent(<Notifications />);
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                </>
              );
            })
            .catch((err) => {
              modalContent(
                <OptionModal heading={"Something went wrong"}>
                  <HoverOption onClick={() => modalContent(<Notifications />)}>
                    Go Back
                  </HoverOption>
                </OptionModal>
              );
            });
        }
        if (notification?.albumId) {
          if (!isOpen) {
            toggleModal();
          }
          modalContent(<ModalLoadingScreen transparent={true} />);
          await getAlbum(notification.albumId)
            .then((album) => {
              markNotificationAsRead(notification.notificationId)
                .then((data) => {
                  if (data) {
                    getUserNotifications();
                  }
                })
                .catch((err) => {
                  //console.log(err);
                });
              modalContent(
                <>
                  <OptionModal heading={<FcLike />}>
                    <FormText>
                      <span>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/u/${notification?.senderId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {notification.senderId}
                        </div>{" "}
                        liked your album{" "}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/album/${notification?.albumId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {album.albumName}
                        </div>
                      </span>
                    </FormText>
                    <HoverOption
                      onClick={() => {
                        getUserNotifications();
                        modalContent(<Notifications />);
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                </>
              );
            })
            .catch((err) => {
              modalContent(
                <OptionModal heading={"Something went wrong"}>
                  <HoverOption onClick={() => modalContent(<Notifications />)}>
                    Go Back
                  </HoverOption>
                </OptionModal>
              );
            });
        }
        if (notification?.playlistId) {
          if (!isOpen) {
            toggleModal();
          }
          modalContent(<ModalLoadingScreen transparent={true} />);
          await getPlaylist(notification.playlistId)
            .then((playlist) => {
              markNotificationAsRead(notification.notificationId)
                .then((data) => {
                  if (data) {
                    getUserNotifications();
                  }
                })
                .catch((err) => {
                  //console.log(err);
                });
              modalContent(
                <>
                  <OptionModal heading={<FcLike />}>
                    <FormText>
                      <span>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/u/${notification?.senderId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {notification.senderId}
                        </div>{" "}
                        liked your playlist{" "}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            goTo(`/playlist/${notification?.playlistId}`);
                            toggleModal();
                            modalContent();
                          }}
                        >
                          {playlist.playlistName}
                        </div>
                      </span>
                    </FormText>
                    <HoverOption
                      onClick={() => {
                        getUserNotifications();
                        modalContent(<Notifications />);
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                </>
              );
            })
            .catch((err) => {
              modalContent(
                <OptionModal heading={"Something went wrong"}>
                  <HoverOption onClick={() => modalContent(<Notifications />)}>
                    Go Back
                  </HoverOption>
                </OptionModal>
              );
            });
        }
        break;
      case NOTIFICATION_TYPES.FOLLOW:
        if (!isOpen) {
          toggleModal();
        }
        modalContent(<ModalLoadingScreen transparent={true} />);
        markNotificationAsRead(notification?.notificationId)
          .then((data) => {
            if (data) {
              getUserNotifications();
            }
          })
          .catch((err) => {
            //console.log(err);
          });
        modalContent(
          <>
            <OptionModal heading={<FaUserPlus />}>
              <FormText>
                <span>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      goTo(`/u/${notification?.senderId}`);
                      toggleModal();
                      modalContent();
                    }}
                  >
                    {notification.senderId}
                  </div>{" "}
                  followed you
                </span>
              </FormText>
              <HoverOption
                onClick={() => {
                  getUserNotifications();
                  modalContent(<Notifications />);
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          </>
        );
        break;
      case NOTIFICATION_TYPES.ARTIST_FOLLOW:
        if (!isOpen) {
          toggleModal();
        }
        modalContent(<ModalLoadingScreen transparent={true} />);
        markNotificationAsRead(notification?.notificationId)
          .then((data) => {
            if (data) {
              getUserNotifications();
            }
          })
          .catch((err) => {
            //console.log(err);
          });
        await isArtist(notification?.recipientId)
          .then((data) => {
            if (data) {
              modalContent(
                <>
                  <OptionModal heading={<FaUserPlus />}>
                    <FormText>
                      <span>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate( `/u/${notification?.senderId}`);
                            toggleModal();
                            modalContent();
                            scroll.scrollToTop();
                          }}
                        >
                          {notification.senderId}
                        </div>{" "}
                        followed your artist page
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate( `/artist/${data?.artistId}`);
                            toggleModal();
                            modalContent();
                            scroll.scrollToTop();
                          }}
                        >
                          {data?.artistName}
                        </div>
                      </span>
                    </FormText>
                    <HoverOption
                      onClick={() => {
                        getUserNotifications();
                        modalContent(<Notifications />);
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                </>
              );
            }
          })
          .catch((err) => {
            modalContent(
              <OptionModal heading={"Something went wrong"}>
                <HoverOption onClick={() => modalContent(<Notifications />)}>
                  Go Back
                </HoverOption>
              </OptionModal>
            );
          });
        break;
      case NOTIFICATION_TYPES.COMMENT:
        if (!isOpen) {
          toggleModal();
        }
        modalContent(<ModalLoadingScreen transparent={true} />);
        await getMusicComment(notification.commentId)
          .then((comment) => {
            markNotificationAsRead(notification.notificationId)
              .then((data) => {
                if (data) {
                  getUserNotifications();
                }
              })
              .catch((err) => {
                console.error(err);
              });
            modalContent(
              <>
                <OptionModal heading={<FcLike />}>
                  <FormText>
                    <span>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          goTo(`/u/${notification?.senderId}`);
                          toggleModal();
                          modalContent();
                        }}
                      >
                        {notification.senderId}
                      </div>{" "}
                      commented your 
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          goTo(
                            `/${notification?.commentType}/${
                              comment?.songId
                                ? comment?.songId
                                : comment?.albumId
                                ? comment?.albumId
                                : comment?.playlistId
                                ? comment?.playlistId :
                                comment?.collectionId
                            }`
                          );
                          toggleModal();
                          modalContent();
                        }}
                      >{notification?.commentType}</div>
                    </span>
                  </FormText>
                  <HoverOption
                    onClick={() => {
                      getUserNotifications();
                      modalContent(<Notifications />);
                    }}
                  >
                    OK
                  </HoverOption>
                </OptionModal>
              </>
            );
          })
          .catch((err) => {
            modalContent(
              <OptionModal heading={"Something went wrong"}>
                <HoverOption onClick={() => modalContent(<Notifications />)}>
                  Go Back
                </HoverOption>
              </OptionModal>
            );
          });
        break;
      case NOTIFICATION_TYPES.MUSIC_RELEASE:
        break;
      case NOTIFICATION_TYPES.PENDING_NFTOKEN:
        //console.log(notification)
        /*validateNFTokenOffer(notification?.notificationId).then(() => {
          
        })*/
        modalContent(<ModalLoadingScreen transparent={true} />);
        await getNFTokenCollection(notification?.collectionId).then(
          (collection) => {
            //console.log(collection)
            modalContent(
              <QRCodeForm 
                heading={`Claim ${notification?.tokenId.substring(0,5)}... from the ${collection.name} NFT Collection`}
                type={TRANSACTION_OPTIONS.NFTOKEN_OFFER_ACCEPT}
                cancelQr={() => {
                  toggleModal()
                  modalContent()}}
                onFail={() => {}}
                onSuccess={onCollectSuccess}
                body=
                  {
                    {
                    collection: collection,
                    invoice: notification.notificationId,
                    tokenId: notification?.tokenId,
                    offer: notification?.offer,
                    sender: currentUser?.uid,
                    memo: `user is collecting/claiming their token ${notification?.tokenId.substring(0,5)}...} NFT from the collection ${collection.name}`,
                    instruction: `user is collecting/claiming their token ${notification?.tokenId.substring(0,5)}...} NFT(s) from the collection ${collection.name}`,}
                  }
              />
            )
          }
        )
        break;
      case NOTIFICATION_TYPES.PLAYS:
        break;
      case NOTIFICATION_TYPES.SUBSCRIPTION:
        break;
      case NOTIFICATION_TYPES.TIP:
        if (!isOpen) {
          toggleModal();
        }
        modalContent(<ModalLoadingScreen transparent={true} />);
        getTip(notification?.notificationId).then((tip) => {
          markNotificationAsRead(notification?.notificationId)
          .then((data) => {
            if (data) {
              getUserNotifications();
            }
          })
          .catch((err) => {
            //console.log(err);
            modalContent()
            toggleModal()
          });
          modalContent(
            <>
              <OptionModal heading={<FcLike />}>
                <FormText>
                  <span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        goTo(`/u/${notification?.senderId}`);
                        toggleModal();
                        modalContent();
                      }}
                    >
                      {notification.senderId}
                    </div>{" "}
                    sent you a tip!
                  </span>
                </FormText>
                <HoverOption
                  onClick={() => {
                    getUserNotifications();
                    modalContent(<Notifications />);
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            </>
          );
        }).catch((err) => {
          //console.log(err);
          modalContent()
          toggleModal()
      });
        
        break;

      default:
        break;
    }
  }

  function displayNotificationIcon(type) {
    switch (type) {
      case NOTIFICATION_TYPES.LIKE:
        return <FcLike />;
      case NOTIFICATION_TYPES.FOLLOW:
        return <FaUserPlus />;
      case NOTIFICATION_TYPES.ARTIST_FOLLOW:
        return <AiOutlineUsergroupAdd />;
      case NOTIFICATION_TYPES.COMMENT:
        return <FaComments />;
      case NOTIFICATION_TYPES.MUSIC_RELEASE:
        return <FaMusic />;
      case NOTIFICATION_TYPES.PENDING_NFTOKEN:
        return <MdOutlinePending />;
      case NOTIFICATION_TYPES.PLAYS:
        return <FaTrophy />;
      case NOTIFICATION_TYPES.SUBSCRIPTION:
        return <MdMobileFriendly />;
      case NOTIFICATION_TYPES.TIP:
        return <FaDonate />;
      default:
        break;
    }
  }

  async function filterNotifications(data) {
    let filteredNotifications = [];
    for (let i = 0; i <= data?.length - 1; i++) {
      let notification = data[i];
      switch (notification.type) {
        case NOTIFICATION_TYPES.LIKE:
          if (notification?.songId) {
            let title = await getSongTitle(notification.songId);
            filteredNotifications.push({
              ...notification,
              songTitle: title,
            });
          }
          if (notification?.albumId) {
          }
          if (notification?.playlistId) {
          }
          break;
        case NOTIFICATION_TYPES.FOLLOW:
          break;
        case NOTIFICATION_TYPES.ARTIST_FOLLOW:
          break;
        case NOTIFICATION_TYPES.COMMENT:
          break;
        case NOTIFICATION_TYPES.MUSIC_RELEASE:
          break;
        case NOTIFICATION_TYPES.PENDING_NFTOKEN:
          break;
        case NOTIFICATION_TYPES.PLAYS:
          break;
        case NOTIFICATION_TYPES.SUBSCRIPTION:
          break;
        case NOTIFICATION_TYPES.TIP:
          break;

        default:
          break;
      }
    }
    return;
  }

  return (
    <>
      <Reveal>
        <NotificationContainer>
          <NotificationWrapper>
            <FormH1>{"Notifications"}</FormH1>

            {notifications && notifications?.length > 0 && (
              <>
                <ContentContainer>
                  <NavContainer style={{ marginBottom: "2%" }}>
                    <Nav>
                      {notifications?.filter((n) => n?.read === false).length >
                        0 && (
                        <NavLink
                          active={currentTab === "unread"}
                          onClick={() => setCurrentTab("unread")}
                        >
                          Unread
                        </NavLink>
                      )}
                      <NavLink
                        active={currentTab === "read"}
                        onClick={() => setCurrentTab("read")}
                      >
                        Read
                      </NavLink>
                      <NavLink
                        active={currentTab === "nft"}
                        onClick={() => setCurrentTab("nft")}
                      >
                        NFT Notifications
                      </NavLink>
                    </Nav>{" "}
                  </NavContainer>
                  <NotificationMenu style={{ width: " 100%" }}>
                    {currentTab === "unread" &&
                      notifications
                        ?.filter((n) => n?.read === false)
                        .map((notification, index) => {
                          return (
                            <Fade>
                              <NotificationRoute
                                key={index}
                                id={notification?.notificationId}
                                to={"#"}
                                onClick={() =>
                                  {
                                    
                                    NotificationDetailsViewer(notification)
                                  
                                  }
                                }
                              >
                                
                                  {displayNotificationIcon(notification?.type)}
                                
                                <NotificationDetails>
                                  <i><small><DetailsText>
                                    {notification?.createdAt
                                      ? getTimestamp(
                                          new Date(
                                            timestampToDate(
                                              notification?.createdAt
                                            )
                                          )
                                        )
                                      : getTimestamp(new Date())}
                                  </DetailsText></small></i>
                                  <DetailsText>
                                    {setNotificationTitle(notification)}
                                  </DetailsText>
                                </NotificationDetails>
                                
                              </NotificationRoute>
                            </Fade>
                          );
                        })}
                    {currentTab === "read" &&
                      notifications
                        ?.filter((n) => n?.read)
                        .map((notification, index) => {
                          return (
                            <Fade>
                              <NotificationRoute
                                key={index}
                                id={notification?.notificationId}
                                to={"#"}
                                onClick={() =>
                                  {
                                    NotificationDetailsViewer(notification)}
                                }
                              >
                               
                                  {displayNotificationIcon(notification?.type)}
                                  
                                <NotificationDetails>
                                  <DetailsText>
                                    {notification?.createdAt
                                      ? getTimestamp(
                                        new Date(
                                          timestampToDate(
                                            notification?.createdAt
                                          )
                                        )
                                        )
                                      : getTimestamp(new Date())}
                                  </DetailsText>
                                  <DetailsText>
                                    {setNotificationTitle(notification)}
                                  </DetailsText>
                                </NotificationDetails>
                                
                              </NotificationRoute>
                            </Fade>
                          );
                        })}
                    {currentTab === "nft" &&
                      notifications
                        ?.filter(
                          (n) => n?.type === NOTIFICATION_TYPES.PENDING_NFTOKEN && !n?.read
                        )
                        .map((notification, index) => {
                          //console.log(notification)
                          return (
                            <Fade>
                              <NotificationRoute
                                key={index}
                                id={notification?.notificationId}
                                to={"#"}
                                onClick={() =>
                                  NotificationDetailsViewer(notification)
                                }
                              >
                                 <NotificationDetails>
                                  {displayNotificationIcon(notification?.type)}
                                </NotificationDetails>
                                <NotificationDetails>
                                  <DetailsText>
                                    {notification?.createdAt
                                      ? getTimestamp(
                                        new Date(
                                          timestampToDate(
                                            notification?.createdAt
                                          )
                                        )
                                        )
                                      : getTimestamp(new Date())}
                                  </DetailsText>
                                  <DetailsText>
                                    {setNotificationTitle(notification)}
                                  </DetailsText>
                                </NotificationDetails>
                               
                              </NotificationRoute>
                            </Fade>
                          );
                        })}
                  </NotificationMenu>
                </ContentContainer>
              </>
            )}
            <NotificationMenu>
              {currentTab === "unread" &&
                notifications &&
                notifications?.filter((n) => !n?.read)?.length === 0 && (
                  <FormText>No New Notifications</FormText>
                )}
              {currentTab === "read" &&
                notifications &&
                notifications?.filter((n) => n?.read)?.length === 0 && (
                  <FormText>No New Notifications</FormText>
                )}
              {currentTab === "nft" &&
                notifications &&
                notifications?.filter(
                  (n) => n.type === NOTIFICATION_TYPES.PENDING_NFTOKEN && !n?.read
                )?.length === 0 && <FormText>No New Notifications</FormText>}
            </NotificationMenu>
          </NotificationWrapper>

          {/* <NotificationMenu>
            {notifications && notifications.map((notification, index) => {
              return (<NotificationRoute 
              id={index} 
              to={() => 
                notification?.senderId ? 
                `/u/${notification.senderId }`: 
                notification?.artistId ? `/artist/${notification.artistId }` : 
                notification?.followerId ? `/u/${notification.followerId }`:
                '#'
              } onClick={markAsRead(notification.id)}>
              {notification.message}
              </NotificationRoute>
            )})}{!notifications && isLoaded === true && 
            <>No Notifications</>}
        </NotificationMenu> */}
        </NotificationContainer>
      </Reveal>
    </>
  );
}
