import React, { useEffect, useState } from 'react'
import useMusic from '../../../hooks/useMusic';
import { getEpochData, getGroupedPlays, getNumListeners, getObjectLength, getPlayCount, groupInfoByDate, groupPurchasesByAmount } from '../../../utils';
import ContentWrapper from '../../ContentWrapper';
import { SelectButtonWrapper } from '../../Select';
import { SelectButton } from '../../CurrencySelector';
import { ModalLoadingScreen } from '../../LoadingScreen';
import KeyMetrics from '../../Charts/KeyMetrics';
import { ChipList } from '../../Chip/ChipElements';
import Chip from '../../Chip';
import { FormText, FormWrapper } from '../../CustomForm/CustomFormElements';
import SongList, { AlbumSongList } from '../../ContentList/SongList';
import BarGraph from '../../Charts/Bar';
import { CURRENCIES_STATS_COLORS } from '../../../utils/Enum';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';
import { Timer } from '../../CountdownTimer';
import ChartToggle from '../../Charts';

const AlbumStats = ({royalty, albumId}) => {
  const {themeColors} = useWebsiteTheme()

  const {
    getAlbumPlaysByDate,
    getAlbumLikesByDate,
    getAlbumDigitalPurchasesByDate,
    getAlbumRoyaltyPurchasesByDate,
    getSongs,
  } = useMusic();
  const [filter, setFilter] = useState("7days");
  const [isLoaded, setLoaded] = useState(false);
  const [isSongsLoaded, setSongsLoaded] = useState(false);
  const [plays, setPlays] = useState([]);
  const [likes, setLikes] = useState([]);
  const [epochs, setEpochs] = useState([])
  const [collections, setCollections] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [digitalPurchases, setDigitalPurchases] = useState([]);
  const [royaltyPurchases, setRoyaltyPurchases] = useState([]);
  const [metricType, setMetricType] = useState("plays");
  const [playType, setPlayType] = useState("all");
  const [purchaseType, setPurchaseType] = useState("all");
  const [songs, setSongs] = useState([]);

  const getData = async () => {
  setLoaded(false);
  setLikes([]);
  setCollections([]);
  setRoyaltyPurchases([]);
  setDigitalPurchases([]);
  return await Promise.all([
    getAlbumPlaysByDate(albumId, filter),
    getAlbumLikesByDate(albumId, filter),
    getAlbumDigitalPurchasesByDate(albumId, filter),
    getAlbumRoyaltyPurchasesByDate(albumId, filter),
  ])
    .then((data) => {
      let p = [];
      if (getObjectLength(data[0]?.plays) > 0) {
        setPlays(data[0]?.plays);
      }
      if (data[1]?.likes?.length > 0) {
        setLikes(data[1]?.likes);
      }

      if (data[2]?.purchases?.length > 0) {
        p.push(...data[2]?.purchases);
        setDigitalPurchases(data[2]?.purchases);
      }
      if (data[3]?.purchases?.length > 0) {
        p.push(...data[3]?.purchases);
        setRoyaltyPurchases(data[3]?.purchases);
      }
      setPurchases(p);
      setLoaded(true);
    })
    .catch((err) => {
      setLoaded(true);
    });
};

const getSongsByPlayType = () => {
  let list = [];
  let listens = [...plays?.skips, ...plays?.partial, ...plays?.full];
  switch (playType) {
    case "skip":
      list = listens
        .filter((play) => play?.playType === playType && !play?.repeat)
        .map((play) => {
          return play?.songId;
        });
      break;
    case "full":
      list = listens
        .filter((play) => play?.playType === playType || play?.repeat > 0)
        .map((play) => {
          return play?.songId;
        });
      break;
    case "partial":
      list = listens
        .filter((play) => play?.playType === playType && !play?.repeat)
        .map((play) => {
          return play?.songId;
        });
      break;

    default:
      list = listens.map((play) => {
        return play?.songId;
      });
      break;
  }
  return [...new Set(list)];
};

useEffect(() => {
  if (isLoaded) {
    setSongsLoaded(false);
    let s = getSongsByPlayType();
    if (s?.length > 0) {
      getSongs(s)
        .then((Songs) => {
          if (Songs?.length > 0) {
            setSongs(
              Songs.map((song) => {
                return {
                  ...song,
                  plays:
                    playType === "all"
                      ? getPlayCount(
                          [
                            ...plays?.skips,
                            ...plays?.partial,
                            ...plays?.full,
                          ].filter((l) => l?.songId === song?.songId)
                        )
                      : [
                          ...plays?.skips,
                          ...plays?.partial,
                          ...plays?.full,
                        ].filter(
                          (l) =>
                            l?.songId === song?.songId &&
                            l?.playType === playType
                        )?.length,
                };
              })
            ).sort((a, b) => b?.plays - a?.plays);
          }
          setSongsLoaded(true);
        })
        .catch((err) => {
          setSongsLoaded(true);
        });
    }
  }

  return () => {};
}, [playType, royalty, filter, isLoaded]);

useEffect(() => {
  getData();
  setEpochs(royalty?.prevPayouts?.length > 0 ? getEpochData(royalty?.prevPayouts) : [])

  return () => {};
}, [royalty, filter]);

return (
  <ContentWrapper>
    <SelectButtonWrapper>
      <SelectButton
        disabled={!isLoaded}
        onChange={(e) => setFilter(e.target.value)}
      >
        <option value={"7days"}>7 days</option>
        <option value={"14days"}>2 Weeks</option>
        <option value={"30days"}>4 Weeks</option>
        <option value={"60days"}>2 Months</option>
        <option value={"180days"}>6 Months</option>
        <option value={"365days"}>12 Months</option>
        <option value={"AllTime"}>All Time</option>
      </SelectButton>
    </SelectButtonWrapper>
    {!isLoaded && <ModalLoadingScreen text={"Loading Stats"} />}
    {isLoaded && (
      <>
        <KeyMetrics
          data={[
            {
              heading: "Plays",
              onClick: () => {
                setMetricType("plays");
              },
              labels:
                getObjectLength(plays) > 0
                  ? Object.keys(
                      groupInfoByDate(
                        [...plays?.full, ...plays?.skips, ...plays?.partial],
                        "timestamp",
                        filter
                      )
                    )
                  : [],
              datasets: [
                {
                  label: "Plays",
                  data:
                    getObjectLength(plays) > 0
                      ? Object.values(
                          groupInfoByDate(
                            [
                              ...plays?.full,
                              ...plays?.skips,
                              ...plays?.partial,
                            ],
                            "timestamp",
                            filter
                          )
                        ).map((p) => {
                          return getPlayCount(p);
                        })
                      : [],
                  borderColor: themeColors.infoColor,
                  backgroundColor: themeColors.infoColor,
                },
              ],
            },
            {
              onClick: () => {
                setMetricType("epochs");
              },
              heading: "Payouts",
              labels: Object.keys(epochs),
              datasets: [
                {
                  label: "XRP",
                  data: Object.values(epochs),
                  borderColor: themeColors.mainColor,
                  backgroundColor: themeColors.mainColor,
                },
              ],
            },
            {
              heading: "Likes",
              onClick: () => {
                setMetricType("likes");
              },
              labels:
                likes.length > 0
                  ? Object.keys(groupInfoByDate(likes, "dateCreated", filter))
                  : [],
              datasets: [
                {
                  label: "Likes",
                  data:
                    likes.length > 0
                      ? Object.values(
                          groupInfoByDate(likes, "dateCreated", filter)
                        ).map((l) => {
                          return l?.length;
                        })
                      : [],
                  borderColor: themeColors.errorColor,
                  backgroundColor: themeColors.infoColor,
                },
              ],
            },
            {
              heading: "Revenue",
              onClick: () => {
                setMetricType("purchases");
              },
              labels:
                purchases?.length > 0
                  ? Object.keys(
                      groupInfoByDate(purchases, "createdAt", filter)
                    )
                  : [],
              datasets: [
                {
                  label: "Revenue",
                  data:
                    purchases?.length > 0
                      ? Object.values(
                          groupInfoByDate(purchases, "createdAt", filter)
                        ).map((p) => {
                          return p?.length;
                        })
                      : [],
                  backgroundColor: themeColors.infoColor,
                },
              ],
            },
          ]}
        />
        <ContentWrapper>
          {metricType === "plays" && (
            <>
              <ChipList alignment={"right"}>
                <Chip
                  label={"All"}
                  background={playType === "all" && themeColors.accentColor}
                  onChipClick={() => setPlayType("all")}
                />
                <Chip
                  label={"Full Plays"}
                  background={playType === "full" && themeColors.successColor}
                  onChipClick={() => setPlayType("full")}
                />
                <Chip
                  label={"Partial Plays"}
                  background={playType === "partial" && themeColors.infoColor}
                  onChipClick={() => setPlayType("partial")}
                />
                <Chip
                  label={"Skips"}
                  background={playType === "skip" && themeColors.warningColor}
                  onChipClick={() => setPlayType("skip")}
                />
              </ChipList>
              <>
              
                {playType === "all" && (
                  <>
                 
                    <ChartToggle
                      data={{
                        heading: 'All Plays (By Type)',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Full",
                            data:
                              plays?.full?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.full,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.successColor,
                            backgroundColor: themeColors.successColor,
                          },
                          {
                            label: "Partial",
                            data:
                              plays?.partial?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.partial,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p.length;
                                  })
                                : [],

                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor,
                          },
                          {
                            label: "Skips",
                            data:
                              plays?.skips?.length > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      plays?.skips,
                                      "timestamp",
                                      filter
                                    )
                                  ).map((p) => {
                                    return p?.length;
                                  })
                                : [],

                            borderColor: themeColors.warningColor,
                            backgroundColor: themeColors.warningColor,
                          },
                        ],
                      }}
                    />
                    <ChartToggle
                      data={{
                        heading: 'Unique Listeners',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Listeners",
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    getNumListeners(
                                      groupInfoByDate(
                                        [
                                          ...plays?.full,
                                          ...plays?.skips,
                                          ...plays?.partial,
                                        ],
                                        "timestamp",
                                        filter
                                      )
                                    )
                                  )
                                : [],
                            borderColor: themeColors.infoColor,
                            backgroundColor: themeColors.infoColor
                                
                          },
                        ],
                      }}
                    />
                  </>
                )}
                {playType !== "all" && (
                  <>
                 
                    <ChartToggle
                      data={{
                        heading: `${playType[0].toUpperCase()}${playType.substring(1)} Plays`,
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: playType,
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    groupInfoByDate(
                                      [
                                        ...plays?.full,
                                        ...plays?.skips,
                                        ...plays?.partial,
                                      ],
                                      "timestamp",
                                      filter
                                    )
                                  ).map((plays) => {
                                    return getGroupedPlays(plays)[playType];
                                  })
                                : [],

                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            backgroundColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                    <ChartToggle
                      data={{
                        heading: 'Unique Listeners',
                        labels:
                          getObjectLength(plays) > 0
                            ? Object.keys(
                                groupInfoByDate(
                                  [
                                    ...plays?.full,
                                    ...plays?.skips,
                                    ...plays?.partial,
                                  ],
                                  "timestamp",
                                  filter
                                )
                              )
                            : [],
                        datasets: [
                          {
                            label: "Listeners",
                            data:
                              getObjectLength(plays) > 0
                                ? Object.values(
                                    getNumListeners(
                                      groupInfoByDate(
                                        [
                                          ...plays?.full,
                                          ...plays?.skips,
                                          ...plays?.partial,
                                        ].filter(
                                          (p) => playType === p?.playType
                                        ),
                                        "timestamp",
                                        filter
                                      )
                                    )
                                  )
                                : [],
                            borderColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                            backgroundColor:
                              playType === "skip"
                                ? themeColors.warningColor
                                : playType === "partial"
                                ? themeColors.infoColor
                                : themeColors.successColor,
                          },
                        ],
                      }}
                    />
                  </>
                )}
              </>

              {!isSongsLoaded && <ModalLoadingScreen />}
              {isSongsLoaded && (
                
                  <FormWrapper>
                    <AlbumSongList 
                      content={songs}
                      heading={`${playType !== 'all' ? `${playType[0].toUpperCase()}${playType.substring(1)} Played Songs` : 'All Songs'}`}
                    />
                  </FormWrapper>
                
              )}
            </>
          )}

          {metricType === "likes" && likes?.length > 0 && (
              <ChartToggle
                data={{
                  heading: "No. Likes",
                  labels:
                    likes.length > 0
                      ? Object.keys(
                          groupInfoByDate(likes, "dateCreated", filter)
                        )
                      : [],
                  datasets: [
                    {
                      label: "Likes",
                      data:
                        likes.length > 0
                          ? Object.values(
                              groupInfoByDate(likes, "dateCreated", filter)
                            ).map((l) => {
                              return l?.length;
                            })
                          : [],
                      borderColor: themeColors.infoColor,
                      backgroundColor: themeColors.infoColor,
                    },
                  ],
                }}
              />
          )}
          {metricType === "purchases" && purchases?.length > 0 && (
            <>

                <ChartToggle
                  data={{
                    heading: "Total Sales",
                    labels:
                      purchases?.length > 0 ? ["Digitals", "Royalties"] : [],
                    datasets: [
                      {
                        label: "Sales",
                        data:
                          purchases?.length > 0
                            ? [
                                digitalPurchases?.length > 0
                                  ? digitalPurchases?.length
                                  : 0,
                                royaltyPurchases?.length > 0
                                  ? royaltyPurchases?.length
                                  : 0,
                              ]
                            : [],
                        borderColor: themeColors.successColor,
                        backgroundColor: themeColors.successColor,
                      },
                    ],
                  }}
                />
              <ContentWrapper>
                <ChipList alignment={"right"}>
                  <Chip
                    label={"All"}
                    background={
                      purchaseType === "all" && themeColors.warningColor
                    }
                    onChipClick={() => setPurchaseType("all")}
                  />
                  <Chip
                    label={"Digital"}
                    background={
                      purchaseType === "digital" && themeColors.successColor
                    }
                    onChipClick={() => setPurchaseType("digital")}
                  />
                  <Chip
                    label={"Royalty"}
                    background={
                      purchaseType === "royalty" && themeColors.infoColor
                    }
                    onChipClick={() => setPurchaseType("royalty")}
                  />
                </ChipList>

                {
                  //Purchases by date
                  //Purchases grouped by currency
                }
                {purchaseType === "all" && (
                  <>
                    
                      <ChartToggle
                        data={{
                          heading: "Total Sales",
                          labels:
                            purchases?.length > 0
                              ? Object.keys(
                                  groupInfoByDate(
                                    purchases,
                                    "createdAt",
                                    filter
                                  )
                                )
                              : [],
                          datasets: [
                            {
                              label: "Sales",
                              data:
                                purchases?.length > 0
                                  ? Object.values(
                                      groupInfoByDate(
                                        purchases,
                                        "createdAt",
                                        filter
                                      )
                                    ).map((p) => {
                                      return p.length;
                                    })
                                  : [],
                              borderColor: themeColors.warningColor,
                              backgroundColor: themeColors.warningColor,
                            },
                          ],
                        }}
                      />
                

                    {getObjectLength(groupPurchasesByAmount(purchases)) >
                      0 && (
                     
                        <ChartToggle
                        type={'bar'}
                          data={{
                            heading: 'Revenue (Grouped By Currency)',
                            labels:
                              getObjectLength(
                                groupPurchasesByAmount(purchases)
                              ) > 0
                                ? Object.keys(
                                    groupPurchasesByAmount(purchases)
                                  )
                                : [],
                            datasets: [
                              {
                                label: "Total Earnings",
                                data:
                                  purchases?.length > 0
                                    ? Object.values(
                                        groupPurchasesByAmount(purchases)
                                      ).map((purchase) => {
                                        return parseFloat(purchase).toFixed(
                                          2
                                        );
                                      })
                                    : [],
                                borderColor: Object.keys(
                                  groupPurchasesByAmount(purchases)
                                ).map((purchase) => {
                                  return CURRENCIES_STATS_COLORS[purchase];
                                }),
                                backgroundColor: Object.keys(
                                  groupPurchasesByAmount(purchases)
                                ).map((purchase) => {
                                  return CURRENCIES_STATS_COLORS[purchase];
                                }),
                              },
                            ],
                          }}
                        />
                    )}
                  </>
                )}
                {purchases?.length > 0 && purchaseType !== "all" && (
                  <>
                    
                      <ChartToggle
                        data={{
                          heading: "Total Sales",
                          labels:
                            purchaseType === "digital"
                              ? digitalPurchases?.length > 0
                                ? Object.keys(
                                    groupInfoByDate(
                                      digitalPurchases,
                                      "createdAt",
                                      filter
                                    )
                                  )
                                : []
                              : royaltyPurchases?.length > 0
                              ? Object.keys(
                                  groupInfoByDate(
                                    royaltyPurchases,
                                    "createdAt",
                                    filter
                                  )
                                )
                              : [],
                          datasets: [
                            {
                              label: `${purchaseType[0].toUpperCase()}${purchaseType.substring(
                                1
                              )} Sales`,
                              data:
                                purchaseType === "digital"
                                  ? digitalPurchases?.length > 0
                                    ? Object.values(
                                        groupInfoByDate(
                                          digitalPurchases,
                                          "createdAt",
                                          filter
                                        )
                                      )
                                    : []
                                  : royaltyPurchases?.length > 0
                                  ? Object.values(
                                      groupInfoByDate(
                                        royaltyPurchases,
                                        "createdAt",
                                        filter
                                      )
                                    )
                                  : [],
                              borderColor:
                                purchaseType === "digital"
                                  ? themeColors.successColor
                                  : themeColors.infoColor,
                            
                              backgroundColor:
                                purchaseType === "digital"
                                  ? themeColors.successColor
                                  : themeColors.infoColor,
                            },
                          ],
                        }}
                      />
                    
                   
                      <ChartToggle
                      type={'bar'}
                        data={{
                          heading: `${purchaseType[0].toUpperCase()}${purchaseType.substring(1)} Purchases`,
                          labels:
                            purchaseType === "digital"
                              ? getObjectLength(
                                  groupPurchasesByAmount(digitalPurchases)
                                ) > 0
                                ? Object.keys(
                                    groupPurchasesByAmount(digitalPurchases)
                                  )
                                : []
                              : getObjectLength(
                                  groupPurchasesByAmount(royaltyPurchases)
                                ) > 0
                              ? Object.keys(
                                  groupPurchasesByAmount(royaltyPurchases)
                                )
                              : [],
                          datasets: [
                            {
                              label: "Total Earnings",
                              data:
                                purchaseType === "digital"
                                  ? getObjectLength(
                                      groupPurchasesByAmount(digitalPurchases)
                                    ) > 0
                                    ? Object.values(
                                        groupPurchasesByAmount(
                                          digitalPurchases
                                        )
                                      ).map((purchase) => {
                                        return parseFloat(purchase).toFixed(
                                          2
                                        );
                                      })
                                    : []
                                  : getObjectLength(
                                      groupPurchasesByAmount(royaltyPurchases)
                                    ) > 0
                                  ? Object.values(
                                      groupPurchasesByAmount(royaltyPurchases)
                                    ).map((purchase) => {
                                      return parseFloat(purchase).toFixed(2);
                                    })
                                  : [],
                              borderColor:Object.keys(
                                groupPurchasesByAmount(purchaseType === "digital" ? digitalPurchases : royaltyPurchases)
                              ).map((purchase) => {
                                return CURRENCIES_STATS_COLORS[purchase];
                              }),
                              backgroundColor:Object.keys(
                                groupPurchasesByAmount(purchaseType === "digital" ? digitalPurchases : royaltyPurchases)
                              ).map((purchase) => {
                                return CURRENCIES_STATS_COLORS[purchase];
                              }),
                            },
                          ],
                        }}
                      />
                  </>
                )}
              </ContentWrapper>
            </>
          )}
           {metricType === "epochs" && getObjectLength(epochs) > 0 && (
            <>
              {" "}
              <ContentWrapper>
                <Timer
                  heading={`$${royalty?.ticker} Payout Epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  }`}
                  isPage={false}
                  date={royalty.nextPayout}
                  details={`Snapshot of $${
                    royalty?.ticker
                  } Shareholders for payout epoch ${
                    royalty.prevPayouts?.length > 0
                      ? royalty.prevPayouts?.length + 1
                      : 1
                  } ${
                    !royalty?.snapshot
                      ? `will take place on ${
                          new Date(
                            new Date()?.setDate(
                              new Date(royalty.nextPayout).getDate() - 3
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }`
                      : "has been taken."
                  }`} /* img={royalty?.musicDetails?.albumArt
                ? royalty?.musicDetails?.albumArt
                : royalty?.musicDetails.thumbnail} */
                />
              </ContentWrapper>
              <div style={{ marginTop: "20%" }}></div>
              <ContentWrapper>
              
                  <ChartToggle
                    data={{
                      heading: "Epoch Payouts (In XRP)",
                      labels: Object.keys(epochs),
                      datasets: [
                        {
                          label: "Payout",
                          data: Object.values(epochs),

                          borderColor: themeColors.successColor,
                        },
                      ],
                    }}
                  />
              </ContentWrapper>
            </>
          )}
          {((metricType === "plays" && plays?.length === 0) ||
            (metricType === "likes" && likes?.length === 0) ||
            (metricType === "purchases" && purchases?.length === 0)) && (
            <>
              <ContentWrapper>
                <FormWrapper>
                  <FormText>No Data Available</FormText>
                </FormWrapper>
              </ContentWrapper>
            </>
          )}
        </ContentWrapper>
      </>
    )}
  </ContentWrapper>
);
}

export default AlbumStats