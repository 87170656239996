import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(  
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  )

export const NoLabelLineGraph = ({title, data}) => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
              display: false,
           } },
        scales:{
          x: {
            ticks: {
              display: false //this will remove only the label
         },
              display: false ////this will remove all the x-axis grid lines
          },
          y: {
            ticks: {
              display: false //this will remove only the label
         },
              display: false ////this will remove all the x-axis grid lines
          }
  
      }
    }



  return <Line options={options} data={data} />;
};

const LineGraph = ({title, data}) => {
    const options = {
        responsive: true,
        plugins: {
            legend:{
                position: "bottom"
            },
            title: {
                display: title && true,
                text: title
            }
        }
    }


  return <Line options={options} data={data} />;
};

export default LineGraph;
