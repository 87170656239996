import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { FaVolumeDown, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { MdExplicit } from "react-icons/md";
import { convertDuration, getArtistsNames, getArtistsNames2, getSongTitle } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { Audio } from "../../AudioPlayer1/AudioPlayerElements";
import { ArtistDetailsContainer, ArtistDetailsText, ArtistInfoContainer, AudioPlayerContainer, AudioPlayerControls, AudioPlayerControlsWrapper, AudioPlayerSlider, AudioPlayerVolumeSlider, AudioText, ProgressBarWrapper, VolumeControlsWrapper, WidgetsContainer } from "../../CollectionAudioPlayer/CollectionAudioPlayerElements";
import ScrollToTop from "../../ScrollToTop";

const MusicStoreAudioPlayer = ({
  timeJump,
  currentSong,
  isPlaying,
  duration,
  currentTime,
  progressBar,
  audioPlayer,
  changeRange,
  setTimeJump,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  nextSong,
  handleToggle,
  prevSong,
  playlist,
  setPlaylist,
  togglePlayPause,
  toggleMute,
  handleEnd,
  toggleVolume,
  isOpen,
  closeViewer,
  isMute,
  openViewer,
}) => {
  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const [isCountdown, setCoundown] = useState(false);
  return (
    <AudioPlayerContainer>
      <ArtistInfoContainer>
        <ArtistDetailsContainer>
          <ArtistDetailsText
          >
            {playlist[currentSong] && (
              getSongTitle(playlist[currentSong])?.length > 20 ? (
                <Marquee text={getSongTitle(playlist[currentSong])} />
              ) : (
                getSongTitle(playlist[currentSong])
              )
            )}
            {playlist[currentSong]?.explicit && <MdExplicit />}
          </ArtistDetailsText>
          <ArtistDetailsText
          >
            <small>
              {playlist[currentSong] &&
                getArtistsNames2(
                  playlist[currentSong]?.artists,
                  ARTIST_SEARCH_TYPES.NAME
                )}
            </small>
          </ArtistDetailsText>
        </ArtistDetailsContainer>
      </ArtistInfoContainer>
      <AudioPlayerControlsWrapper>
        <VolumeControlsWrapper>
          <AudioPlayerControls onClick={toggleMute} >
            {isMute || volume === 0 ? (
              <FaVolumeMute />
            ) : volume * 100 < 50 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeUp />
            )}
          </AudioPlayerControls>
          <AudioPlayerVolumeSlider
            progressWidth={`${(currentTime / duration) * 100}%`}
            type="range"
            value={isMute ? 0 : volume * 100}
            onChange={toggleVolume}
          />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>

      <Audio
        ref={audioPlayer}
        src={playlist[currentSong]?.mp3Url ? playlist[currentSong]?.mp3Url :playlist[currentSong]?.url}
        loop={isRepeat}
        preload={"metadata"}
        volume={volume}
        muted={isMute || volume === 0}
        onEnded={handleEnd}
      />
      {playlist.length > 0 && (
        <WidgetsContainer>
          {/*current time*/}
          <AudioText style={{ marginLeft: "2%" }}>
            {currentTime && !isNaN(currentTime) && calcTime(currentTime)}
          </AudioText>
          {/*progress bar*/}
          <ProgressBarWrapper>
            <AudioPlayerSlider
              progressWidth={`${
                (audioPlayer?.current?.currentTime / duration) * 100
              }%`}
              type="range"
              defaultValue={"0"}
              ref={progressBar}
              onChange={changeRange}
            />
          </ProgressBarWrapper>

          {/*Duration*/}
          <AudioText>
            {playlist[currentSong]?.duration
              ? convertDuration(playlist[currentSong].duration)
              : duration && !isNaN(duration)
              ? convertDuration(duration)
              : convertDuration(0)}
          </AudioText>
        </WidgetsContainer>
      )}
    </AudioPlayerContainer>
  );
};

export default MusicStoreAudioPlayer;
