import React from 'react'
import axiosInstance from '../components/Authorization/client'

let axios = axiosInstance

const useVideos = () => {

    const getLatestSongVideos = async(page) => {
        return await axios.get(`/api/videos/getLatestSongVideos/${page}`).then(
            songs => {
              if(songs.data && !songs.data.error){
                
                return songs.data
              }else {
                return false
              }
            }
          ).catch(err => {
            console.error(err)
            return false
          })
    }
  return {
    getLatestSongVideos
  }
}

export default useVideos