import React from 'react'
import Comment from './Comment'

const CommentList = ({comments}) => {

  return (<>{comments.map(comment => {
            return(
            <div style={{display: "flex", padding: '2%', flexDirection: "column", width: "100%", height: "100%"}}>
                <Comment {...comment} comments={comments}/>
            </div>)
        })}</>)
  

}

export default CommentList