import React, { useCallback, useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaShare, FaRegMoneyBillAlt } from "react-icons/fa";
import { useSonarMusePlayer } from "../../hooks/AudioHook";
import NftBadge from "../../images/nft_badge.png";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { GoReport } from "react-icons/go";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { filterLikes, getSongTitle, stringFormatter, timestampToDate } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
} from "../../utils/Enum";
import { BsShuffle, BsThreeDots } from "react-icons/bs";
import { MusicOptionsModal } from "../MenuOptions";
import ImageUtils from "../../utils/ImageUtils";
import { NavContainer } from "../NavLink/NavLinkElements";
import { useModal } from "../Modal/ModalContext";
import MusicInfo from "./MusicInfo";
import { Slider } from "../Slider";
import { useAlert } from "../Alert/AlertContext";
import CommentsSection from "../CommentsSection";
import useMusic from "../../hooks/useMusic";
import { shuffleSongs } from "../../utils/MusicUtils";
import { MdExplicit } from "react-icons/md";
import ScrollToTop from "../ScrollToTop";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { AlbumSongList, CollectionSongList, MusicStoreSongList } from "../ContentList/SongList";
import { getResizedImage } from "../../utils/FileUploadsUtils";

function AlbumPage({
  id,
  album,
  currentSong,
  isSongPlaying,
  handleToggle,
  currentTab,
  setTab,
  deleteAlbum,
  query,
  location,
  updateWebPageIcon
}) {
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
    const {goTo} = ScrollToTop()

  const navigate  = useNavigate();
  const { defaultAlbumArt } = ImageUtils();
  
  const { getSimilarAlbums, searchAlbumsByArtistId, getNFTokenCollectionsByAlbum } = useMusic();
  const [collections, setCollections] = useState([])
  const [isSALoaded, setSALoaded] = useState(false)
  const [isAALoaded, setAALoaded] = useState(false)
  const [isNFTLoaded, setNFTLoaded] = useState(false)
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [similarAlbums, setSimilarAlbums] = useState([])
  const [artistAlbums, setArtistAlbums] = useState([])
  const [isPlaying, setPlaying] = useState(false)
  const [albumArt, setAlbumArt] = useState(album?.albumArt)

  const { setCurrentSong, setPlaylist, play, isQueueLoaded } = useAudio();
  const { addAlert } = useAlert();

  const isPlayingFrom = useCallback( () => {
    if (JSON.parse(localStorage?.getItem("isPlayingFrom")) && 
    JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.ALBUM &&
      JSON.parse(localStorage?.getItem("isPlayingFrom"))?.id === album.albumId
    ) {
      return true;
    }
    return false;
  }, [album]) 


  useEffect(() => {
    updateWebPageIcon(album?.albumArt)
    return () => {}
  }, [])

  useEffect(() => {
    if(!isAlbumArtLoaded){
      getResizedImage(album?.albumArt).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(album?.albumArt)
      })
      setAlbumArtLoaded(true)
    }
  }, [])



  useEffect(() => {
    if(isSongPlaying && isPlayingFrom()){
      setPlaying(true)
    }else{
      setPlaying(false)
    }
  
   },[album,isSongPlaying, isPlayingFrom])

  const handleSelect = async (e) => {
    switch (e.target.value) {
      case "delete":
        break;
      case "edit":
        const params = new URLSearchParams({ edit: true });
        navigate(`${location.pathname}?edit=true`,{
          replace:true},
        );

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    
      getSimilarAlbums(album?.albumId, 0).then(data => {
        if(data){
          setSimilarAlbums(data)
          setSALoaded(true)
        }
      }).catch(err => {
        setSALoaded(true)
        console.error(err)
        return 
  
      })
      searchAlbumsByArtistId(album.artists[0].artistId).then(data => {
        if(data){
          setArtistAlbums(data)
          setAALoaded(true)
        }
      }).catch(err => {
        setAALoaded(true)
        console.error(err)
        return
  
      })
      getNFTokenCollectionsByAlbum(album?.albumId, 0).then(data => {
        if(data){
          setCollections(data)
          
        }setNFTLoaded(true)
      }).catch(err => {
        setNFTLoaded(true)
        console.error(err)
        return
  
      })
      return () => {}
  }, [album])




  const playSong = (i) => {
    if (currentUser?.uid ){
      if(isUserSubscribed()) {
      setPlaylist(album?.songs?.map((s) => s));
      setCurrentSong(i);
    }
  }
  };

  const togglePlay = async(pl) => {
    if (currentUser?.uid ){
      let playingFrom = {
        playingFrom: MUSIC_PLAYING_FROM.ALBUM,
        id: album.albumId,
      };
      
      setPlaylist(pl);
      
       let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
      if(isQueueLoaded){
        if (!isSongPlaying && !isPlayingFrom()) {

          setCurrentSong(0);
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        await handleToggle("play-pause");
        setPlaying(true)
        return;
      }
      if (!isSongPlaying && isPlayingFrom()) {
        await handleToggle("play-pause");
        setPlaying(true)
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        return;
      }
      if (isSongPlaying && isPlayingFrom()) {
        await handleToggle("play-pause", false);
        setPlaying(false)
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        return;
      }
      if (isSongPlaying && !isPlayingFrom()) {
        await handleToggle("play");
        setCurrentSong(0);
        setPlaying(true)
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        return;
      }else{
        await handleToggle("play");
        setPlaying(true)
        setCurrentSong(0);
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        return
      }}
      
      
    }
    if(isPlay === "Network Error"){
      addAlert({
        title: "Network Error",
        type: ALERT_TYPES.DANGER,
        message:
          'Cannot play music. Please check your connection',
      })
      if (isSongPlaying) {
        // if(isPlaying && subscribed to coil)
        await handleToggle("play-pause", false);
        setPlaying(false)
        return;
      }
     }
    else{
      if (isSongPlaying) {
        // if(isPlaying && subscribed to coil)
        await handleToggle("play-pause", false);
        setPlaying(false)
        return;
      }
      openModal() 
            modalContent(<><StreamingSubscriptionModal onSuccess={() => {
              getUserSubscription(currentUser?.uid);
              handleToggle("play-pause", !isSongPlaying)
              setPlaying(true)
              localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
              modalContent(<OptionModal heading={"Subscription Unsucessful"}>
                <HoverOption onClick={ 
            closeModal()}>OK</HoverOption>
            </OptionModal>)
               }} onFail={() => {
              modalContent(<OptionModal heading={"Subscription Unsucessful"}>
                <HoverOption onClick={() => {closeModal()
                modalContent()}}>OK</HoverOption>
            </OptionModal>)}} cancel={() => {closeModal()
            modalContent()}} /></>)
    }
    } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this album",
      });
    }
  };

  const shuffleSongs = async() => {
    if (album?.songs?.length > 0) {
      if (currentUser?.uid) {
        if((await isUserSubscribed())) {
          if(isQueueLoaded){
        let playingFrom = {
          playingFrom: MUSIC_PLAYING_FROM.ALBUM,
          id: album?.albumId,
        }
        setPlaylist(shuffleSongs(album?.songs.map(a => {
          return {...a,
            playingFrom: playingFrom}
        })));
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        setCurrentSong(0);
        handleToggle("play");}
      } else {
        if (
          isSongPlaying
        ) {
          setPlaying(false)
          handleToggle("play-pause", !isSongPlaying)
        }
        openModal();
        modalContent(
          <>
            <StreamingSubscriptionModal
              onSuccess={() => {
                handleToggle("play-pause", !isSongPlaying);
                modalContent(
                  <OptionModal heading={"Subscription Successful"}>
                    <HoverOption onClick={() => {
                        closeModal();
                        modalContent();
                      }}>OK</HoverOption>
                  </OptionModal>
                );
              }}
              onFail={() => {
                modalContent(
                  <OptionModal heading={"Subscription Unsuccessful"}>
                    <HoverOption
                      onClick={() => {
                        closeModal();
                        modalContent();
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                );
              }}
              cancel={() => {
                closeModal();
                modalContent();
              }}
            />
          </>
        );
      }
    } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this album",
      });
    }
  }
  }

 

  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
          color={album?.color}
          src={
            isAlbumArtLoaded ? 
                 album?.albumArt ? albumArt
                  : album?.songs?.length > 0 && album?.songs[0]?.albumArt
                  ? album?.songs[0].albumArt
                  : defaultAlbumArt : defaultAlbumArt
          }
          alt={album.albumName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
              color={album?.color}
              onContextMenu={(e) => {
                e.preventDefault();
               
              }}
              src={
                 isAlbumArtLoaded ? 
                 album?.albumArt ? albumArt
                  : album?.songs?.length > 0 && album?.songs[0]?.albumArt
                  ? album?.songs[0].albumArt
                  : defaultAlbumArt : defaultAlbumArt
              }
              alt={album.albumName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading><small>Album</small></SubHeading>
            <MusicHeading>{album?.albumName}{album?.songs?.some(s => s?.explicit === true) && <MdExplicit style={{ marginLeft: "1%" }} />} </MusicHeading>
            <MusicSubheading>
            <MusicHeading
                style={{ cursor: "pointer" }}
              >
                {album?.artists?.map((artist, index) => {
                  if (index < album?.artists?.length - 1) {
                    return <div style={{marginRight: '0.5rem'}}>{artist.artistName + " ○ "}</div>
                  } else {
                    return <div onClick={() =>
                      goTo(`/artist/${artist?.artistId}`)
                    }>{artist?.artistName}</div>;
                  }
                })}
              </MusicHeading>
            </MusicSubheading>
           
              <MusicHeadingSmall>{album?.genre?.name}○{`${album?.songs?.length} tracks`}○{new Date(timestampToDate(album?.releaseDate)).getFullYear()}</MusicHeadingSmall>
            
            <MusicOptionsContainer>
             
                <>{(album?.isStreamable /* || album?.isSubscription */) &&<><MusicPlayPauseButton
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (album?.songs?.length > 0) {
                        togglePlay([...album?.songs.map(a => {
                          return {...a,
                            playingFrom: {
                                playingFrom: MUSIC_PLAYING_FROM.ALBUM,
                                id: album.albumId,
                              }}
                        })]);
                      }
                    } else {
                      addAlert({
                        title: "Not Logged In",
                        type: ALERT_TYPES.WARNING,
                        message: "You need to log in to play this album",
                      });
                    }
                  }}
                >
                  {isPlaying ? (
                    <>
                      <FaPause /> Pause
                    </>
                  ) : (
                    <>
                      <FaPlay
                        style={{
                          position: "relative",
                          left: "1%",
                          marginRight: "2%",
                        }}
                      />{" "}
                      Play
                    </>
                  )}
                </MusicPlayPauseButton>
                {/* <MusicPlayPauseButton
                  onClick={shuffleSongs}
                >
                  <BsShuffle/> Shuffle
                </MusicPlayPauseButton> */}</>}
                
                </>
        
              {currentUser?.uid && (
                <OptionButton
                onClick={() =>
                  {openModal()
                  modalContent(<MusicOptionsModal content={album} type={ CONTENT_TYPES.ALBUM}/>)
                }
              }
                >
                  <OptionMenuButton />
                </OptionButton>
              )}
            </MusicOptionsContainer>
            {/* {album.about && <MusicOptionsContainer>
                  <MusicAboutWrapper>{stringFormatter(album.about)}</MusicAboutWrapper>
                </MusicOptionsContainer>} */}
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>
      
        <>
          <NavContainer>
            <MusicNav>
              <MusicNavLink
                onClick={() => setTab("music")}
                active={currentTab === "music"}
              >
                Music
              </MusicNavLink>
              <MusicNavLink
                onClick={() => setTab("about")}
                active={currentTab === "about"}
              >
                About {album?.albumName}
              </MusicNavLink>
              {album?.isDownloadable && (
                <MusicNavLink
                  onClick={() => setTab("purchase")}
                  active={currentTab === "purchase"}
                >
                  Purchase {album.albumName}
                </MusicNavLink>
              )}
            </MusicNav>
          </NavContainer>
          <MusicDetailsContainer>
            {currentTab === "music" && (
              <MusicInfoWrapper>
                <ContentWrapper heading={"Album"}>
                  {album?.isStreamable && isQueueLoaded && <AlbumSongList
                    type={CONTENT_TYPES.SONG}
                    content={
                      album?.songs?.length > 0 && album?.songs?.map((p) => {
                        return {...p,
                        playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.ALBUM,
                            id: album?.albumId,
                          }}
                        
                      })
                    }
                  />}
                  {(!album?.isStreamable || !isQueueLoaded) && <MusicStoreSongList content={album?.songs} isPlaying={isSongPlaying} currentSong={currentSong} playlist={() =>{}}/>}
                </ContentWrapper>
                <MusicContentWrapper>
                <ContentWrapper heading={`Comments`}>
                 <CommentsSection type={CONTENT_TYPES.ALBUM} id={album?.albumId} contentName={album?.albumName}/>
                </ContentWrapper>
                {isNFTLoaded && collections.length > 0 && <ContentWrapper
                  heading={`NFToken Collections Containing The Album ${album.albumName}`}
                  onClick={() => collections.length > 10 ? navigate(`/album/${album?.albumId}/collections`) : false}
                  subheading={collections?.length > 10 && "View All"}
                >
                  <Slider
                    type={SLIDER_TYPES.COLLECTION}
                    id={"collections"}
                    slides={collections.collections}
                  />
                </ContentWrapper>}
                {!isNFTLoaded && <ContentWrapper
                  heading={`NFToken Collections Containing The Album ${album.albumName}`}
                  
                >
                  <Slider
                    type={SLIDER_TYPES.MUSIC_SKELETON}
                    id={"collections"}
                  />
                </ContentWrapper>}
                  {isSALoaded && similarAlbums?.albums.length > 0 && <ContentWrapper
                    heading={`Similar Albums to ${album.albumName}`}
                    onClick={() => similarAlbums?.length > 10 &&
                      navigate(`/album/${album.albumId}/similarAlbums`)
                    } subheading={similarAlbums?.length > 10 && 'View All'}
                  >
                    <Slider
                      type={SLIDER_TYPES.ALBUM}
                      id={"recommended albums"}
                      slides={similarAlbums.albums.filter(a => a.albumId !== album?.albumId)}
                    />
                  </ContentWrapper>}
                  {!isSALoaded &&  <ContentWrapper
                    heading={`Similar Albums to ${album.albumName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"recommended albums"}
                    />
                  </ContentWrapper>}
                  {isAALoaded && (artistAlbums?.length > 0 && artistAlbums.albums.filter(a => a.albumId !== album?.albumId)?.length > 0) &&<ContentWrapper
                    heading={`Albums by ${album?.artists[0]?.artistName}`}
                    onClick={() => artistAlbums?.length > 10 && 
                      navigate(`/artist/${album?.artists[0]?.artistId}/albums`)
                    }
                    subheading={artistAlbums?.length > 10 && "View All"}
                  >
                    <Slider
                      type={SLIDER_TYPES.ALBUM}
                      id={"albums by artist"}
                      slides={artistAlbums.albums.filter(a => a.albumId !== album?.albumId)}
                    />
                  </ContentWrapper>}
                  {!isAALoaded && <ContentWrapper
                    heading={`Albums by ${album?.artists[0]?.artistName}`}
                  >
                    <Slider
                      type={SLIDER_TYPES.MUSIC_SKELETON}
                      id={"albums by artist"}
                   />
                  </ContentWrapper>}
                </MusicContentWrapper>
              </MusicInfoWrapper>
            )}
            {currentTab === "about" && (
              <MusicInfo type={CONTENT_TYPES.ALBUM} content={album} />

            )}
            {currentTab === "purchase" && (
              <>
                <MusicInfoWrapper>
                  <ContentWrapper>
                    <Slider
                      type={SLIDER_TYPES.DIGITAL_ALBUM}
                      slides={[
                        { ...album, songs: album?.songs?.map((s) => s.songId) },
                      ]}
                      id={"purchaseAlbum"}
                    />
                  </ContentWrapper>
                </MusicInfoWrapper>
              </>
            )}
          </MusicDetailsContainer>
        </>
      
    </MusicPageWrapper>
  );
}

export default AlbumPage;
